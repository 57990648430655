'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styles = require('@material-ui/core/styles');

var _reactMedia = require('react-media');

var _reactMedia2 = _interopRequireDefault(_reactMedia);

var _appCore = require('@manakin/app-core');

var _recompose = require('recompose');

var _reactRedux = require('react-redux');

var _reduxFirstHistory = require('redux-first-history');

var _hooks = require('@manakin/hooks');

var _graphql = require('./graphql');

var _reactApollo = require('react-apollo');

var _selectors = require('@manakin/authentication/selectors');

var _MenuItem = require('./MenuItem');

var _MenuItem2 = _interopRequireDefault(_MenuItem);

var _graphql2 = require('../graphql');

var _Router = require('../Router/Router');

var _reactI18next = require('react-i18next');

var _lib = require('@manakin/app-core/lib');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

var styles = function styles(theme) {
	return {
		smallWrapper: _extends({}, theme.manakin.smallWrapper, {
			marginTop: '5rem',
			width: '60%'
		}),
		list: {
			position: 'absolute',
			marginTop: '20rem',
			zIndex: 99
		},
		dropDownWrapper: {
			marginBottom: theme.spacing(4) + 'px'
		}
	};
};

var Menu = function Menu(props) {
	var _useTranslation = (0, _reactI18next.useTranslation)(),
	    t = _useTranslation.t;

	var handleMenuItemClick = function handleMenuItemClick(redirect) {
		props.goToRedirect(redirect);
	};

	var classes = props.classes,
	    location = props.location,
	    _props$config = props.config,
	    config = _props$config === undefined ? {} : _props$config,
	    _props$data = props.data,
	    data = _props$data === undefined ? {} : _props$data,
	    messagesReceivedData = props.messagesReceivedData;
	var _config$account = config.account,
	    account = _config$account === undefined ? {} : _config$account,
	    _config$general = config.general,
	    general = _config$general === undefined ? {} : _config$general;

	var _data$idealOrders = data.idealOrders,
	    _idealOrders = _data$idealOrders === undefined ? {} : _data$idealOrders;

	var _idealOrders$idealOrd = _idealOrders.idealOrders,
	    idealOrders = _idealOrders$idealOrd === undefined ? [] : _idealOrders$idealOrd;
	var _messagesReceivedData = messagesReceivedData.currentMessagesReceived,
	    currentMessagesReceived = _messagesReceivedData === undefined ? {} : _messagesReceivedData;
	var _currentMessagesRecei = currentMessagesReceived.messagesReceived,
	    messagesReceived = _currentMessagesRecei === undefined ? [] : _currentMessagesRecei;

	var showMessagesUnreadBadge = messagesReceived.some(function (it) {
		return it.readOn === null;
	});

	var path = location.pathname;
	var dropDownOptions = [{
		value: '/account',
		label: t('app.account.menu.account')
	}, {
		value: '/account/messages',
		label: t('app.account.menu.messages')
	}];
	var studentOnlyOptions = [];
	var teacherOnlyOptions = [];
	var poAndStudentOnlyOptions = [];

	var showNews = (0, _hooks.useGetConfig)('pages', 'general', 'showNews', config);
	var showResults = (0, _hooks.useGetConfig)('pages', 'general', 'showResults', config);
	var showInvoices = (0, _hooks.useGetConfig)('pages', 'general', 'showInvoices', config);
	var showStudentRapports = (0, _hooks.useGetConfig)('pages', 'general', 'showStudentRapports', config);

	if (!general.sso) {
		studentOnlyOptions.push({
			value: '/account/licenses',
			label: t('app.account.menu.license')
		});
	}

	if (showNews) {
		dropDownOptions.push({
			value: '/account/news',
			label: t('app.account.menu.news')
		});
	}

	if (showResults) {
		dropDownOptions.push({
			value: '/account/results',
			label: t('app.account.menu.results')
		});
	}

	if (showInvoices && idealOrders && idealOrders.length) {
		dropDownOptions.push({
			value: '/account/invoices',
			label: t('app.account.menu.invoices')
		});
	}

	if (showStudentRapports) {
		studentOnlyOptions.push({
			value: '/account/student-rapports',
			label: t('app.account.menu.reports')
		});
		teacherOnlyOptions.push({
			value: '/account/student-rapports',
			label: t('app.account.menu.reports')
		});
	}

	if (account.showNotifications) {
		poAndStudentOnlyOptions.push({
			value: '/account/notifications',
			label: t('app.account.menu.notifications')
		});
	}

	if (account.showInvites) {
		poAndStudentOnlyOptions.push({
			value: '/account/invite',
			label: t('app.account.menu.invite')
		});
	}

	if (account.showBPVBRapport) {
		studentOnlyOptions.push({
			value: '/account/bpvb',
			label: t('app.account.menu.bpv-report')
		});
	}
	if (general.books) {
		teacherOnlyOptions.push({
			value: '/account/books',
			label: t('app.account.menu.books')
		});
	}

	dropDownOptions.map(function (option) {
		option.selected = path === '' + option.value;
		return option;
	});

	studentOnlyOptions.map(function (option) {
		option.selected = path === '' + option.value;
		return option;
	});

	teacherOnlyOptions.map(function (option) {
		option.selected = path === '' + option.value;
		return option;
	});

	return _react2.default.createElement(
		_reactMedia2.default,
		{ query: '(max-width: 1140px)' },
		function (matches) {
			return matches ? _react2.default.createElement(
				'div',
				{ className: classes.smallWrapper },
				_react2.default.createElement(
					'div',
					{ className: classes.dropDownWrapper },
					_react2.default.createElement(
						_appCore.AccessControl,
						{ role: _lib.schoolManagerAndTeacherRoles },
						_react2.default.createElement(_appCore.AppBarDropDown, { dropDownOptions: [].concat(dropDownOptions, teacherOnlyOptions) })
					),
					_react2.default.createElement(
						_appCore.AccessControl,
						{ role: _lib.studentRoles },
						_react2.default.createElement(_appCore.AppBarDropDown, { dropDownOptions: [].concat(dropDownOptions, studentOnlyOptions, poAndStudentOnlyOptions) })
					)
				)
			) : _react2.default.createElement(
				'div',
				{ className: classes.list },
				dropDownOptions && dropDownOptions.map(function (option, idx) {
					return _react2.default.createElement(_MenuItem2.default, {
						key: idx,
						idx: idx,
						badge: option.value.includes(_Router.MESSAGES_ROOT_PATH) && showMessagesUnreadBadge,
						onClick: handleMenuItemClick,
						option: option
					});
				}),
				_react2.default.createElement(
					_appCore.AccessControl,
					{ role: _lib.studentRoles },
					studentOnlyOptions && studentOnlyOptions.map(function (option, idx) {
						return _react2.default.createElement(_MenuItem2.default, {
							key: idx,
							idx: idx,
							badge: option.value.includes(_Router.MESSAGES_ROOT_PATH) && showMessagesUnreadBadge,
							onClick: handleMenuItemClick,
							option: option
						});
					})
				),
				_react2.default.createElement(
					_appCore.AccessControl,
					{ role: _lib.schoolManagerAndTeacherRoles },
					teacherOnlyOptions && teacherOnlyOptions.map(function (option, idx) {
						return _react2.default.createElement(_MenuItem2.default, {
							key: idx,
							idx: idx,
							badge: option.value.includes(_Router.MESSAGES_ROOT_PATH) && showMessagesUnreadBadge,
							onClick: handleMenuItemClick,
							option: option
						});
					})
				),
				_react2.default.createElement(
					_appCore.AccessControl,
					{ role: [].concat(_toConsumableArray(_lib.trainerRoles), _toConsumableArray(_lib.studentRoles)) },
					poAndStudentOnlyOptions && poAndStudentOnlyOptions.map(function (option, idx) {
						return _react2.default.createElement(_MenuItem2.default, {
							key: idx,
							idx: idx,
							badge: option.value.includes(_Router.MESSAGES_ROOT_PATH) && showMessagesUnreadBadge,
							onClick: handleMenuItemClick,
							option: option
						});
					})
				)
			);
		}
	);
};

exports.default = (0, _recompose.compose)((0, _reactRedux.connect)(function (state) {
	return {
		appUser: (0, _selectors.getAppUser)(state),
		config: state.config
	};
}, function (dispatch) {
	return {
		goToRedirect: function goToRedirect(url) {
			return dispatch((0, _reduxFirstHistory.push)('' + url));
		}
	};
}), (0, _styles.withStyles)(styles, { name: 'AppAccountMenu' }), (0, _reactApollo.graphql)(_graphql2.GQL_FETCH_MESSAGES, { name: 'messagesReceivedData' }), (0, _reactApollo.graphql)(_graphql.GQL_FETCH_ORDERS, {
	options: function options(props) {
		return {
			variables: {
				filter: { users: [props.appUser.id || ''] }
			}
		};
	}
}))(Menu);