import gql from 'graphql-tag';
import { asset, fileAsset } from '@manakin/app-core/graphQlHelpers';

export const GQL_FETCH_APP_USERS = gql`
    query {
        appUsers {
            users {
                id
                fullName
            }
        }
    }
`;

export const GQL_FETCH_GROUPS = gql`
    query($filter: GroupFilter) {
        groups(filter: $filter) {
            groups {
                id
                name
                type
            }
        }
    }
`;

export const GQL_FETCH_SCHOOL = gql`
    query($id: ID!) {
        school(id: $id) {
            id
            name
            classes {
                id
                name
                products {
                    id
                    name
                    program {
                        id
                        name
                        description
                        boxes {
                            id
                            name
                            elements {
                                id
                                title
                                type
                            }
                            image {
                                ${fileAsset}
                            }
                        }
                    }
                }
                students {
                    id
                    firstName
                    middleName
                    lastName
                    fullName
                    email
                    initials
                    group {
                        name
                    }
                }
                fixedBoxes
                milestones {
                    id
                    box {
                        id
                    }
                    text
                }
            }
        }
    }
`;

export const GQL_UPDATE_SCHOOLCLASS = gql`
    mutation($id: ID!, $students: [ID], $teachers: [ID], $boxes: [[ID]]) {
        updateSchoolClass(
            input: {
                id: $id
                students: $students
                teachers: $teachers
                boxes: $boxes
            }
        ) {
            group {
                id
            }
        }
    }
`;

export const GQL_FETCH_PROGRAM = gql`
    query($id: ID!) {
        program(id: $id) {
            name
            description
        }
    }
`;

export const GQL_FETCH_SCHOOL_CLASS = gql`
    query($id: ID!) {
        schoolClass(id: $id) {
            id
            name
            products {
                id
                name
                program {
                    id
                    name
                    description
                    boxes {
                        id
                        name
                        elements {
                            id
                            title
                            type
                        }
                        image {
                            ${fileAsset}
                        }
                    }
                }
            }
            students {
                id
                firstName
                middleName
                lastName
                fullName
                email
                initials
                group {
                    name
                }
            }
            fixedBoxes
            milestones {
                id
                box {
                    id
                }
                text
            }
        }
    }
`;


export const GQL_FETCH_SCHOOL_CLASS_PROGRAM = gql`
    query fetchSchoolClassProgram($programId: ID!, $schoolClassId: ID!) {
        schoolClassProgram(program: $programId, schoolClass: $schoolClassId){
            program {
                id
                boxes {
                    id
                    name
                    elements {
                        id
                        title
                        type
                    }
                    image {
                        ${fileAsset}
                    }
                }
            }
            boxes {
                id
                name
                elements {
                    id
                    title
                    type
                }
                image {
                    ${fileAsset}
                }
            }
            milestones {
                id
                text
                box {
                    id
                }
            }
        }
    }  
`;
export const GQL_UPDATE_SCHOOL_CLASS_PROGRAM = gql`
    mutation updateSchoolClassProgram($input: UpdateSchoolClassProgramInput!) {
        updateSchoolClassProgram(input: $input) {
          result
        }
    }
`;