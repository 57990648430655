import logo from '../assets/logo.svg';
const defaultLang = 'nl_NL';

export default async (props) =>
	await new Promise(async (resolve) => {
		const config = {
			whiteLabel: 'Q for Profile',
			defaultLang: defaultLang,
			files: [
				{
					id: 'testfile',
					file: logo,
				},
			],
			general: {
				showMenuLabel: true,
				showTags: false,
				cbd: false,
				showFilters: false,
				teacherMayGiveFeedback: false,
				secondStatisticsBox: true,
				autoLogin: true,
			},
			pages: {
				general: {
					showStudentRapports: false,
				},
				appLogin: {
					showHeaderTitle: false,
					showLogo: true,
					headerTitleIsHtml: false,
					showFootNote: false,
					visualTitle: false,
					footerLogo: false,
				},
				appRegistration: {
					showBirthDate: true,
					showPhoneNumber: true,
					showRegisterType: false,
					showRegisterCode: false,
					registerCodeRequired: false,
					addMoreLicenses: true,
					hideGender: false,
					showLanguageSelect: true,
				},
				appNoLicense: {
					showFootNote: false,
				},
				appAccount: {
					showEducation: true,
					showLanguageSelect: true,
					hideFunction: true,
				},
				appElementOverlay: {
					showHeaderTitle: true,
				},
				dashboard: {
					scrollTo: false,
				},
				boxCard: {
					showArrow: false,
					showDescription: true,
					showIconLabel: false,
					animateLabel: true,
					showThemeIconsInList: false,
				},
				appBox: {
					showResultsPercentage: true,
				},
				elements: {
					exerciseCard: true,
				},
			},
			caseConfig: {
				redoButtons: true,
			},
			elementCardConfig: {
				noPercentage: false
			},
			getFile: (id) => config.files.find((file) => file.id === id),
			getPage: (key) => config[key],
		};

		resolve(config);
	});
