'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _Toolbar = require('@material-ui/core/Toolbar');

var _Toolbar2 = _interopRequireDefault(_Toolbar);

var _styles = require('@material-ui/core/styles');

var _appCore = require('@manakin/app-core');

var _AppBar = require('@material-ui/core/AppBar');

var _AppBar2 = _interopRequireDefault(_AppBar);

var _recompose = require('recompose');

var _Menu = require('@material-ui/icons/Menu');

var _Menu2 = _interopRequireDefault(_Menu);

var _IconButton = require('@material-ui/core/IconButton');

var _IconButton2 = _interopRequireDefault(_IconButton);

var _Search = require('@material-ui/icons/Search');

var _Search2 = _interopRequireDefault(_Search);

var _ArrowBack = require('@material-ui/icons/ArrowBack');

var _ArrowBack2 = _interopRequireDefault(_ArrowBack);

var _KeyboardArrowDown = require('@material-ui/icons/KeyboardArrowDown');

var _KeyboardArrowDown2 = _interopRequireDefault(_KeyboardArrowDown);

var _AccountCircle = require('@material-ui/icons/AccountCircle');

var _AccountCircle2 = _interopRequireDefault(_AccountCircle);

var _Button = require('@material-ui/core/Button');

var _Button2 = _interopRequireDefault(_Button);

var _Menu3 = require('@material-ui/core/Menu');

var _Menu4 = _interopRequireDefault(_Menu3);

var _MenuItem = require('@material-ui/core/MenuItem');

var _MenuItem2 = _interopRequireDefault(_MenuItem);

var _reactRouterDom = require('react-router-dom');

var _actions = require('@manakin/authentication/actions');

var _reactRedux = require('react-redux');

var _actions2 = require('../AppMenu/actions');

var _actions3 = require('./actions');

var _selectors = require('./selectors');

var _selectors2 = require('@manakin/authentication/selectors');

var _GroupsDropdown = require('../GroupsDropdown/GroupsDropdown');

var _GroupsDropdown2 = _interopRequireDefault(_GroupsDropdown);

var _actions4 = require('../SearchBar/actions');

var _styles2 = require('./styles');

var _Badge = require('@material-ui/core/Badge');

var _Badge2 = _interopRequireDefault(_Badge);

var _graphql = require('./graphql');

var _reactApollo = require('react-apollo');

var _AppBarMessages = require('./AppBarMessages');

var _AppBarMessages2 = _interopRequireDefault(_AppBarMessages);

var _reactI18next = require('react-i18next');

var _SaveAlt = require('@material-ui/icons/SaveAlt');

var _SaveAlt2 = _interopRequireDefault(_SaveAlt);

var _lib = require('@manakin/app-core/lib');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var mapStateToProps = function mapStateToProps(state) {
	return {
		config: state.config,
		appUser: (0, _selectors2.getAppUser)(state),
		hiddenPath: (0, _selectors.getHide)(state),
		appBarBackgroundColor: (0, _selectors.getColor)(state),
		appBarDropDown: (0, _selectors.getDropDown)(state),
		backButtonData: (0, _selectors.getBackButtonData)(state)
	};
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
	return {
		onToggleAppMenu: function onToggleAppMenu() {
			return dispatch((0, _actions2.toggleAppMenu)());
		},
		onLogout: function onLogout() {
			return dispatch((0, _actions.logout)());
		},
		onSave: function onSave(data) {
			return dispatch((0, _actions3.saveAppBar)(data));
		},
		onGoBack: function onGoBack(data) {
			return dispatch((0, _actions3.goBackAppBar)(data));
		},
		onToggleSearchBar: function onToggleSearchBar() {
			return dispatch((0, _actions4.toggleSearchBar)());
		}
	};
};

var BackAction = function BackAction(_ref) {
	var classes = _ref.classes,
	    onClick = _ref.onClick,
	    t = _ref.t,
	    appBarBackgroundColor = _ref.appBarBackgroundColor;

	return _react2.default.createElement(
		_react2.default.Fragment,
		null,
		_react2.default.createElement(
			_IconButton2.default,
			{
				classes: { root: (0, _classnames2.default)(classes.backButtonRoot, appBarBackgroundColor.className) },
				onClick: onClick },
			_react2.default.createElement(_ArrowBack2.default, null)
		),
		_react2.default.createElement(
			'span',
			{ className: (0, _classnames2.default)(classes.menuText, appBarBackgroundColor.className), onClick: onClick },
			t('common.back')
		)
	);
};

var AppCoreAppBar = function (_React$Component) {
	_inherits(AppCoreAppBar, _React$Component);

	function AppCoreAppBar() {
		var _ref2;

		var _temp, _this, _ret;

		_classCallCheck(this, AppCoreAppBar);

		for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
			args[_key] = arguments[_key];
		}

		return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref2 = AppCoreAppBar.__proto__ || Object.getPrototypeOf(AppCoreAppBar)).call.apply(_ref2, [this].concat(args))), _this), _this.state = {
			anchorEl: null,
			headerBackground: false,
			profilePicture: false
		}, _this.handleMenuClose = function () {
			_this.setState({ anchorEl: null });
		}, _this.handleMenuOpen = function (event) {
			_this.setState({ anchorEl: event.currentTarget });
		}, _this.handleMenuToggle = function () {
			_this.props.onToggleAppMenu();
		}, _this.handleLogout = function () {
			_this.props.onLogout();
		}, _this.handleBackToDashboard = function () {
			return _this.props.history.push("/");
		}, _this.handleBackToBoxes = function () {
			var _this$props = _this.props,
			    _this$props$config = _this$props.config,
			    config = _this$props$config === undefined ? {} : _this$props$config,
			    history = _this$props.history;
			var _config$pages = config.pages,
			    pages = _config$pages === undefined ? {} : _config$pages;


			var fromProgramDashboard = sessionStorage.getItem("fromProgramDashboard");
			if (pages.showDashboard && fromProgramDashboard !== undefined) {
				sessionStorage.removeItem("fromProgramDashboard");
				history.push(fromProgramDashboard === "true" ? "/dashboard" : "/");
			} else {
				history.push(pages.showDashboard ? "/dashboard" : "/");
			}
		}, _this.handleBackToTeacher = function () {
			if (_this.props.onGoBack) _this.props.onGoBack('teacherProgram');
		}, _this.handleBackToRoot = function () {
			_this.props.history.push('/');
		}, _this.handleBack = function () {
			var _this$props$backButto = _this.props.backButtonData,
			    backButtonData = _this$props$backButto === undefined ? {} : _this$props$backButto;

			if (backButtonData.url) {
				_this.props.history.push(backButtonData.url);
			} else {
				_this.props.history.goBack();
			}
		}, _this.handleSearchBarOpen = function () {
			_this.props.onToggleSearchBar();
		}, _this.handleScrollY = function () {
			window.pageYOffset >= 100 ? _this.setState({ headerBackground: true }) : _this.setState({ headerBackground: false });
		}, _this.componentDidMount = function () {
			window.addEventListener('scroll', _this.handleScrollY);
			if (_this.props.appUser.profilePicture && _this.props.appUser.profilePicture.url) {
				_this.setState({
					profilePicture: _this.props.appUser.profilePicture.url
				});
			}
		}, _this.componentDidUpdate = function (prevProps) {
			if (prevProps.appUser != _this.props.appUser) {
				if (_this.props.appUser.profilePicture && _this.props.appUser.profilePicture.url) {
					_this.setState({
						profilePicture: _this.props.appUser.profilePicture.url
					});
				}
			}
		}, _temp), _possibleConstructorReturn(_this, _ret);
	}

	_createClass(AppCoreAppBar, [{
		key: 'render',
		value: function render() {
			var _this2 = this;

			var _props = this.props,
			    classes = _props.classes,
			    appUser = _props.appUser,
			    _props$appBarBackgrou = _props.appBarBackgroundColor,
			    appBarBackgroundColor = _props$appBarBackgrou === undefined ? {} : _props$appBarBackgrou,
			    appBarDropDown = _props.appBarDropDown,
			    location = _props.location,
			    hiddenPath = _props.hiddenPath,
			    _props$config = _props.config,
			    config = _props$config === undefined ? {} : _props$config,
			    _props$backButtonData = _props.backButtonData,
			    backButtonData = _props$backButtonData === undefined ? {} : _props$backButtonData,
			    data = _props.data,
			    t = _props.t;
			var _data$currentMessages = data.currentMessagesReceived,
			    currentMessagesReceived = _data$currentMessages === undefined ? {} : _data$currentMessages;
			var _state = this.state,
			    anchorEl = _state.anchorEl,
			    profilePicture = _state.profilePicture;

			var firstName = appUser.firstName || '';
			var navColor = appBarBackgroundColor.path == location.pathname ? appBarBackgroundColor.color || null : null;
			var navBGColor = appBarBackgroundColor.path == location.pathname ? appBarBackgroundColor.backgroundColor || null : null;

			var showBackButton = backButtonData.location == location.pathname ? backButtonData.show : false;
			var noLicensePage = location.pathname === '/no-license';
			var hideAppBar = hiddenPath.path && hiddenPath.path == location.pathname && !noLicensePage;

			var inBoxes = (0, _reactRouterDom.matchPath)(location.pathname, {
				path: '/box/:id',
				exact: true,
				strict: false
			});

			var inTeacher = (0, _reactRouterDom.matchPath)(location.pathname, {
				path: '/teacherProgram',
				exact: true,
				strict: false
			});

			var inAccount = (0, _reactRouterDom.matchPath)(location.pathname, {
				path: '/account',
				exact: false,
				strict: false
			});

			var inDashboard = (0, _reactRouterDom.matchPath)(location.pathname, {
				path: '/dashboard',
				exact: true,
				strict: false
			});

			var _config$general = config.general,
			    general = _config$general === undefined ? {} : _config$general,
			    _config$pages2 = config.pages,
			    pages = _config$pages2 === undefined ? {} : _config$pages2;


			var showMenu = general.showMenu != undefined ? general.showMenu : true;
			if (noLicensePage) showMenu = false;
			var showMenuLabel = general.showMenuLabel != undefined ? general.showMenuLabel : false;

			var showLogo = general.showLogo != undefined ? general.showLogo : false;

			var showDashboard = pages.showDashboard;

			var _ref3 = currentMessagesReceived || {},
			    _ref3$messagesReceive = _ref3.messagesReceived,
			    messagesReceived = _ref3$messagesReceive === undefined ? [] : _ref3$messagesReceive;

			var newMessages = false;

			messagesReceived && messagesReceived.forEach(function (item) {
				if (!item.readOn) newMessages = true;
			});

			return _react2.default.createElement(
				'div',
				{
					className: (0, _classnames2.default)(classes.root, _defineProperty({}, classes.hide, hideAppBar), _defineProperty({}, classes.secondaryColors, navColor == 'secondary'), _defineProperty({}, classes.lightColors, navColor == 'light'), _defineProperty({}, classes.darkColors, navColor == 'dark'))
				},
				_react2.default.createElement(
					_AppBar2.default,
					{
						className: (0, _classnames2.default)(_defineProperty({}, classes.appBarBackground, this.state.headerBackground), appBarBackgroundColor.backgroundClassName),
						position: 'static',
						classes: { root: classes.appBarRoot },
						style: {
							backgroundColor: navBGColor
						}
					},
					general.bpvMessages && _react2.default.createElement(_AppBarMessages2.default, null),
					_react2.default.createElement(
						_Toolbar2.default,
						{ classes: { root: classes.toolBarRoot } },
						_react2.default.createElement(
							'div',
							{ className: classes.toolBarInner },
							showMenu && _react2.default.createElement(
								'div',
								{ className: classes.menuButton },
								_react2.default.createElement(
									_IconButton2.default,
									{
										onClick: this.handleMenuToggle,
										classes: {
											label: (0, _classnames2.default)(classes.iconColor, appBarBackgroundColor.className)
										}
									},
									_react2.default.createElement(_Menu2.default, null)
								),
								showMenuLabel && _react2.default.createElement(
									'span',
									{ className: classes.menuButtonLabel, onClick: this.handleMenuToggle },
									t('common.menu')
								)
							),
							inDashboard && showDashboard && _react2.default.createElement(BackAction, { classes: classes, appBarBackgroundColor: appBarBackgroundColor, t: t, onClick: this.handleBackToDashboard }),
							inBoxes && inBoxes.isExact && _react2.default.createElement(BackAction, { classes: classes, appBarBackgroundColor: appBarBackgroundColor, t: t, onClick: this.handleBackToBoxes }),
							showBackButton && _react2.default.createElement(BackAction, { classes: classes, appBarBackgroundColor: appBarBackgroundColor, t: t, onClick: this.handleBack }),
							inAccount && _react2.default.createElement(BackAction, { classes: classes, appBarBackgroundColor: appBarBackgroundColor, t: t, onClick: this.handleBackToRoot })
						),
						_react2.default.createElement(
							'div',
							{ className: classes.programsDropdownRoot },
							showLogo && (location.pathname === '/' || location.pathname == '/dashboard' || location.pathname == '/progress-dashboard' || location.pathname == '/dashboard/no-scroll') && _react2.default.createElement(
								_appCore.AccessControl,
								{ not: [].concat(_toConsumableArray(_lib.teacherRoles), [_lib.schoolManagerRole]) },
								_react2.default.createElement(
									_reactRouterDom.Link,
									{ to: '/' },
									_react2.default.createElement('div', { className: classes.logo })
								)
							),
							appBarDropDown && appBarDropDown.path == location.pathname && appBarDropDown.dropDown && _react2.default.createElement(_appCore.AppBarDropDown, { dropDownOptions: [].concat(_toConsumableArray(appBarDropDown.dropDown)) }),
							_react2.default.createElement(
								'div',
								{
									className: (0, _classnames2.default)(classes.programsDropdown, _defineProperty({}, classes.show, location.pathname === '/' || location.pathname == '/dashboard' || location.pathname === '/altdashboard' || location.pathname == '/dashboard/no-scroll'))
								},
								_react2.default.createElement(_appCore.ProgramsDropdown, {
									inputProps: {
										classes: {
											select: classes.dropdownInput,
											icon: classes.dropdownIcon
										}
									}
								})
							),
							_react2.default.createElement(
								'div',
								{
									className: (0, _classnames2.default)(classes.programsDropdown, _defineProperty({}, classes.show, location.pathname === '/progress-dashboard'))
								},
								_react2.default.createElement(_appCore.ProductsFilter, { className: classes.programsDropdownSelect })
							),
							location.pathname === '/rapportage' && _react2.default.createElement(_GroupsDropdown2.default, {
								onlyShowGroups: true,
								inputProps: {
									classes: {
										select: classes.dropdownInput,
										icon: classes.dropdownIcon
									}
								}
							}),
							location.pathname == '/teacherProgram' && _react2.default.createElement(_GroupsDropdown2.default, {
								inputProps: {
									classes: {
										select: classes.dropdownInput,
										icon: classes.dropdownIcon
									}
								},
								onlyShowGroups: true
							})
						),
						inTeacher && inTeacher.isExact ? _react2.default.createElement(
							'div',
							{ className: classes.save, onClick: function onClick() {
									return _this2.props.onSave('teacherProgram');
								} },
							_react2.default.createElement(
								'div',
								{ className: classes.saveIcon },
								_react2.default.createElement(_SaveAlt2.default, null)
							)
						) : _react2.default.createElement(
							'div',
							{ className: classes.menuOptionsRoot },
							!noLicensePage && _react2.default.createElement(
								_IconButton2.default,
								{
									classes: {
										root: classes.iconButtonRoot,
										label: (0, _classnames2.default)(classes.iconColor, appBarBackgroundColor.className)
									},
									onClick: this.handleSearchBarOpen
								},
								_react2.default.createElement(_Search2.default, null)
							),
							general.showYears && _react2.default.createElement(_appCore.YearDropdown, appUser),
							_react2.default.createElement(
								_Button2.default,
								{
									classes: {
										root: classes.buttonRoot,
										label: (0, _classnames2.default)(classes.buttonLabel, appBarBackgroundColor.className)
									},
									color: 'primary',
									component: 'span',
									onClick: this.handleMenuOpen
								},
								_react2.default.createElement(
									'span',
									{ className: classes.name },
									firstName
								),
								_react2.default.createElement(_KeyboardArrowDown2.default, { className: classes.rightIcon })
							),
							_react2.default.createElement(
								_Menu4.default,
								{ id: 'simple-menu', anchorEl: anchorEl, open: Boolean(anchorEl), onClose: this.handleMenuClose },
								_react2.default.createElement(
									_reactRouterDom.Link,
									{ to: general.sso ? '/account/messages' : '/account', className: classes.link },
									_react2.default.createElement(
										_MenuItem2.default,
										{ onClick: this.handleMenuClose },
										t('common.account')
									)
								),
								!general.hideLogout && _react2.default.createElement(
									_MenuItem2.default,
									{ onClick: this.handleLogout },
									t('common.logout')
								)
							),
							profilePicture && _react2.default.createElement(
								_Badge2.default,
								{ invisible: !newMessages, variant: 'dot', color: 'secondary' },
								_react2.default.createElement('div', {
									className: classes.avatar,
									style: {
										backgroundImage: 'url(' + profilePicture + ')'
									},
									onClick: this.handleMenuOpen
								})
							),
							!profilePicture && _react2.default.createElement(
								_IconButton2.default,
								{
									classes: {
										root: classes.iconButtonRoot,
										label: (0, _classnames2.default)(classes.iconColor, appBarBackgroundColor.className)
									},
									onClick: this.handleMenuOpen
								},
								_react2.default.createElement(
									_Badge2.default,
									{ invisible: !newMessages, variant: 'dot', color: 'secondary' },
									_react2.default.createElement(_AccountCircle2.default, null)
								)
							)
						)
					)
				)
			);
		}
	}]);

	return AppCoreAppBar;
}(_react2.default.Component);

exports.default = (0, _recompose.compose)(_reactRouterDom.withRouter, (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps), (0, _reactI18next.withTranslation)(), (0, _reactApollo.graphql)(_graphql.GQL_FETCH_MESSAGES), (0, _styles.withStyles)(_styles2.styles, { name: 'AppBar' }))(AppCoreAppBar);