"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require("react-redux");

var _recompose = require("recompose");

var _reactRouterDom = require("react-router-dom");

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _Done = require("@material-ui/icons/Done");

var _Done2 = _interopRequireDefault(_Done);

var _Lock = require("@material-ui/icons/Lock");

var _Lock2 = _interopRequireDefault(_Lock);

var _Typography = require("@material-ui/core/Typography");

var _Typography2 = _interopRequireDefault(_Typography);

var _appCore = require("@manakin/app-core");

var _2 = require("../");

var _styles = require("./styles");

var _styles2 = _interopRequireDefault(_styles);

var _reactI18next = require("react-i18next");

var _contexts = require("@manakin/core/contexts");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var BoxCard = function BoxCard(props) {
	var _classNames3, _classNames4, _classNames8, _classNames9, _classNames10, _classNames11, _classNames12;

	var classes = (0, _styles2.default)();

	var _useState = (0, _react.useState)(false),
	    _useState2 = _slicedToArray(_useState, 2),
	    active = _useState2[0],
	    setActive = _useState2[1];

	var _useTranslation = (0, _reactI18next.useTranslation)(),
	    t = _useTranslation.t;

	var _useContext = (0, _react.useContext)(_contexts.BoxContext),
	    setBoxCondition = _useContext.setBoxCondition;

	var _props$content = props.content,
	    content = _props$content === undefined ? {} : _props$content,
	    listView = props.listView,
	    idx = props.idx,
	    _props$percentage = props.percentage,
	    percentage = _props$percentage === undefined ? 0 : _props$percentage,
	    config = props.config,
	    progressionDashboard = props.progressionDashboard,
	    _props$tags = props.tags,
	    tags = _props$tags === undefined ? [] : _props$tags,
	    locked = props.locked,
	    boxCondition = props.boxCondition,
	    onConditionDialogOpen = props.onConditionDialogOpen;
	var done = content.done,
	    description = content.description,
	    id = content.id,
	    image = content.image,
	    label = content.label,
	    buttonText = content.buttonText,
	    name = content.name,
	    prefixColor = content.prefixColor,
	    preTitle = content.preTitle,
	    titleColor = content.titleColor,
	    required = content.mandatory,
	    _content$imageOverflo = content.imageOverflow,
	    imagePosition = _content$imageOverflo === undefined ? false : _content$imageOverflo;


	var imageUrl = image ? image.url : "";
	var placeholder = image ? image.thumbnailUrl : "";

	(0, _react.useEffect)(function () {
		if (content) {
			setActive(true);
		}
	}, []);

	var handleLockLink = function handleLockLink(e) {
		if (locked && !boxCondition.fulfilled) {
			e.preventDefault();
			setBoxCondition(boxCondition);
			onConditionDialogOpen();
		};
	};

	var showDescription = _.get(config, "pages.boxCard.showDescription");
	var showArrow = _.get(config, "pages.boxCard.showArrow");
	var showIconLabel = _.get(config, "pages.boxCard.showIconLabel");
	var animateLabel = _.get(config, "pages.boxCard.animateLabel");
	var mandatory = required === false ? false : true;

	return _react2.default.createElement(
		"div",
		{
			className: (0, _classnames2.default)(_defineProperty({}, classes.double, progressionDashboard))
		},
		_react2.default.createElement(
			_reactRouterDom.Link,
			{
				className: (0, _classnames2.default)(classes.link, _defineProperty({}, classes.progressionDashboard, progressionDashboard)),
				onClick: handleLockLink,
				to: { pathname: "/box/" + id, state: { fromProgramDashboard: true } }
			},
			_react2.default.createElement(
				"div",
				{
					className: (0, _classnames2.default)(classes.boxRoot, (_classNames3 = {}, _defineProperty(_classNames3, classes.boxRootOverflow, imagePosition), _defineProperty(_classNames3, classes.listViewRoot, listView), _classNames3))
				},
				_react2.default.createElement(
					"div",
					{
						className: (0, _classnames2.default)(classes.inner, (_classNames4 = {}, _defineProperty(_classNames4, classes.listView, listView), _defineProperty(_classNames4, classes.labelAndlist, listView && label ? true : false), _defineProperty(_classNames4, classes.show, active), _defineProperty(_classNames4, classes.withOverflow, imagePosition), _defineProperty(_classNames4, classes.innerDone, percentage >= 100), _defineProperty(_classNames4, classes.innerDoneProgressionDashboard, percentage >= 100 && progressionDashboard), _classNames4)),
						style: {
							backgroundColor: listView ? null : content.backgroundColor || null
						}
					},
					label && !showIconLabel && _react2.default.createElement(
						_Typography2.default,
						{ className: (0, _classnames2.default)(classes.boxCardLabel, _defineProperty({}, classes.boxCardLabelAndTag, tags && tags.filter(function (tag) {
								return tag.visibleInApp;
							}).length > 0)) },
						label
					),
					_react2.default.createElement(
						"div",
						{ className: classes.themes },
						tags && tags.map(function (tag) {
							return _react2.default.createElement(
								_react2.default.Fragment,
								{ key: tag.id },
								tag.visibleInApp && _react2.default.createElement(
									"div",
									{
										key: tag.id,
										className: (0, _classnames2.default)(classes.boxCardLabel, classes.boxCardIconLabel, _defineProperty({}, classes.boxCardLabelAnimate, animateLabel))
									},
									tag.image && tag.image.url && _react2.default.createElement(
										"figure",
										{ className: classes.imageIcon },
										_react2.default.createElement("img", { src: tag.image.url })
									),
									_react2.default.createElement(
										_Typography2.default,
										{ className: classes.boxCardText },
										tag.name
									)
								)
							);
						})
					),
					done && mandatory && _react2.default.createElement(
						"div",
						{ className: classes.done },
						_react2.default.createElement(
							"span",
							{ className: classes.doneIcon },
							_react2.default.createElement(_Done2.default, {
								classes: { root: classes.iconRoot }
							})
						)
					),
					_react2.default.createElement(
						"div",
						{
							className: (0, _classnames2.default)(classes.backgroundImage, _defineProperty({}, classes.overflow, imagePosition))
						},
						_react2.default.createElement(_2.LoadImage, {
							align: "right",
							src: imageUrl,
							placeholder: placeholder
						})
					),
					preTitle && _react2.default.createElement(
						"span",
						{
							className: (0, _classnames2.default)(classes.boxSubtitle, (_classNames8 = {}, _defineProperty(_classNames8, classes.primaryColor, prefixColor && prefixColor === 'PRIMARY'), _defineProperty(_classNames8, classes.secondaryColor, titleColor === 'SECONDARY'), _classNames8))
						},
						preTitle
					),
					_react2.default.createElement(
						"span",
						{
							className: (0, _classnames2.default)(classes.boxName, (_classNames9 = {}, _defineProperty(_classNames9, classes.primaryColor, titleColor && titleColor === 'PRIMARY'), _defineProperty(_classNames9, classes.secondaryColor, titleColor === 'SECONDARY'), _defineProperty(_classNames9, classes.hasLabel, label ? true : false), _classNames9))
						},
						tags && listView && tags.map(function (tag) {
							return _react2.default.createElement(
								_react2.default.Fragment,
								{ key: tag.id },
								tag.image && _react2.default.createElement(
									"figure",
									{
										key: tag.id,
										className: classes.imageIcon
									},
									_react2.default.createElement("img", { src: tag.image.url })
								)
							);
						}),
						_react2.default.createElement(
							"span",
							null,
							buttonText || name
						)
					),
					locked && !boxCondition.fulfilled && _react2.default.createElement(_Lock2.default, {
						className: classes.lockedIcon
					}),
					percentage > 0 && _react2.default.createElement(
						"div",
						{
							className: (0, _classnames2.default)(classes.percentage, (_classNames10 = {}, _defineProperty(_classNames10, classes.percentageDone, percentage >= 100), _defineProperty(_classNames10, classes.percentageDoneListView, listView), _classNames10))
						},
						percentage >= 100 ? _react2.default.createElement(
							"div",
							{ className: classes.done },
							_react2.default.createElement(_Done2.default, {
								className: classes.percentageDoneIcon
							}),
							!listView && _react2.default.createElement(
								"span",
								{ className: classes.voltooid },
								t('common.completed').toLowerCase()
							)
						) : _react2.default.createElement(
							"span",
							null,
							percentage,
							"%"
						)
					),
					description && showDescription && _react2.default.createElement(
						"span",
						{
							className: (0, _classnames2.default)(classes.boxDescription, (_classNames11 = {}, _defineProperty(_classNames11, classes.primaryColor, titleColor === 'PRIMARY'), _defineProperty(_classNames11, classes.secondaryColor, titleColor === 'SECONDARY'), _classNames11))
						},
						description
					),
					showArrow && _react2.default.createElement(_appCore.LongArrow, {
						className: (0, _classnames2.default)(classes.longArrow, (_classNames12 = {}, _defineProperty(_classNames12, classes.primaryColor, titleColor === 'PRIMARY'), _defineProperty(_classNames12, classes.secondaryColor, titleColor === 'SECONDARY'), _classNames12))
					})
				)
			)
		),
		progressionDashboard && _react2.default.createElement(_appCore.ProgressionDashboardButton, null)
	);
};

exports.default = (0, _recompose.compose)((0, _reactRedux.connect)(function (_ref) {
	var config = _ref.config;
	return {
		config: config
	};
}))(BoxCard);