import React, { useState, useEffect } from 'react';
import FormHelperText from '@material-ui/core/FormHelperText';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import nlLocale from 'date-fns/locale/nl';
import { MuiPickersUtilsProvider, DatePicker, KeyboardDatePicker } from '@material-ui/pickers';
import FormControl from '@material-ui/core/FormControl';
import createUuid from 'uuid/v4';
import moment from 'moment';
import InputLabel from '@material-ui/core/InputLabel';

const DatePickerContainer = (props) => {
	const { form, name, initialValue, defaultValue = '', loading = false, label = '', margin = 'normal', maxDate = '3000-01-01', keyboardOnly, readOnly } = props;
	const { errors, schema } = form;
	const uuid = createUuid();

	//state hooks
	const [value, setValue] = useState(defaultValue ? new Date(defaultValue) : '');

	//effect hooks
	useEffect(() => {
		if (!loading) {
			setValue(initialValue);
			form.onFieldChange({ key: name, value: initialValue });
		}
	}, [initialValue, loading]);

	const handleDateChange = (date) => {
		form.onFieldChange({
			key: name,
			value: moment(date).format('YYYY-MM-DD'),
		});
		setValue(date);
	};

	return (
		<FormControl required={(schema[name] || {}).required} margin={margin} error={errors[name] && errors[name].length > 0}>
			<InputLabel disabled={readOnly} shrink={true}>{label}</InputLabel>
			<MuiPickersUtilsProvider utils={DateFnsUtils} locale={nlLocale}>
				{keyboardOnly ? (
					<KeyboardDatePicker
						disabled={readOnly}
						readOnly={readOnly}
						autoOk
						disableToolbar
						maxDate={maxDate}
						variant='inline'
						margin='normal'
						id={uuid}
						invalidDateMessage=''
						format='dd/MM/yyyy'
						value={value || null}
						allowKeyboardControl={true}
						onChange={handleDateChange}
					/>
				) : (
					<DatePicker
						disabled={readOnly}
						readOnly={readOnly}
						autoOk
						disableToolbar
						maxDate={maxDate}
						variant="inline"
						margin="normal"
						id={uuid}
						invalidDateMessage=""
						format="dd/MM/yyyy"
						value={value || null}
						allowKeyboardControl={true}
						onChange={handleDateChange}
					/>
				)}

			</MuiPickersUtilsProvider>
			{errors[name] && errors[name].length > 0 && errors[name].map((e, i) => <FormHelperText key={i}>{e}</FormHelperText>)}
		</FormControl>
	);
};

export default DatePickerContainer;
