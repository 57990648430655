import React, { useState, useEffect } from 'react';
import { ElementOverlay } from '@manakin/app-core';
import { withStyles } from '@material-ui/core/styles';
import { WorkformViewer } from '@manakin/app-core';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        [theme.breakpoints.up('lg')]: {
            flexDirection: 'row',
        },
    },
    leftColumn: {
        backgroundColor: theme.manakin.primaryColor[500],
    },
    rightColumn: {},
    column: {
        width: '100%',
        padding: '22px 0',
        [theme.breakpoints.up('sm')]: {
            padding: '30px 0',
        },
        [theme.breakpoints.up('md')]: {
            padding: '30px',
        },
        [theme.breakpoints.up('lg')]: {
            width: '50%',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
            padding: '0 100px',
            minHeight: '800px',
            maxHeight: 'calc(100vh - 100px)',
            overflow: 'scroll',
        },
    },
    content: {
        maxWidth: '650px',
        width: '100%',
        [theme.breakpoints.up('lg')]: {
            padding: '100px 0',
        },
    },
    chatBubble: {
        [theme.breakpoints.up('lg')]: {
            height: '100%',
            display: 'flex',
            alignItems: 'flex-start',
        },
    },
});

const Questions = (props) => {
    const { workforms = [], classes, options = {}, disabled = false } = props;
    const { t } = useTranslation();
    const _workforms = [...workforms];
    //state hooks
    const [step, setStep] = useState(0);
    const [currentWorkforms, setCurrentWorkforms] = useState([
        ..._workforms.splice(0, 2),
    ]);

    //effect hooks
    useEffect(() => {
        if (!workforms[step]) {
            props.onExit();
        }
    }, [workforms]);

    //functions
    const handleNext = (data) => {
        const idx = step + 2;
        const _workforms = [...workforms];

        props.saveWorkform({
            ...data,
            elementId: props.rawData.elementId,
            element: 'Lesson',
        });

        setStep(idx);
        if (workforms[idx]) {
            setCurrentWorkforms([..._workforms.splice(idx, 2)]);
        }
    };

    return (
        <ElementOverlay
            variant="Test"
            title={
                t("app.elements.test-entry.questions.title", {
                    title: props.preTitle || props.prefix || t("common.element-types.test").toLocaleLowerCase(),
                    questionNumber: step <= 0 ? 1 : step / 2 + 1
                })
            }
            controls={true}
        >
            {!disabled && (
                <div className={classes.root}>
                    <div
                        className={classNames(
                            classes.leftColumn,
                            classes.column
                        )}
                    >
                        <div
                            className={classNames(classes.content, {
                                [classes.chatBubble]:
                                    currentWorkforms[0] &&
                                    currentWorkforms[0].type === 'ChatBubble',
                            })}
                        >
                            {currentWorkforms[0] && (
                                <WorkformViewer
                                    type={currentWorkforms[0].type}
                                    onFinish={handleNext}
                                    item={currentWorkforms[0]}
                                    options={options}
                                    contentStyle="light"
                                    animate={
                                        currentWorkforms[0].type ===
                                        'ChatBubble'
                                    }
                                />
                            )}
                        </div>
                    </div>
                    <div
                        className={classNames(
                            classes.rightColumn,
                            classes.column
                        )}
                    >
                        <div className={classes.content}>
                            {currentWorkforms[1] && (
                                <WorkformViewer
                                    type={currentWorkforms[1].type}
                                    onFinish={handleNext}
                                    item={currentWorkforms[1]}
                                    options={options}
                                    direction="column"
                                    animate={
                                        currentWorkforms[1].type ===
                                        'ChatBubble'
                                    }
                                />
                            )}
                        </div>
                    </div>
                </div>
            )}
        </ElementOverlay>
    );
};

export default withStyles(styles)(Questions);
