export const isSettingEnabled = (name, manualSettings) => {
	const settings = manualSettings ? manualSettings : JSON.parse(localStorage.getItem("settings")) || [];
	const setting = settings.find((setting) => setting.name === name);

    return setting && setting.value === 'true' ? true : false;
}

export const getSettings = (name) => {
	const settings = JSON.parse(localStorage.getItem("settings")) || [];
	return settings;
}

export const getSettingValue = (name) => {
	const settings = JSON.parse(localStorage.getItem("settings")) || [];
	const setting = settings.find((setting) => setting.name === name);

	return setting && setting.value;
}
