'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _Done = require('@material-ui/icons/Done');

var _Done2 = _interopRequireDefault(_Done);

var _ArrowForward = require('@material-ui/icons/ArrowForward');

var _ArrowForward2 = _interopRequireDefault(_ArrowForward);

var _PlayArrow = require('@material-ui/icons/PlayArrow');

var _PlayArrow2 = _interopRequireDefault(_PlayArrow);

var _ = require('../../');

var _core = require('@material-ui/core');

var _VideoPlayer = require('../../VideoPlayer');

var _VideoPlayer2 = _interopRequireDefault(_VideoPlayer);

var _reactHooks = require('@apollo/react-hooks');

var _useMediaQuery = require('@material-ui/core/useMediaQuery');

var _useMediaQuery2 = _interopRequireDefault(_useMediaQuery);

var _styles = require('@material-ui/styles');

var _appCore = require('@manakin/app-core');

var _reactI18next = require('react-i18next');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var DefaultItem = function DefaultItem(props) {
	var _classNames, _classNames2, _classNames3, _classNames4, _classNames6, _classNames7;

	var query = props.query,
	    classes = props.classes,
	    item = props.item,
	    disabled = props.disabled,
	    settings = props.settings,
	    prefix = props.prefix,
	    dataName = props.dataName,
	    _props$withRating = props.withRating,
	    withRating = _props$withRating === undefined ? false : _props$withRating,
	    tags = props.tags,
	    show = props.show,
	    _props$config = props.config,
	    config = _props$config === undefined ? {} : _props$config;
	var _config$general = config.general,
	    general = _config$general === undefined ? {} : _config$general;

	var theme = (0, _styles.useTheme)();
	var matchesMD = (0, _useMediaQuery2.default)(theme.breakpoints.up('md'));

	var _useState = (0, _react.useState)(false),
	    _useState2 = _slicedToArray(_useState, 2),
	    hover = _useState2[0],
	    setHover = _useState2[1];

	var _useQuery = (0, _reactHooks.useQuery)(query, {
		variables: {
			id: item.id
		}
	}),
	    _useQuery$data = _useQuery.data,
	    data = _useQuery$data === undefined ? {} : _useQuery$data;

	var _useTranslation = (0, _reactI18next.useTranslation)(),
	    t = _useTranslation.t;

	var handlePointer = function handlePointer(data) {
		setHover(true);
	};
	var handleScroll = function handleScroll() {
		if (hover) setHover(false);
	};

	var videoId = data[dataName] ? data[dataName].vimeoId || '' : '';
	var showText = data[dataName] ? data[dataName].showText || false : false;
	var imageUrl = item.image ? item.image.url : '';
	var placeholder = item.image ? item.image.thumbnailUrl : '';

	var _ref = data && data[dataName] || {},
	    _ref$preTitle = _ref.preTitle,
	    preTitle = _ref$preTitle === undefined ? '' : _ref$preTitle,
	    _ref$prefixColor = _ref.prefixColor,
	    prefixColor = _ref$prefixColor === undefined ? '' : _ref$prefixColor,
	    _ref$titleColor = _ref.titleColor,
	    titleColor = _ref$titleColor === undefined ? '' : _ref$titleColor,
	    introTitle = _ref.introTitle;

	var imageOverflow = data[dataName] ? data[dataName].imageOverflow || false : false;
	var backgroundColor = data[dataName] ? data[dataName].backgroundColor || null : null;
	var useTextOverlay = data[dataName] ? data[dataName].useTextOverlay || false : false;
	var compact = data[dataName] ? data[dataName].compact || false : false;

	var eligibleForIncorrect = item.type !== "Trainer" && item.type !== "BettingGame" && item.type !== "Case";

	return _react2.default.createElement(
		'div',
		{
			className: (0, _classnames2.default)(classes.root, classes[item.type], (_classNames = {}, _defineProperty(_classNames, classes.fullWidth, item.fullWidth), _defineProperty(_classNames, classes.minHeight, true), _defineProperty(_classNames, classes.listView, compact), _defineProperty(_classNames, classes.disabled, disabled), _defineProperty(_classNames, classes.show, show), _defineProperty(_classNames, classes.overflowRoot, imageOverflow), _defineProperty(_classNames, classes.useTextOverlay, useTextOverlay), _defineProperty(_classNames, classes.lightTextOverlay, titleColor == 'PRIMARY' || prefixColor == 'PRIMARY'), _classNames)),
			style: {
				transition: 'transform .5s .' + 1 + 's, opacity .5s .' + 1 + 's',
				backgroundColor: backgroundColor
			},
			onPointerOver: handlePointer,
			onPointerLeave: function onPointerLeave() {
				return setHover(false);
			},
			onPointerOut: function onPointerOut() {
				return setHover(false);
			},
			onScroll: handleScroll
		},
		_react2.default.createElement(_appCore.Tags, {
			tags: tags,
			className: (0, _classnames2.default)(classes.tags, (_classNames2 = {}, _defineProperty(_classNames2, classes.withIcon, item.done != undefined), _defineProperty(_classNames2, classes.bookcheckTag, item.type === 'BookCheck'), _classNames2))
		}),
		item.done != undefined && !withRating && _react2.default.createElement(
			'div',
			{
				className: (0, _classnames2.default)(classes.done, (_classNames3 = {}, _defineProperty(_classNames3, classes.inCorrect, eligibleForIncorrect && item.done && !item.correct), _defineProperty(_classNames3, classes.continue, !item.done), _classNames3))
			},
			item.done && _react2.default.createElement(
				'span',
				{ className: classes.doneIcon },
				_react2.default.createElement(_Done2.default, { classes: { root: classes.iconRoot } })
			),
			!item.done && item.done !== undefined && _react2.default.createElement(
				'span',
				{ className: classes.doneIcon },
				_react2.default.createElement(_ArrowForward2.default, {
					classes: {
						root: (0, _classnames2.default)(classes.iconRoot, (_classNames4 = {}, _defineProperty(_classNames4, classes.secondaryTitleColor, titleColor == 'SECONDARY'), _defineProperty(_classNames4, classes.primaryTitleColor, titleColor == 'PRIMARY'), _classNames4))
					}
				})
			)
		),
		withRating && item.rating && _react2.default.createElement(
			'div',
			{ className: classes.done },
			_react2.default.createElement(
				'span',
				{ className: classes.doneIcon },
				_react2.default.createElement(_Done2.default, { classes: { root: classes.iconRoot } })
			)
		),
		videoId && matchesMD ? _react2.default.createElement(
			'div',
			{ className: classes.videoBG },
			_react2.default.createElement(_VideoPlayer2.default, {
				videoId: videoId,
				playing: false,
				playOnHover: true,
				withControls: false,
				hoverState: hover,
				overflow: imageOverflow,
				fullWidth: false,
				volume: 0,
				loop: true,
				max: 30
			})
		) : _react2.default.createElement(
			'div',
			{
				className: (0, _classnames2.default)(classes.backgroundImage, _defineProperty({}, classes.overflow, imageOverflow))
			},
			_react2.default.createElement(_.LoadImage, { src: imageUrl, placeholder: placeholder })
		),
		(item.type === 'Film' || item.type === 'MasterClass') && showText && _react2.default.createElement(
			_react2.default.Fragment,
			null,
			general.playButtonAsIcon ? _react2.default.createElement(
				'div',
				{ className: classes.playIconContainer },
				_react2.default.createElement(_PlayArrow2.default, { className: classes.playIcon, size: 'large' })
			) : _react2.default.createElement(
				_core.Typography,
				{ variant: 'h1', className: classes.play },
				'PLAY'
			)
		),
		_react2.default.createElement(
			'div',
			{ className: classes.content },
			_react2.default.createElement(
				'span',
				{
					className: (0, _classnames2.default)(classes.type, (_classNames6 = {}, _defineProperty(_classNames6, classes.secondaryPrefixColor, prefixColor == 'SECONDARY'), _defineProperty(_classNames6, classes.primaryPrefixColor, prefixColor == 'PRIMARY'), _classNames6))
				},
				preTitle || settings && settings[item.type.toLowerCase() + 'Prefix'] || prefix || t('common.element-types.lesson') || ''
			),
			_react2.default.createElement(
				_core.Typography,
				{
					variant: 'h3',
					className: (0, _classnames2.default)(classes.name, (_classNames7 = {}, _defineProperty(_classNames7, classes.secondaryTitleColor, titleColor == 'SECONDARY'), _defineProperty(_classNames7, classes.primaryTitleColor, titleColor == 'PRIMARY'), _classNames7))
				},
				introTitle || item.title || prefix
			)
		),
		useTextOverlay && _react2.default.createElement(
			'div',
			{ className: classes.textOverlay },
			_react2.default.createElement(
				_core.Typography,
				{
					variant: 'h1',
					className: (0, _classnames2.default)(classes.textOverlayTitle, _defineProperty({}, classes.secondaryTitleColor, titleColor == 'SECONDARY'), _defineProperty({}, classes.primaryTitleColor, titleColor == 'PRIMARY'))
				},
				t('common.intro').toLocaleUpperCase()
			)
		)
	);
};

exports.default = DefaultItem;