import React, { useEffect, useRef } from 'react';
import { Filters, Header } from './parts';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import { styles } from './styles';
import { getAppUser } from '@manakin/authentication/selectors';
import { useSelector } from 'react-redux';
import { trainerRoles } from '@manakin/app-core/lib';
import { Redirect, useLocation } from 'react-router-dom';
import FactCardItem from './FactCardItem';
import { Loader } from '@manakin/app-core';
import { useVisibleOnScreen } from "@manakin/hooks";
import { getSettingValue } from '@manakin/core/actions';
import { SettingsKeys } from '@manakin/core/lib/constants';

const FactCards = (props) => {
	const { classes, factCards: originalFactCards = [], FactCardsData = {}, fromBox = false, fromElement = false, onFilter, loading, isTags = false, initialFilter = [], hideFilters = false } = props;
	const location = useLocation();
	const endListRef = useRef();
	const isVisible = useVisibleOnScreen(endListRef);
	const hasPaginatedScrolling = props.loadMore !== undefined;

	const tagOrderValue = getSettingValue(SettingsKeys.TAG_ORDER);
	const tagOrder = JSON.parse(tagOrderValue || '[]');

	// Selectors
	const appUser = useSelector((state) => getAppUser(state));

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if (isVisible && hasPaginatedScrolling) props.loadMore();
	}, [isVisible]);

	const highestTag = (tags) => {
		for (let i = 0; i < tagOrder.length; i++) {
			if (tags.find((tag) => tag.id == tagOrder[i])) return i;
		}

		// Return the highest possible number, a number no ID will ever reach, because Javascript is
		// quite broken when comparing numbers and null..
		return Number.MAX_SAFE_INTEGER;
	};

	const sortFactCards = (factCards = []) => {
		return factCards.sort((a, b) => {
			// The lower the tag, the higher it should be in the sorting
			if (highestTag(a.tags) < highestTag(b.tags)) return -1;
			if (highestTag(a.tags) > highestTag(b.tags)) return 1;
			else return 0;
		});
	};

	// Note: Intentionally using return value even though sort modifies the array itself
	const factCards = sortFactCards(hasPaginatedScrolling ? originalFactCards : FactCardsData.factCards);

	if (!location.pathname.startsWith("/preview") && appUser.roles.some((role) => trainerRoles.includes(role.name))) {
		return <Redirect to="/progress-dashboard" />;
	}

	return (
		<div className={classes.root} ref={props.ref}>
			<Header isTags={isTags} fromBox={fromBox} fromElement={fromElement} />
			<div className={classes.container}>
				<Grid container spacing={4}>
					{!hideFilters && (
						<Grid item xs={12}>
							<Filters onFilter={onFilter} initialFilter={initialFilter} />
						</Grid>
					)}
					{loading ? (
						<Grid container spacing={4}>
							<Loader />
						</Grid>
					) : (
						<Grid container spacing={4}>
							{factCards && factCards.map((factCard, i) => (
								<React.Fragment key={factCard ? factCard.id : `null-${i}`}>
									{factCard && <FactCardItem factCard={factCard} />}
								</React.Fragment>
							))}
						</Grid>
					)}
				</Grid>
			</div>
			{!loading && <div ref={endListRef}/>}
		</div>
	);
};

export default compose(withRouter, withStyles(styles, { name: 'FactCards' }))(FactCards);
