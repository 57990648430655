import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Dashboard from '../Dashboard';
import altDashboard from '../AltDashboard';
import Box from '../Box';
import { BookCheckContainer } from '../BookCheck';
import { MasterclassContainer, FilmContainer } from '../MasterAndVideo';
import { LessonContainer } from '../Lesson';
import { RecapContainer } from '../Recap';
import Rapportage from '../Rapportage';
import { TrainerContainer } from '../Trainer';
import TeacherProgram from '../TeacherProgram';
import Case from '../Case';
import Faq from '../Faq';
import SlideshowSetup from '../SlideshowSetup';
import Slideshow from '../Slideshow';
import { TransitionRouter } from '@manakin/app-core';
import { FactCardContainer } from '../FactCard';
import { FactCardsContainer, BoxCardContainer } from '../FactCards';
import { EntryTestContainer } from '../EntryTest';
import { TestContainer } from '../Test';
import { EvaluationContainer } from '../Evaluation';
import NoLicenses from '../NoLicenses/NoLicenses';
import NoLicensesCBD from '../NoLicensesCBD/NoLicenses';
import BettingGame from '../BettingGame';
import AssignmentCard from '../AssignmentCard';
import StudentRapportage from '../StudentRapportage';
import ConfirmInvitation from '../ConfirmInvitation';
import { useSelector } from 'react-redux';
import ProgressDashboard from '../ProgressDashboard';
import AssignedStudent from '../AssignedStudent';
import Planner from '@manakin/app-views/Planner';
import { RatingContainer } from '../Rating';
import RatingRequestsOverview from '../RatingRequestsOverview';
import BPVRapport from '../BPVRapport';
import { FinalRatingContainer } from '../FinalRating';
import ExamTrainer from '../ExamTrainer';
import WidgetDashboard from '../WidgetDashboard';
import Colophon from '../Colophon';

export const DASHBOARD_ROOT_PATH = 'dashboard';
export const PROGRESS_DASHBOARD_ROOT_PATH = 'progress-dashboard';
export const BOX_ROOT_PATH = 'box';
export const BOOKCHECK_ROOT_PATH = 'BookCheck';
export const MASTERCLASS_ROOT_PATH = 'MasterClass';
export const FILM_ROOT_PATH = 'Film';
export const LESSON_ROOT_PATH = 'Lesson';
export const EVALUATION_ROOT_PATH = 'SelfEvaluation';
export const TRAINER_ROOT_PATH = 'Trainer';
export const CASE_ROOT_PATH = 'Case';
export const FACT_CARD_ROOT_PATH = 'factcard';
export const FACT_CARDS_ROOT_PATH = 'factcards';
export const BOX_CARDS_PATH = 'boxCards';
export const ENTRY_TEST_ROOT_PATH = 'entryTest';
export const ENTRY_EXAM_TRAINER_ROOT_PATH = 'examTrainer';
export const TEST_ROOT_PATH = 'test';
export const RAPPORTAGE_ROOT_PATH = 'rapportage';
export const TEACHER_PROGRAM_ROOT_PATH = 'teacherProgram';
export const BETTING_GAME_ROOT_PATH = 'BettingGame';
export const ASSIGNMENT_CARD_ROOT_PATH = 'ExerciseCard';
export const INVITATION_ROOT_PATH = 'invitation';
export const PLANNER_ROOT_PATH = 'planner';
export const REQUEST_RATING_ROOT_PATH = 'RequestRating';
export const RATING_ROOT_PATH = 'Rating';
export const FINAL_RATING_ROOT_PATH = 'FinalRating';
export const ASSIGNED_STUDENT_ROOT_PATH = 'student';
export const RATING_REQUESTS_ROOT_PATH = 'ratingRequests';
export const BPVB_RAPPORT_ROOT_PATH = 'report-approval';
export const WIDGET_DASHBOARD_ROOT_PATH = 'widget-dashboard';

const Router = (props) => {
	const config = useSelector((state) => state.config);
	const { general = {} } = config || {};
	const useWidgetDashboard = config.pages && config.pages.showDashboard;

	return (
		<TransitionRouter>
			<Route path={'/no-license'} component={general.cbd ? NoLicensesCBD : NoLicenses} />
			{general.cbd && (<Route path={'/webshop/:variant'} component={NoLicensesCBD} />)}
			<Route exact path={`/`} component={useWidgetDashboard ? WidgetDashboard : Dashboard} />
			<Route exact path={`/${DASHBOARD_ROOT_PATH}`} component={Dashboard} />
			<Route path={`/${PROGRESS_DASHBOARD_ROOT_PATH}`} component={ProgressDashboard} />
			<Route exact path={`/${BPVB_RAPPORT_ROOT_PATH}/:id`} component={BPVRapport} />
			<Route exact path={`/${DASHBOARD_ROOT_PATH}/:dashboardOption`} component={Dashboard} />
			<Route exact path={`/${INVITATION_ROOT_PATH}/:token`} component={ConfirmInvitation} />
			<Route path={`/${FACT_CARD_ROOT_PATH}/:factCardId`} component={FactCardContainer} />
			<Route path={`/${FACT_CARDS_ROOT_PATH}`} component={FactCardsContainer} />
			<Route exact path={`/${BOX_ROOT_PATH}/:boxid`} component={Box} />
			<Route path={`/${BOX_CARDS_PATH}`} component={BoxCardContainer} />
			<Route path={`/${RAPPORTAGE_ROOT_PATH}/student/:id/:programId/:productId`} component={StudentRapportage} exact />
			<Route path={`/${RAPPORTAGE_ROOT_PATH}`} component={Rapportage} />
			<Route path={`/${TEACHER_PROGRAM_ROOT_PATH}`} component={TeacherProgram} />
			<Route path={`/faq`} component={Faq} />
			<Route path={`/colophon`} component={Colophon} />
			<Route path={`/${BOOKCHECK_ROOT_PATH}/:elementId`} component={BookCheckContainer} />
			<Route path={`/${MASTERCLASS_ROOT_PATH}/:elementId`} component={MasterclassContainer} />
			<Route path={`/${FILM_ROOT_PATH}/:elementId`} component={FilmContainer} />
			<Route path={`/${LESSON_ROOT_PATH}/:elementId`} component={LessonContainer} />
			<Route path={`/${EVALUATION_ROOT_PATH}/:elementId`} component={EvaluationContainer} />
			<Route path={`/${TRAINER_ROOT_PATH}/:elementId`} component={TrainerContainer} />
			<Route path={`/${RATING_ROOT_PATH}/:elementId/:studentId`} component={RatingContainer} />
			<Route path={`/${RATING_ROOT_PATH}/:elementId`} component={RatingContainer} />
			<Route path={`/${FINAL_RATING_ROOT_PATH}/:elementId/:studentId`} component={FinalRatingContainer} />
			<Route path={`/${FINAL_RATING_ROOT_PATH}/:elementId`} component={FinalRatingContainer} />
			<Route path={`/${ENTRY_TEST_ROOT_PATH}/:elementId`} component={EntryTestContainer} />
			<Route path={`/${ENTRY_EXAM_TRAINER_ROOT_PATH}/:elementId`} component={ExamTrainer} />  
			<Route path={`/${TEST_ROOT_PATH}/:elementId/:status`} component={TestContainer} />
			<Route path={`/${TEST_ROOT_PATH}/:elementId`} component={TestContainer} />
			<Route path={`/${PLANNER_ROOT_PATH}`} component={Planner} />
			<Route path={`/${BETTING_GAME_ROOT_PATH}/:elementId`} component={BettingGame} />
			<Route path={`/${CASE_ROOT_PATH}/:elementId`} component={Case} />
			<Route path={`/${ASSIGNMENT_CARD_ROOT_PATH}/:elementId`} component={AssignmentCard} />
			<Route path={`/SlideshowCreator/:elementId/:slideId`} component={SlideshowSetup} />
			<Route path={`/${ASSIGNED_STUDENT_ROOT_PATH}/:studentId/:program`} component={AssignedStudent} />
			<Route path={`/${ASSIGNED_STUDENT_ROOT_PATH}/:studentId`} component={AssignedStudent} />
			<Route path={`/${RATING_REQUESTS_ROOT_PATH}`} component={RatingRequestsOverview} />
			<Route path={`/recap/:elementId`} component={RecapContainer} />
			<Route path={`/Summary/:elementId`} component={RecapContainer} />
			<Route path={`/slideshow/:elementId`} component={Slideshow} />
			<Redirect from="/login" to="/" />
		</TransitionRouter>
	);
};

export default Router;
