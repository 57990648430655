import React, { useEffect, useState } from 'react';
import PersonalData from './PersonalData';
import SchoolData from './SchoolData';
import SchoolClassData from './SchoolClassData';
import AuthData from './AuthData';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import { push } from 'redux-first-history';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../actions';
import { GQL_CREATE_APP_USER, GQL_FETCH_SETTINGS, GQL_LOGIN_APP } from '../graphql';
import { useMutation, useQuery } from '@apollo/react-hooks';
import useStyles from './styles';
import { PersonalDataForm } from './forms/PersonalDataForm';
import { SchoolDataForm } from './forms/SchoolDataForm';
import { authDataForm } from './forms/AuthDataForm';
import { Loader } from '@manakin/app-core';
import Cookies from 'js-cookie';
import { getErrorMessage } from '../lib';
import { useHistory } from 'react-router';
import { useSnackBar } from '@manakin/hooks';
import { SettingsKeys } from '@manakin/core/lib/constants';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

const formData = [PersonalDataForm, SchoolDataForm, authDataForm];

const AppRegistration = (props) => {
	const { form } = props;
	const config = useSelector((state) => state.config) || {};
	const { t, i18n } = useTranslation();

	const { pages = {} } = config;
	const { appRegistration = {} } = pages;
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();
	const snackBar = useSnackBar();

	//query hooks
	const { loading, data = {} } = useQuery(GQL_FETCH_SETTINGS);

	//mutation hooks
	const [createAppUser, { data: userData, error }] = useMutation(GQL_CREATE_APP_USER);
	const [loginApp, { data: loginData, error: loginError }] = useMutation(GQL_LOGIN_APP);

	//state hooks
	const [value, setValue] = useState(0);
	const [regData, setData] = useState({});
	const [isMounted, setIsMounted] = useState(false);

	useEffect(() => {
		setIsMounted(true);
	}, []);

	useEffect(() => {
		if (isMounted) {
			if (form.errors && Object.keys(form.errors).some(key => form.errors[key] && form.errors[key].length > 0)) {
				// There are already errors present, make sure the error texts are now in the correct language
				form.onValidate();
			}
		}
	}, [i18n.language]);

	//functions
	const handleNext = () => {
		form.onValidate().then(
			() => {
				setValue(value + 1);
			},
			(error) => {
				let hasError = false;
				for (const key of Object.keys(formData[value])) {
					if (error[key] != undefined) hasError = true;
				}
				if (!hasError) setValue(value + 1);
			}
		);
	};

	const handleBack = () => {
		if (value == 0) history.goBack();
		else setValue(value - 1);
	};

	const handleSubmit = () => {
		const { settings: _settings } = data;
		const { settings = [] } = _settings;
		let role = null;

		settings.forEach((setting) => {
			if (setting.name === SettingsKeys.STANDARD_ROLE) {
				role = setting.value;
			}
		});

		form.onSubmit().then((data) => {
			const e = data.schoolClasses ? [data.schoolClasses] : null;
			const _data = {
				...data,
				schoolClasses: e,
				sendVerifyMail: true,
				roles: role ? [role] : [],
				acceptTerms: true,
			};
			if (data) {
				setData(_data);
				onCreate({ ..._data });
			}
		});
	};

	const onCreate = (event) => {
		createAppUser({
			variables: {
				...event,
				gender: event.gender || 'UNKNOWN',
			},
		}).then((result) => {
			if (result.errors && result.errors.length) {
				if (result.errors.some((error) => error.code === 409) ||
					result.errors.some(error => error.message === "APP user already exists!")) {
					snackBar.error(t('authentication.registration.email-already-exists'));
				} else {
					result.errors.map((error) => {
						const message = getErrorMessage(error.message, t);
						snackBar.error(message);
					});
				}
			}
		});
	};

	useEffect(() => {
		if (userData && userData.createAppUser && userData.createAppUser.user && userData.createAppUser.user.id) {
			loginApp({
				variables: {
					email: regData.email,
					password: regData.password,
				},
			});
		}
	}, [userData, error]);

	useEffect(() => {
		if (loginData) {
			if (loginData.loginApp.user && loginData.loginApp.user.locale) {
				const newLocale =
					loginData.loginApp.user.locale.code === config.defaultLang ? '' : loginData.loginApp.user.locale.code || '';

				Cookies.set('locale', newLocale);
				i18n.changeLanguage(newLocale);
			}
			if (loginData.loginApp && loginData.loginApp.mfa) {
				history.push({
					pathname: '/auth/2factor',
					state: {
						...loginData.loginApp,
						redirect: 'registration-success',
						env: 'APP',
					},
				});
			} else {
				dispatch(login('registration-success', loginData.loginApp.user));
			}
		}
		if (loginError) {
			dispatch(push('registration-success'));
		}
	}, [loginData, loginError]);

	if (loading) return <Loader fullScreen />;
	return (
		<div className={classes.root}>
			<div className={classes.container}>
				<Stepper classes={{ root: classes.stepperRoot }} activeStep={value} alternativeLabel>
					{formData.map((e, index) => (
						<Step classes={{ root: classes.stepRoot }} key={index}>
							<StepLabel>{index}</StepLabel>
						</Step>
					))}
				</Stepper>
				<div className={classes.content}>
					{value === 0 && <PersonalData form={form} config={config} data={data} />}
					{value === 1 && !appRegistration.showSchoolClass && <SchoolData form={form} config={config} />}
					{value === 1 && appRegistration.showSchoolClass && <SchoolClassData form={form} config={config} />}
					{value === 2 && <AuthData form={form} config={config} data={data} />}
				</div>
				<div className={classes.buttonGroup}>
					<Button
						variant="contained"
						classes={{ root: classNames(classes.buttonRoot, classes.buttonPrimary) }}
						onClick={value == 2 ? handleSubmit : handleNext}
						color="primary"
					>
						{t('common.continue')}
					</Button>
					<Button classes={{ root: classes.buttonRoot }} onClick={handleBack} color="primary">
						{t('common.back')}
					</Button>
				</div>
			</div>
		</div>
	);
};

export default AppRegistration;
