'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _core = require('@manakin/core');

var _reactRedux = require('react-redux');

var _actions = require('@manakin/app-core/ProgramsDropdown/actions');

var _selectors = require('@manakin/app-core/ProgramsDropdown/selectors');

var _selectors2 = require('@manakin/app-core/GroupsDropdown/selectors');

var _graphql = require('./graphql');

var _reactHooks = require('@apollo/react-hooks');

var _selectors3 = require('@manakin/authentication/selectors');

var _reactI18next = require('react-i18next');

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _styles = require('@material-ui/styles');

var _lib = require('@manakin/app-core/lib');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var useStyles = (0, _styles.makeStyles)(function (theme) {
	var _root;

	return (0, _styles.createStyles)({
		root: (_root = {}, _defineProperty(_root, theme.breakpoints.up('md'), {
			marginTop: '0',
			width: '30rem'
		}), _defineProperty(_root, '& > div', {
			margin: 0
		}), _defineProperty(_root, '& .MuiFormLabel-root', {
			color: theme.manakin.defaultContentColor[500]
		}), _defineProperty(_root, '& .MuiInput-input', {
			padding: '21.5px 0',
			'& > div > p': {
				marginBottom: 0
			},
			'& > div > div': {
				marginTop: -26
			},
			'& > div:nth-child(2)': {
				marginTop: 30
			}
		}), _root),
		noProgramSelected: {
			marginTop: 6
		}
	});
});

var ProductsFilter = function ProductsFilter(props) {
	var className = props.className,
	    form = props.form;

	var programId = (0, _reactRedux.useSelector)(function (state) {
		return (0, _selectors.getProgram)(state);
	});
	var group = (0, _reactRedux.useSelector)(function (state) {
		return (0, _selectors2.getGroup)(state);
	});
	var appUser = (0, _reactRedux.useSelector)(function (state) {
		return (0, _selectors3.getAppUser)(state);
	});

	var roleType = appUser.roles && appUser.roles[0].name;

	var _useTranslation = (0, _reactI18next.useTranslation)(),
	    t = _useTranslation.t;

	var classes = useStyles();

	var variables = {
		pagesize: 99,
		filter: {
			validLicensesOnly: true
		}
	};

	var _useLazyQuery = (0, _reactHooks.useLazyQuery)(_graphql.GQL_FETCH_GROUP, {
		variables: {
			id: group ? group.id || null : null
		}
	}),
	    _useLazyQuery2 = _slicedToArray(_useLazyQuery, 2),
	    queryGroup = _useLazyQuery2[0],
	    groupData = _useLazyQuery2[1].data;

	var _useLazyQuery3 = (0, _reactHooks.useLazyQuery)(_graphql.GQL_FETCH_PROGRAMS, {
		variables: variables
	}),
	    _useLazyQuery4 = _slicedToArray(_useLazyQuery3, 2),
	    queryPrograms = _useLazyQuery4[0],
	    _useLazyQuery4$ = _useLazyQuery4[1],
	    _useLazyQuery4$$data = _useLazyQuery4$.data,
	    data = _useLazyQuery4$$data === undefined ? {} : _useLazyQuery4$$data,
	    loading = _useLazyQuery4$.loading;

	var _ref = data || {},
	    _ref$programs = _ref.programs,
	    programs = _ref$programs === undefined ? {} : _ref$programs;

	var options = programs.programs && programs.programs.map(function (p) {
		return {
			id: p.id,
			label: p.name
		};
	}) || [];

	//dispatch
	var dispatch = (0, _reactRedux.useDispatch)();
	var setProgram = (0, _react.useCallback)(function (program) {
		return dispatch((0, _actions.selectProgram)(program));
	}, [dispatch]);

	//effect hooks
	(0, _react.useEffect)(function () {
		if (group && group.id) {
			queryGroup();
		}
	}, [group]);

	(0, _react.useEffect)(function () {
		if (!groupData && (_lib.studentRoles.includes(roleType) || _lib.trainerRoles.includes(roleType) || roleType === _lib.managementRole)) {
			queryPrograms();
		}
		if (groupData && groupData.group && groupData.group.id && group && group.id) {
			queryPrograms({
				variables: _extends({}, variables, {
					filter: _extends({}, variables.filter, _defineProperty({}, groupData.group.type === 'SchoolClass' ? 'schoolClass' : 'school', group.id))
				})
			});
		}
	}, [groupData]);

	(0, _react.useEffect)(function () {
		if (!loading && options && options.length) {
			if (!programId && options[0].id || !options.some(function (opt) {
				return opt.id === programId;
			}) && options[0].id) {
				setProgram(options[0].id);
			}
		}
	}, [loading, options]);

	//functions
	var handleChange = function handleChange(val) {
		if (val && val !== 'null' && val.value) setProgram(val.value);
	};

	var selectedProgram = (0, _react.useMemo)(function () {
		if (!programId || !options) return null;
		var foundProgram = options.find(function (option) {
			return option.id === programId;
		});
		if (foundProgram) {
			return {
				id: foundProgram.id,
				label: foundProgram.name
			};
		}

		return null;
	}, [programId, options]);

	if (loading) return '';

	if (options && options.length === 1 && selectedProgram && options[0].id === selectedProgram.id) {
		// Only one program option for the current user, do not show the dropdown
		return _react2.default.createElement(_react2.default.Fragment, null);
	}

	return _react2.default.createElement(
		'div',
		{ className: (0, _classnames2.default)(classes.root, className, _defineProperty({}, classes.noProgramSelected, isNaN(parseInt(programId)))) },
		_react2.default.createElement(_core.SelectField, {
			options: options,
			name: 'program',
			onChange: handleChange,
			label: t('app-core.products-filter.label'),
			initialValue: selectedProgram,
			form: form
		})
	);
};

exports.default = (0, _core.withForm)({})(ProductsFilter);