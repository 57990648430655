export function hasPermission(permission) {
    const privileges = JSON.parse(localStorage.getItem("privileges") || "[]")
    return privileges.indexOf(permission) !== -1
}

export const savePermissions = (roles) => {
    const privileges = [];
    roles.forEach(role => {
        role.privileges.forEach(privilege => {
            privileges.push(privilege.id);
        });
    });

    localStorage.setItem("privileges", JSON.stringify([...new Set(privileges)]));
};