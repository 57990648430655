'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _graphql = require('./graphql');

var _reactHooks = require('@apollo/react-hooks');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var useAppSettings = function useAppSettings() {
    var _useQuery = (0, _reactHooks.useQuery)(_graphql.GQL_FETCH_GLOBAL_SETTINGS, {
        onCompleted: function onCompleted() {
            if (!loading && data && data.settings && data.settings.settings && data.settings.settings.length) {
                localStorage.setItem('settings', JSON.stringify(data.settings.settings));
            }
        }
    }),
        data = _useQuery.data,
        loading = _useQuery.loading;
};

exports.default = useAppSettings;