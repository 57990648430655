'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactIdleTimer = require('react-idle-timer');

var _actions = require('@manakin/authentication/actions');

var _IdleDialog = require('./IdleDialog');

var _IdleDialog2 = _interopRequireDefault(_IdleDialog);

var _actions2 = require('@manakin/core/actions');

var _reactRedux = require('react-redux');

var _selectors = require('@manakin/authentication/selectors');

var _lib = require('@manakin/app-core/lib');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectDestructuringEmpty(obj) { if (obj == null) throw new TypeError("Cannot destructure undefined"); }

var timeout = 15 * 1000 * 60;


var IdleTimeoutContainer = function IdleTimeoutContainer(props) {
    var children = props.children;

    var _useState = (0, _react.useState)(false),
        _useState2 = _slicedToArray(_useState, 2),
        dialog = _useState2[0],
        setDialog = _useState2[1];

    var appUser = (0, _reactRedux.useSelector)(function (state) {
        return (0, _selectors.getAppUser)(state);
    });

    //dispatch functions
    var dispatch = (0, _reactRedux.useDispatch)();
    var onOpenDialog = (0, _react.useCallback)(function () {
        return dispatch((0, _actions2.openDialog)('appIdleDialog'));
    }, [dispatch]);

    var onCloseDialog = (0, _react.useCallback)(function () {
        return dispatch((0, _actions2.closeDialog)('appIdleDialog'));
    }, [dispatch]);

    var onLogout = (0, _react.useCallback)(function () {
        return dispatch((0, _actions.logout)());
    }, [dispatch]);

    //effect hooks
    (0, _react.useEffect)(function () {
        dispatch((0, _actions2.closeDialog)('appIdleDialog'));
        onCloseDialog();
    }, []);

    (0, _react.useEffect)(function () {
        if (dialog) {
            onOpenDialog();
        }
    }, [dialog]);

    //functions
    var onIdle = function onIdle(e) {
        if (process.env.NODE_ENV === 'development') {
            // Disable idle timer for debug, very annoying
        } else if (appUser && appUser.roles && appUser.roles.some(function (role) {
            return role.name === _lib.demoRole;
        })) {
            // Demo role should not auto log out, because the account will be lost at logout
        } else {
            setDialog(true);
        }
    };

    var handleClose = function handleClose(logout) {
        onCloseDialog();
        setDialog(false);
        if (logout) {
            onLogout();
        }
    };

    var _useIdleTimer = (0, _reactIdleTimer.useIdleTimer)({
        timeout: timeout,
        onIdle: onIdle,
        element: document,
        debounce: 250
    });

    _objectDestructuringEmpty(_useIdleTimer);

    return _react2.default.createElement(
        _react2.default.Fragment,
        null,
        _react2.default.createElement(_IdleDialog2.default, { dialog: dialog, onClose: handleClose }),
        children
    );
};

exports.default = IdleTimeoutContainer;