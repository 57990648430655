export const styles = (theme) => ({
    root: {
        width: '100%',
        height: 'auto',
        marginBottom: '20px',
    },
    alt: {},
    grouping: {},
    cardRoot: {
        display: 'flex',
    },
    card: {
        display: 'flex',
        height: '120px',
        border: '1px solid',
        borderColor: theme.palette.grey['400'],
        borderRadius: '10px',
        boxShadow: 'none',
        position: 'relative',
        width: '100%',
    },
    cardDrag: {
        opacity: 0.5,
    },
    container: {
        border: '1px solid #e7e8ee',
        cursor: 'pointer',
        backgroundColor: 'white',
        borderRadius: 10,
        height: '100%',
        marginBottom: '24px',
        width: '100%',
        padding: '0 20px',
    },
    cardContentContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    thumbnail: {
        width: '120px',
        minWidth: '120px',
        height: '100%',
        objectFit: 'cover',
        fontFamily: "'object-fit: cover'",
        backgroundColor: theme.palette.grey['100'],
    },
    cardContainerChild: {
        margin: '5px 10px',
    },
    cardHeader: {
        paddingBottom: 0,
        fontSize: '16px',
        marginBottom: '.2rem',
    },
    cardContent: {
        paddingTop: 0,
    },
    cardDrag: {
        opacity: 0,
    },
    icons: {
        display: 'flex',
        position: 'absolute',
        top: '10px',
        right: '10px',
    },
    cardDescription: {
        maxHeight: 40,
        overflow: 'hidden',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    moveButtonContainer: {
        display: 'inline-flex',
        flex: '0 0 auto',
        width: 48,
        color: 'rgba(0, 0, 0, 0.54)',
        height: 48,
        padding: 0,
        fontSize: '1.5rem',
        textAlign: 'center',
        transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'move',
    },
    moveButton: {
        display: 'flex',
    },
});
