import { makeStyles, createStyles, Typography } from '@material-ui/core';
import React from 'react';
import ArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ApiKeys } from '@manakin/core';
import { AccessControl } from '@manakin/app-core';
import { schoolManagerAndTeacherRoles } from '@manakin/app-core/lib';

const useStyles = makeStyles(
	(theme) =>
		createStyles({
			buttonText: {},
			root: {
				width: '100%',
				'& $buttonText': {
					marginBottom: '0',
					color: theme.palette.getContrastText(theme.palette.primary[500]),
				},
			},
			rightArrow: {
				width: '11px',
				height: '11px',
				position: 'absolute',
				right: '2rem',
				color: theme.palette.primary[500],
				[theme.breakpoints.up('md')]: {
					right: '4rem',
				},
			},
			buttonExport: {
				backgroundColor: theme.palette.primary[500],
				height: '5rem',
				width: '100%',
				display: 'flex',
				alignItems: 'center',
				padding: '0 2rem',
				position: 'relative',
				textDecoration: 'none',
				'& $rightArrow': {
					top: '13px',
					color: theme.palette.getContrastText(theme.palette.primary[500]),
				},
				[theme.breakpoints.up('md')]: {
					padding: '0 5rem',
				},
			},
		}),
	{
		name: 'AppCbdExportUsersManager',
	}
);

const CbdExportUsersManager = () => {
	const { t } = useTranslation();
	const classes = useStyles();

	const app_url = process.env.REACT_APP_APP_URL || 'http://localhost:3000';

	return (
		<AccessControl role={schoolManagerAndTeacherRoles}>
			<div className={classes.root}>
				<Link
					to={{
						pathname: `${app_url}${ApiKeys.cbdExportUsersManager}`,
					}}
					className={classes.buttonExport}
					target="_blank"
				>
					<Typography variant="body1" className={classes.buttonText}>
						{t('app.alt-dashboard.export-action')}
					</Typography>
					<div className={classes.rightArrow}>
						<ArrowRight />
					</div>
				</Link>
			</div>
		</AccessControl>
	);
};

export default CbdExportUsersManager;
