import React from 'react';
import { GQL_FETCH_REGISTRATION_GROUPS } from '../graphql';
import { SelectField } from '@manakin/core';

const SchoolClassSelector = (props) => {
	const { form } = props;

	return (
		<SelectField
			isAsync={true}
			query={GQL_FETCH_REGISTRATION_GROUPS}
			queryName='registrationGroups'
			customQuery={true}
			filter={{
				types: [ 'SchoolClass' ],
				mainOrganisations: [ props.id ],
			}}
			name='affiliate'
			label='filialen'
			form={form}
			initialValue={{ id: (form.fields && form.fields.affiliate) || '' }}
		/>
	);
};

export default SchoolClassSelector;
