import React from 'react';
import TableHead from '../TableHead';
import { withStyles } from '@material-ui/core/styles';
import MuiTable from '@material-ui/core/Table';
import TableBody from '../TableBody';
import TableToolbar from '../TableToolbar';
import TablePagination from '@material-ui/core/TablePagination';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

const styles = (theme) => ({
	root: {
		position: 'relative',
		paddingBottom: '120px',
	},
	search: {
		flex: '0 0 auto',
	},
	loader: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
	},
	relative: {
		position: 'relative',
	},
});

class Table extends React.Component {
	state = {
		selectAll: false,
		selection: [],
	};

	handleSort = (event, property) => {
		const orderBy = property;
		let order = 'desc';

		const { query, onQueryChange } = this.props;

		if (query.orderBy === property && query.order === 'desc') {
			order = 'asc';
		}

		onQueryChange({ ...query, order, orderBy, offset: 0 });
		this.resetSelection();
	};

	handleSelectVisible = (event) => {
		const { totalCount, rows } = this.props;
		this.setState({
			selection: [...rows.allIds],
			selectAll: totalCount === rows.allIds.length,
		});
	};

	handleSelectAll = (event) => {
		this.setState({
			selection: [...this.props.rows.allIds],
			selectAll: true,
		});
	};

	handleDeselectAll = (event) => {
		this.resetSelection();
	};

	handleToggleSelectRow = (row) => {
		const { selection } = this.state;
		const { totalCount } = this.props;
		const selectionIndex = selection.indexOf(row.id);

		if (selectionIndex === -1) {
			this.setState({
				selectAll: selection.length + 1 === totalCount,
				selection: [...selection, row.id],
			});
		} else {
			const newSelection = [...selection];
			newSelection.splice(selectionIndex, 1);

			this.setState({
				selectAll: false,
				selection: newSelection,
			});
		}
	};

	handleRowClick = (row) => {
		if (this.props.onRowClick) {
			this.props.onRowClick(row);
		}
	};

	handleChangePage = (event, page) => {
		if (event === null) {
			return;
		} // dunno why but it fires event with page = 0 and event =null on startup
		const { query, onQueryChange } = this.props;
		onQueryChange({ ...query, offset: page * query.limit });
		this.resetSelection();
	};

	handleChangeRowsPerPage = (event) => {
		const { query, onQueryChange } = this.props;
		onQueryChange({ ...query, offset: 0, limit: event.target.value });
		this.resetSelection();
	};

	handleSearch = (search) => {
		const { query, onQueryChange } = this.props;
		onQueryChange({ ...query, search });
		this.resetSelection();
	};

	resetSelection = () => {
		this.setState({ selection: [], selectAll: false });
	};

	getLabelDisplayedRows = ({ from, to, count }) => {
		const { t } = this.props;

		return count > 0
			? this.props.t('common.rows-displayed', {
					from: from,
					to: to,
					count: count,
			  })
			: '';
	};

	componentWillReceiveProps(newProps) {
		const oldRowsLength = this.props.rows.allIds.length;
		const newRowsLength = newProps.rows.allIds.length;
		if (oldRowsLength != newRowsLength) {
			this.resetSelection();
		}
	}

	render() {
		const { classes, rows, columns, totalCount, query, actions, loading, hideSearch, hideCheckBoxes, t, hideToolbar, customToolbarItems } = this.props;
		const { selection, selectAll } = this.state;

		const page = Math.max(0, Math.floor(query.offset / query.limit));
		let allRows = rows;

		return (
			<div className={classes.root}>
				{!hideToolbar && (
					<TableToolbar
						totalCount={totalCount}
						selectAll={selectAll}
						selection={selection}
						onSearch={this.handleSearch}
						onSelectAll={this.handleSelectAll}
						onDeselectAll={this.handleDeselectAll}
						defaultSearch={query.search}
						actions={actions}
						customToolbarItems={customToolbarItems}
						hideSearch={hideSearch}
					/>
				)}
				<div className={classes.relative}>
					<MuiTable>
						<TableHead
							selectedCount={selection.length}
							hideCheckBoxes={hideCheckBoxes}
							order={query.order}
							orderBy={query.orderBy}
							rowCount={rows.allIds.length}
							columns={columns}
							onSelectAll={this.handleSelectAll}
							onDeselectAll={this.handleDeselectAll}
							onSelectVisible={this.handleSelectVisible}
							onSort={this.handleSort}
						/>
						<TableBody
							totalCount={totalCount}
							hideCheckBoxes={hideCheckBoxes}
							rows={allRows}
							columns={columns}
							rowsPerPage={query.limit}
							selection={selection}
							onToggleSelect={this.handleToggleSelectRow}
							onRowClick={this.handleRowClick}
							loading={loading}
						/>
					</MuiTable>
					<TablePagination
						component="div"
						count={totalCount}
						rowsPerPage={query.limit}
						page={page}
						rowsPerPageOptions={[12, 24, 48, 96]}
						onPageChange={this.handleChangePage}
						onRowsPerPageChange={this.handleChangeRowsPerPage}
						labelDisplayedRows={this.getLabelDisplayedRows}
						labelRowsPerPage={t('common.rows-per-page')}
					/>
				</div>
			</div>
		);
	}
}

export default compose(withTranslation(), withStyles(styles, { name: 'CoreTable' }))(Table);
