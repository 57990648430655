import React, { useState, useEffect } from 'react';
import AppLogin from '@manakin/authentication/AppLogin';
import classNames from 'classnames';
import { connect } from 'react-redux';
import renderHTML from 'react-render-html';
import useStyles from './styles';
import { compose } from 'recompose';
import { GQL_FETCH_SETTINGS, GQL_FETCH_CMS_HOME_PAGE_BLOCKS_IDS } from '../graphql';
import { useQuery } from '@apollo/react-hooks';
import { useSetting, useBooleanSetting } from '@manakin/hooks';
import { Loader } from '@manakin/app-core';
import { SettingsKeys } from '@manakin/core/lib/constants';
import { useTranslation } from 'react-i18next';
import LocaleSelect from '@manakin/app-core/LocaleSelect';
import { scroller } from 'react-scroll';

const handleGoToLink = (link) => {
	const withHttp = (url) => (!/^https?:\/\//i.test(url) ? `https://${url}` : url);
	window.open(withHttp(link), '_blank');
};

const Header = (props) => {
	const { config = {} } = props;
	const { t } = useTranslation();

	//useStates
	const [mounted, setIsMounted] = useState(false);
	const [hasHomepageBlocks, setHasHomepageBlocks] = useState(false);

	//useQueries
	const { data = {}, loading } = useQuery(GQL_FETCH_SETTINGS);
	const { data: homepageBlocks = {} } = useQuery(GQL_FETCH_CMS_HOME_PAGE_BLOCKS_IDS, {
		onCompleted: (data) => {
			if (data && data.homepageBlocks && data.homepageBlocks.homepageBlocks && data.homepageBlocks.homepageBlocks.length > 0) {
				setHasHomepageBlocks(true);
			}
		},
	});

	const loginBackgroundColor = useSetting(data.settings, SettingsKeys.HOME_PAGE_BACKGROUND_COLOR, false);

	let homePageWittyLogoEnabled = useSetting(data.settings, SettingsKeys.HOME_PAGE_WITTY_LOGO_ENABLED, false) || false;

	if (typeof homePageWittyLogoEnabled === 'string' && homePageWittyLogoEnabled === 'false') {
		homePageWittyLogoEnabled = false;
	}

	const classes = useStyles({
		loginBackgroundImage: useSetting(data.settings, SettingsKeys.HOME_PAGE_BACKGROUND_IMAGE, false, true),
		loginBackgroundColor: loginBackgroundColor,
		loginCustomerLogo: useSetting(data.settings, SettingsKeys.CUSTOMER_LOGO, false, true),
	})();
	const { pages = {} } = config;
	const { appLogin = {} } = pages;
	const multilanguageEnabled = useBooleanSetting(data.settings, SettingsKeys.MULTILANGUAGE_ENABLED);

	const onScrollIndicatorClick = () => {
		scroller.scrollTo('scrollIndicator', {
			duration: 400,
			smooth: 'easeInOutQuint',
		});
	};

	useEffect(() => {
		setIsMounted(true);
	}, []);

	if (loading) return <Loader fullScreen />;

	return (
		<div className={classes.root}>
			<div className={classes.content}>
				{appLogin.visualTitle && <span className={classes.visualTitle}>{renderHTML(t('app.login.visual-title')) || ''}</span>}
				<div className={classes.visual} />
			</div>
			<div
				className={classNames(classes.login, {
					[classes.mounted]: mounted,
				})}
			>
				{appLogin.showLogo && <div className={classes.logo} />}
				<AppLogin search={props.search} loginBackgroundColor={loginBackgroundColor}>
					{appLogin.showHeaderTitle && (
						<div className={classes.headerTitle}>
							{appLogin.headerTitleIsHtml ? renderHTML(t('app.login.header')) : t('app.login.header')}
						</div>
					)}
				</AppLogin>
				{appLogin.showFootNote && (
					<div className={classes.footNote}>
						{t('app.login.foot-note.text')}
						{t('app.login.foot-note.link') !== '' && (
							<div className={classes.link} onClick={() => handleGoToLink(t('app.login.foot-note.link'))}>
								{t('app.login.foot-note.link')}
							</div>
						)}
						<div className={classes.footNoteExtra} />
					</div>
				)}
				{homePageWittyLogoEnabled && <div className={classes.footerLogo} />}
			</div>
			{multilanguageEnabled && <LocaleSelect />}
			{hasHomepageBlocks && (
				<div name="scrollIndicator" className={classes.scrollIndicator} onClick={onScrollIndicatorClick}>
					<span></span>
					<span></span>
				</div>
			)}
		</div>
	);
};

export default compose(connect(({ config }) => ({ config })))(Header);
