import React, { useState, useEffect, useContext } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { withStyles } from "@material-ui/core/styles";
import createUuid from "uuid/v4";
import classNames from "classnames";
import { Dropable } from "@manakin/core";
import Button from "@material-ui/core/Button";
import { styles } from "./styles";
import Checkbox from "@material-ui/core/Checkbox";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import RedirectIcon from "@manakin/core/icons/Redirect";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MenuIcon from "@manakin/core/icons/Menu";
import Typography from "@material-ui/core/Typography";
import MoveIcon from "@material-ui/icons/DragHandle";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { useTranslation } from 'react-i18next';
import LockIcon from "@material-ui/icons/Lock";
import { BoxContext } from '@manakin/core/contexts';
import { GQL_FETCH_BOX_CONDITIONS_BY_PROGRAM_ID } from '../../graphql';
import { useQuery } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';

const ProgramCard = (props) => {
	const {
		index,
		provided,
		classes,
		snapshot,
		item,
		style,
		grouping = false,
		checkedItems = [],
		readOnly = false,
		unGrouping: _unGrouping,
		config = {},
	} = props;

	const { t } = useTranslation();
	const params = useParams();
	const { setBoxCondition } = useContext(BoxContext);

	const { data: boxConditionData = {}, loading } = useQuery(GQL_FETCH_BOX_CONDITIONS_BY_PROGRAM_ID, {
		variables: { programId: params.programId 
		}
	});

	const { general = {} } = config;
	const idx = item.id ? item.id + 1 : item[0].id;
	const [checked, isChecked] = useState(false);
	const [unGrouping, setIsUngrouping] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [boxConditions, setBoxConditions] = useState([]);

	const boxCondition = boxConditions.find((boxCondition) => boxCondition.boxId === idx) || null;

	//effects
	useEffect(() => {
        if(boxConditionData && boxConditionData.boxConditionsByProgramId) {
            setBoxConditions(boxConditionData.boxConditionsByProgramId);
        }
    }, [boxConditionData])
	useEffect(() => {
		if (snapshot.isDraggingOver) {
			if (props.onCheckboxReset) props.onCheckboxReset();
			isChecked(false);
		}
	}, [props.snapshot]);

	useEffect(() => {
		isChecked(checkedItems[index] || false);
	}, [props.checkedItems]);

	//functions
	const handleBoxConditionDialogOpen = (boxCondition = null) => () => {
		setBoxCondition(boxCondition);
		props.handleBoxConditionDialogOpen();
	}

	const handleUngroupClick = (index) => {
		if (props.onUnGrouping) props.onUnGrouping(index, !unGrouping);
		setIsUngrouping(!unGrouping);
	};

	const handleChange = (index) => {
		if (props.onCheckboxChange) props.onCheckboxChange(index, !checked);
		isChecked(!checked);
	};

	const handleUnGroupCheckboxChange = (index) => {
		if (props.onCheckboxChange) props.onCheckboxChange(index, !checked);
		isChecked(!checked);
	};

	const handleUnGrouping = (index, checked) => {
		if (props.onUnGroupingChange) props.onUnGroupingChange(index, checked);
	};

	const handleRedirect = () => {
		const idx = item.id || item[0].id || false;
		if (idx) props.history.push(`/boxes/${idx}`);
	};

	const handleRemove = () => {
		setAnchorEl(null);
		const idx = item.id || item[0].id || false;
		if (props.onRemove) props.onRemove(index, idx);
	};

	const handleRemoveAlt = (index, idx) => {
		props.onRemoveAlt(index, idx);
	};

	let image = general.hideDummy ? null : "/cms/images/dummy-image.png";
	if (item && item.image && item.image.url) {
		image = item.image.url;
	}
	if (item[0] && item[0].image && item[0].image.url) {
		image = item[0].image.url;
	}

	return (
		<Draggable draggableId={idx} index={index}>
			{(provided, snapshot) => (
				<div
					ref={provided.innerRef}
					{...provided.draggableProps}
					className={classNames(classes.root, {
						[classes.alt]: style == "programItem",
						[classes.container]: item.length > 1,
						[classes.grouping]: grouping,
					})}
				>
					{item.length > 1 && (
						<div>
							<div className={classes.buttonContainer}>
								<Button
									className={classes.groupingButton}
									onClick={() => handleUngroupClick(index)}
								>
									{unGrouping
										? t("core.drag-and-drop.ungroup.save")
										: t("core.drag-and-drop.ungroup.create")}
								</Button>
								<div
									{...provided.dragHandleProps}
									className={classes.moveButtonContainer}
								>
									<div className={classes.moveButton}>
										<MoveIcon
											className={classes.menuIcon}
										/>
									</div>
								</div>
							</div>
							<Dropable
								items={item}
								style="programItem"
								type={"innerDrop"}
								dropId={idx}
								unGrouping={unGrouping}
								onHandleUnGrouping={handleUnGrouping}
								onRemoveAlt={handleRemoveAlt}
							/>
						</div>
					)}
					{(item.length <= 1 || item.id != undefined) && (
						<div
							className={classNames(classes.cardRoot, {
								[classes.cardDrag]: snapshot.isDraggingOver,
							})}
						>
							{grouping && (
								<Checkbox
									color="primary"
									checked={checked}
									onChange={() => handleChange(index)}
									value={checked}
									name="checkbox"
								/>
							)}
							{_unGrouping && (
								<Checkbox
									color="primary"
									checked={checked}
									onChange={() =>
										handleUnGroupCheckboxChange(index)
									}
									value={checked}
									name="checkbox"
								/>
							)}
							<Card className={classes.card}>
								{!general.hideDummy && (
									<img
										className={classes.thumbnail}
										src={
											image ||
											"/cms/images/dummy-image.png"
										}
									/>
								)}
								{general.hideDummy && (
									<React.Fragment>
										{image ? (
											<img
												className={classes.thumbnail}
												src={image}
											/>
										) : (
											<div
												className={classes.thumbnail}
											/>
										)}
									</React.Fragment>
								)}
								<div className={classes.cardContentContainer}>
									<CardHeader
										className={classes.cardHeader}
										action={
											<div className={classes.icons}>
												{boxCondition && (
													<IconButton onClick={handleBoxConditionDialogOpen(boxCondition)}>
														<LockIcon className={classes.lockedIcon} />
													</IconButton>
												)}
												<IconButton
													onClick={handleRedirect}
												>
													<RedirectIcon />
												</IconButton>
												{!readOnly && (
													<div>
														<IconButton
															onClick={(event) =>
																setAnchorEl(
																	event.currentTarget
																)
															}
															aria-owns={
																anchorEl
																	? "simple-menu"
																	: null
															}
															aria-haspopup="true"
														>
															<MenuIcon />
														</IconButton>

														<Menu
															id="simple-menu"
															anchorEl={anchorEl}
															open={Boolean(
																anchorEl
															)}
															onClose={(event) =>
																setAnchorEl(
																	null
																)
															}
														>
															<MenuItem
																onClick={
																	handleRemove
																}
															>
																{t("common.delete")}
															</MenuItem>
														</Menu>
													</div>
												)}
												<div
													{...provided.dragHandleProps}
													className={
														classes.moveButtonContainer
													}
												>
													<div
														className={
															classes.moveButton
														}
													>
														<MoveIcon
															className={
																classes.menuIcon
															}
														/>
													</div>
												</div>
											</div>
										}
										subheader="Box"
									/>
									<CardContent
										className={classes.cardContent}
									>
										<Typography
											className={classNames(
												classes.cardText,
												classes.cardTitle
											)}
											variant="h5"
										>
											{item.name || item[0].name || ""}
										</Typography>
										<Typography
											className={classNames(
												classes.cardText,
												classes.cardDescription
											)}
										>
											{(item && item.description) ||
												(item[0] &&
													item[0].description) ||
												""}
										</Typography>
									</CardContent>
								</div>
							</Card>
						</div>
					)}
				</div>
			)}
		</Draggable>
	);
};

export default compose(
	connect((state) => ({ config: state.config })),
	withStyles(styles),
	withTranslation(),
	withRouter
)(ProgramCard);
