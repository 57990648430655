'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _graphql = require('./graphql');

var _reactApollo = require('react-apollo');

var _recompose = require('recompose');

var _selectors = require('@manakin/app-core/ProgramsDropdown/selectors');

var _selectors2 = require('@manakin/authentication/selectors');

var _selectors3 = require('@manakin/app-core/BoxService/selectors');

var _reactRedux = require('react-redux');

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _Typography = require('@material-ui/core/Typography');

var _Typography2 = _interopRequireDefault(_Typography);

var _styles = require('@material-ui/core/styles');

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _reactRouterDom = require('react-router-dom');

var _reactHooks = require('@apollo/react-hooks');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = function styles(theme) {
    return {
        fontStyle: {
            margin: 0,
            display: 'inline-block',
            color: theme.manakin.primaryColor[500]
        },
        fat: {
            fontWeight: 'bold',
            marginLeft: '2rem'
        },
        root: _defineProperty({
            '&:hover': {
                '& $fontStyle': {
                    color: theme.manakin.defaultContentHover[500]
                }
            },
            display: 'none'
        }, theme.breakpoints.up('md'), {
            display: 'inline-block'
        })
    };
};

var mapStateToProps = function mapStateToProps(state) {
    return {
        appUserData: (0, _selectors2.getAppUser)(state),
        program: (0, _selectors.getProgram)(state),
        newBoxResults: (0, _selectors3.getBoxResults)(state)
    };
};

var YearDropdown = function YearDropdown(props) {
    var data = props.data,
        classes = props.classes,
        _props$newBoxResults = props.newBoxResults,
        newBoxResults = _props$newBoxResults === undefined ? {} : _props$newBoxResults;
    var loading = data.loading,
        _data$appUser = data.appUser,
        appUser = _data$appUser === undefined ? {} : _data$appUser;

    var _useQuery = (0, _reactHooks.useQuery)(_graphql.GQL_FETCH_BOX_RESULTS, {
        variables: {
            program: props.program,
            user: props.appUserData.id
        },
        fetchPolicy: 'no-cache'
    }),
        boxResultsLoading = _useQuery.loading,
        _useQuery$data = _useQuery.data,
        boxResultsData = _useQuery$data === undefined ? {} : _useQuery$data,
        refetch = _useQuery.refetch;

    //state hooks


    var _useState = (0, _react.useState)([]),
        _useState2 = _slicedToArray(_useState, 2),
        yearsArray = _useState2[0],
        setYearsArray = _useState2[1];

    var _useState3 = (0, _react.useState)(),
        _useState4 = _slicedToArray(_useState3, 2),
        progressArray = _useState4[0],
        setProgressArray = _useState4[1];

    var _useState5 = (0, _react.useState)(),
        _useState6 = _slicedToArray(_useState5, 2),
        drogistProgressObj = _useState6[0],
        setdrogistProgressObj = _useState6[1];

    var _useState7 = (0, _react.useState)(0),
        _useState8 = _slicedToArray(_useState7, 2),
        points = _useState8[0],
        setPoints = _useState8[1];

    var _useState9 = (0, _react.useState)(false),
        _useState10 = _slicedToArray(_useState9, 2),
        special = _useState10[0],
        setSpecial = _useState10[1];

    var _useState11 = (0, _react.useState)(5),
        _useState12 = _slicedToArray(_useState11, 2),
        maxPoints = _useState12[0],
        setMaxPoints = _useState12[1];

    var _useState13 = (0, _react.useState)(0),
        _useState14 = _slicedToArray(_useState13, 2),
        currentIdx = _useState14[0],
        setCurrentIdx = _useState14[1];

    var _useState15 = (0, _react.useState)(false),
        _useState16 = _slicedToArray(_useState15, 2),
        userFunction = _useState16[0],
        setUserFunction = _useState16[1];

    var _useState17 = (0, _react.useState)(null),
        _useState18 = _slicedToArray(_useState17, 2),
        year = _useState18[0],
        setYear = _useState18[1];

    var _useState19 = (0, _react.useState)(false),
        _useState20 = _slicedToArray(_useState19, 2),
        functionObj = _useState20[0],
        setFunctionObj = _useState20[1];

    //effect hooks


    (0, _react.useEffect)(function () {
        refetch();
    }, [newBoxResults]);

    (0, _react.useEffect)(function () {
        if (!loading) {
            handleSetYears();
        }
    }, [loading]);

    (0, _react.useEffect)(function () {
        if (yearsArray.length) {
            if (boxResultsData && boxResultsData.boxResults) {
                var boxesProgress = [];
                var DrogistObj = {};
                var _boxResultsData$boxRe = boxResultsData.boxResults,
                    boxResults = _boxResultsData$boxRe === undefined ? [] : _boxResultsData$boxRe;


                boxResults.filter(function (boxResult) {
                    return boxResult.box && boxResult.box.mandatory;
                }).sort(function (a, b) {
                    return (0, _moment2.default)(a.finishedOn).valueOf() - (0, _moment2.default)(b.finishedOn).valueOf();
                }).forEach(function (result) {
                    if (result.finished && !boxesProgress.some(function (item) {
                        return item.box.id === result.box.id;
                    })) {
                        // Continue if the box is not in the list yet and finished
                        if (result.text === '' || result.text === null) {
                            boxesProgress.push(result);
                        }

                        if (result.text !== '' && result.text != null && yearsArray.some(function (_year) {
                            return _year.year == result.text;
                        })) {
                            if (DrogistObj[result.text] === true) {
                                // Already set to true, another box with same year text, add it to keep the points correct
                                boxesProgress.push(result);
                            } else {
                                DrogistObj[result.text] = true;
                            }
                        }
                    }
                });

                setProgressArray(boxesProgress);
                setdrogistProgressObj(DrogistObj);
            }
        }
    }, [yearsArray, boxResultsData, boxResultsLoading]);

    (0, _react.useEffect)(function () {
        var _currentYear = false;
        var _userFunction = false;
        var _idx = 0;

        if (drogistProgressObj && progressArray) yearsArray.forEach(function (year, idx) {
            var yearFunction = functionObj[year.year];
            var maxPoints = idx * 5 + 5;
            if (progressArray.length < maxPoints && !_currentYear) {
                _currentYear = year.year;
                _userFunction = yearFunction;
                _idx = idx;
            }

            if (yearFunction === 'Drogist') {
                if (!drogistProgressObj[year.year] && (!_currentYear || _currentYear >= year.year)) {
                    _currentYear = year.year;
                    _idx = idx;
                    _userFunction = yearFunction;
                    setSpecial(true);
                } else {}
            }
        });
        setYear(_currentYear);
        setUserFunction(_userFunction);
        setCurrentIdx(_idx);
    }, [progressArray, drogistProgressObj, boxResultsData]);

    (0, _react.useEffect)(function () {
        if (year && progressArray) {
            var totalPoints = progressArray.length;
            var idxPoints = currentIdx * 5;
            var diff = totalPoints - idxPoints;

            var _points = totalPoints >= idxPoints ? diff > 5 ? 5 : diff : totalPoints;

            if (userFunction && userFunction === 'Drogist') {
                setMaxPoints(6);
                if (!special) {
                    _points += 1;
                }
            }

            setPoints(_points);
        }
    }, [year, progressArray]);

    //functions
    var handleSetYears = function handleSetYears() {
        if (appUser.userDataCbd) {
            if (appUser.userDataCbd.function && appUser.userDataCbd.function === 'Drogist') {
                setPoints(6);
                setUserFunction(false);
            }

            var arr = [];
            var _obj = {};
            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
                for (var _iterator = Object.entries(appUser.userDataCbd)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                    var _ref = _step.value;

                    var _ref2 = _slicedToArray(_ref, 2);

                    var key = _ref2[0];
                    var value = _ref2[1];

                    if (key.indexOf('trainingMandatory') !== -1 && value) {
                        arr.push({ year: getSecondPart(key, 'trainingMandatory') });
                    }
                    if (key.indexOf('function20') !== -1) {
                        _obj[getSecondPart(key, 'function')] = value || appUser.userDataCbd.function || 'Assistent drogist';
                    }
                }
            } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion && _iterator.return) {
                        _iterator.return();
                    }
                } finally {
                    if (_didIteratorError) {
                        throw _iteratorError;
                    }
                }
            }

            setFunctionObj(_obj);
            setYearsArray(arr);
        }
    };
    var getSecondPart = function getSecondPart(str, firstPart) {
        return str.split(firstPart)[1];
    };

    return _react2.default.createElement(
        _reactRouterDom.Link,
        { to: '/account/results', className: classes.root },
        _react2.default.createElement(
            'div',
            null,
            _react2.default.createElement(
                _Typography2.default,
                {
                    variant: 'body1',
                    className: (0, _classnames2.default)(classes.fontStyle, classes.yearText)
                },
                year ? _react2.default.createElement(
                    _react2.default.Fragment,
                    null,
                    'Nascholing ',
                    year
                ) : _react2.default.createElement(
                    _react2.default.Fragment,
                    null,
                    'Nascholing compleet'
                )
            ),
            year && _react2.default.createElement(
                _Typography2.default,
                {
                    variant: 'body1',
                    className: (0, _classnames2.default)(classes.fat, classes.fontStyle)
                },
                points,
                ' / ',
                maxPoints
            )
        )
    );
};

exports.default = (0, _recompose.compose)((0, _reactRedux.connect)(mapStateToProps), (0, _reactApollo.graphql)(_graphql.GQL_FETCH_APP_USER, {
    options: function options(props) {
        return {
            variables: {
                id: props.id
            }
        };
    }
}), (0, _styles.withStyles)(styles), _reactApollo.withApollo)(YearDropdown);