import React from 'react';
import ReactDOM from 'react-dom';
import Root from './components/Root';
import { store, history } from './lib/store';
import graphqlClient from './lib/graphql-client';
import theme from './styling/themes/standard';
import './index.css';

import { loadConfig } from './config/config';
import loadConfiguration from './config';
import { initI18n } from '@manakin/locale';

loadConfiguration().then((configuration) => store.dispatch(loadConfig(configuration)));

initI18n("q-for-profile", false)
	.then(() => {
		ReactDOM.render(
			<Root
				store={store}
				theme={theme}
				history={history}
				client={graphqlClient} />,
			document.getElementById("root"),
		);
	});

document.addEventListener('copy', (event) => {
	event.preventDefault();
});
