"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _selectors = require("./selectors");

var _actions = require("./actions");

var _styles = require("@material-ui/core/styles");

var _reactRedux = require("react-redux");

var _recompose = require("recompose");

var _FormControl = require("@material-ui/core/FormControl");

var _FormControl2 = _interopRequireDefault(_FormControl);

var _Select = require("@material-ui/core/Select");

var _Select2 = _interopRequireDefault(_Select);

var _MenuItem = require("@material-ui/core/MenuItem");

var _MenuItem2 = _interopRequireDefault(_MenuItem);

var _reduxFirstHistory = require("redux-first-history");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var styles = function styles(theme) {
	return {};
};

var AppBarDropDown = function (_React$PureComponent) {
	_inherits(AppBarDropDown, _React$PureComponent);

	function AppBarDropDown() {
		var _ref;

		var _temp, _this, _ret;

		_classCallCheck(this, AppBarDropDown);

		for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
			args[_key] = arguments[_key];
		}

		return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = AppBarDropDown.__proto__ || Object.getPrototypeOf(AppBarDropDown)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
			options: [],
			option: ""
		}, _this.setStartState = function () {
			var _this$props = _this.props,
			    dropDownOptions = _this$props.dropDownOptions,
			    option = _this$props.option,
			    selectOption = _this$props.selectOption;


			if (dropDownOptions) {
				var selectedOption = option || (dropDownOptions.find(function (option) {
					return option.selected;
				}) || {}).value;

				_this.setState({
					options: [].concat(_toConsumableArray(dropDownOptions)),
					option: selectedOption
				});

				selectOption(selectedOption);
			}
		}, _this.handleChange = function (event) {
			var _this$props2 = _this.props,
			    selectOption = _this$props2.selectOption,
			    goToRedirect = _this$props2.goToRedirect;

			_this.setState({
				option: event.target.value
			}, function () {
				var option = _this.state.option;

				selectOption(option);
				goToRedirect(option);
			});
		}, _temp), _possibleConstructorReturn(_this, _ret);
	}

	_createClass(AppBarDropDown, [{
		key: "componentDidMount",
		value: function componentDidMount() {
			this.setStartState();
		}
	}, {
		key: "componentDidUpdate",
		value: function componentDidUpdate(prevProps) {
			if (prevProps.dropDownOptions.length != this.props.dropDownOptions.length) {
				this.setStartState();
			}
		}
	}, {
		key: "render",
		value: function render() {
			var classes = this.props.classes;
			var _state = this.state,
			    options = _state.options,
			    option = _state.option;


			return _react2.default.createElement(
				_FormControl2.default,
				{ className: classes.formControl },
				_react2.default.createElement(
					_Select2.default,
					{
						value: option,
						onChange: this.handleChange,
						name: "option",
						className: classes.selectEmpty
					},
					options.map(function (item) {
						return _react2.default.createElement(
							_MenuItem2.default,
							{
								key: item.id || item.value,
								selected: item.selected,
								value: item.id || item.value
							},
							item.label
						);
					})
				)
			);
		}
	}]);

	return AppBarDropDown;
}(_react2.default.PureComponent);

exports.default = (0, _recompose.compose)((0, _styles.withStyles)(styles), (0, _reactRedux.connect)(function (state) {
	return {
		options: (0, _selectors.getOptions)(state)
	};
}, function (dispatch) {
	return {
		selectOption: function selectOption(option) {
			return dispatch((0, _actions.selectOption)(option));
		},
		goToRedirect: function goToRedirect(url) {
			return dispatch((0, _reduxFirstHistory.push)("" + url));
		}
	};
}))(AppBarDropDown);