import React, { useState, useEffect } from 'react';
import { ElementOverlay } from '@manakin/app-core';
import { useTranslation } from 'react-i18next';
import { WorkformViewer, getGradeFromItems } from '@manakin/app-core';
import { useHistory, useLocation, useParams } from 'react-router';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { ENTRY_EXAM_TRAINER_ROOT_PATH } from '../Router/Router';

const useStyles = makeStyles((theme) => ({
	info: {
		width: '100%',
		position: 'absolute',
		top: '100px',
		background: 'white',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	infoContent: {
		margin: '0 20px',
	},
}));

const ExamTrainerReview = () => {
	const [step, setStep] = useState(0);
	const { t } = useTranslation();
	const location = useLocation();
	const params = useParams();
	const history = useHistory();

	const { state } = location || {};
	const { items = [] } = state || {};
    const [item, setItem] = useState(null)
	const grade = getGradeFromItems(items);
	const classes = useStyles();

    useEffect(() => {
        const newItem = {
            ...items[step],
            finished: true,
            examReview: true
        }

        setItem(newItem)
    }, [step])

	const handleLeft = () => {
		setStep(Math.max(0, step - 1));
	};

	const push = (url) => {
		history.push({
			pathname: url,
			state: {
				items: items,
				tags: state.tags || null,
				category: state.category
			},
		});
	};

	const handleRight = () => {
		const newStep = step + 1;
		if (newStep > items.length - 1) {
			if (state.programId) {
				push(`/${ENTRY_EXAM_TRAINER_ROOT_PATH}/${state.programId}/programResults`);
			} else if (params.elementId) {
				push(`/${ENTRY_EXAM_TRAINER_ROOT_PATH}/${params.elementId}/results`);
			}
		} else {
			setStep(newStep);
		}
	};

	const handleClose = () => {
		history.goBack();
	};

	return (
		<div>
			<div className={classes.info}>
				<IconButton size="small" onClick={handleLeft}>
					<ChevronLeftIcon fontSize="inherit" />
				</IconButton>
				<Typography className={classes.infoContent}>
					{t('app.elements.exam-trainer.current-question', {
						step: step + 1,
						totalSteps: items.length,
					})}
					<strong>
						{t('app.elements.exam-trainer.your-score', {
							grade: grade,
						})}
					</strong>
				</Typography>
				<IconButton size="small" onClick={handleRight}>
					<ChevronRightIcon fontSize="inherit" />
				</IconButton>
			</div>
			<ElementOverlay
				title={t('common.element-types.exam-trainer')}
				background='white'
				customControls={true}
				onClick={handleClose}>
				{item && <WorkformViewer type={item.type} item={item} options={{ showFeedback: true }} />}
			</ElementOverlay>
		</div>
	);
};

export default ExamTrainerReview;
