import gql from 'graphql-tag';
import { currentAppUser } from '@manakin/app-core/graphQlHelpers';

export const GQL_GET_INVITAION = gql`
    query invitationByToken($token: String!) {
        invitationByToken(token: $token) {
            id
            state
            trainerEmail
            trainerRole {
                id
                name
            }
        }
    }
`;

export const GQL_GET_CURRENT_USER = gql`
    query currentAppUser {
        currentAppUser {
            ${currentAppUser}
        }
    }
`;

export const GQL_LOGIN_APP = gql`
    mutation loginApp($email: String!, $password: String!) {
        loginApp(input: { email: $email, password: $password }) {
            mfa
            mfaDataUri
            showQr
            user {
                ${currentAppUser}
            }
        }
    }
`;

export const GQL_CREATE_APP_USER = gql`
    mutation createAppUser(
        $email: String!
        $password: String
        $firstName: String!
        $middleName: String
        $lastName: String!
        $dateOfBirth: String
        $sendVerifyMail: Boolean
        $roles: [String]
        $function: String
        $educationOrganisation: String
    ) {
        createAppUser(
            input: {
                email: $email
                password: $password
                firstName: $firstName
                middleName: $middleName
                lastName: $lastName
                dateOfBirth: $dateOfBirth
                sendVerifyMail: $sendVerifyMail
                roles: $roles
                function: $function
                educationOrganisation: $educationOrganisation
            }
        ) {
            user {
                id
                fullName
            }
        }
    }
`;

export const GQL_ACCEPT_INVITATION = gql`
    mutation acceptInvitation($input: AcceptInvitationInput!) {
        acceptInvitation(input: $input) {
            id
            state
        }
    }
`;
