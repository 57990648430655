import { createMuiTheme } from '@material-ui/core/styles';
import { createTheme, createColor } from '@manakin/theme';
import {
	green,
	yellow,
	beautySecondary,
	white,
	grey,
	mountain,
	black,
} from '../colors';
import amber from '@material-ui/core/colors/amber';
import blue from '@material-ui/core/colors/blue';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import loginBackgroundImage from '../../assets/login-background.jpg';
import accountBackgroundImage from '../../assets/account-background.jpg';
import paperBackgroundImage from '../../assets/papier.jpg';
import brandVisualBackground from '../../assets/brand-visual.jpg';
import loginVisual from '../../assets/login-visual.svg';
import logo from '../../assets/logo.png';
import pdfIcon from '../../assets/pdf.svg';
import footerLogo from '../../assets/logo.svg';
import calendarVisual from '../../assets/calendar-visual.svg';
import reportVisual from '../../assets/report-visual.svg';
import playIcon from '../../assets/play.svg';

const theme = (obj = {}) => {
	const homePageBlockPrimaryBackgroundColor = obj.homePageBlockPrimaryBackgroundColor;
	const homePageBlockSecondaryBackgroundColor = obj.homePageBlockSecondaryBackgroundColor;

	let _obj = {};

	_obj._primary = '#0062a0';
	_obj._secondary = '#ed960b';
	_obj._tertiary = '#4b2364';
	_obj._palette = obj.themeColor || '#150f10';
	_obj.indication = obj.indicationColor || '#150f10';
	_obj._primaryWorkform = '#0362A0';
	_obj._secondaryWorkform = '#008d43';
	_obj._buttonBorderColor = obj.buttonBorderColor || '#000000';
	_obj.buttonRadius = obj.buttonBorderRadius || '0px';
	_obj.defaultRadius = '25px';

	//dynamic colors
	_obj.defaultBox = obj.defaultBoxColor || _obj._primary;
	_obj.defaultElement =
		obj.defaultWorkformColor || obj.defaultElementColor || _obj._primary;
	_obj.primaryElement = obj.primaryWorkformColor || _obj._secondary;
	_obj.secondaryElement =
		obj.defaultWorkformColor || obj.secondaryElementColor || _obj._tertiary;
	_obj.defaultWorkform = obj.defaultWorkformColor || '#fff';
	_obj.primaryWorkform = obj.primaryWorkformColor || _obj._primaryWorkform;
	_obj.secondaryWorkform =
		obj.secondaryWorkformColor || _obj._secondaryWorkform;
	_obj.buttonBorderColor = createColor(_obj._buttonBorderColor);

	///////COLORS
	_obj.createdPalette = createColor(_obj._palette);
	_obj.indicationColor = createColor(_obj.indication);
	_obj.defaultBoxColor = createColor(_obj.defaultBox);
	_obj.offWhite = createColor('#ece8df');

	//ELEMENT COLORS
	_obj.defaultElementColor = createColor(_obj.defaultElement);
	_obj.primaryElementColor = createColor(_obj.primaryElement);
	_obj.secondaryElementColor = createColor(_obj.secondaryElement);

	//WORKFORM COLORS
	_obj.defaultWorkformColor = createColor(_obj.defaultWorkform);
	_obj.primaryWorkformColor = createColor(_obj.primaryWorkform);
	_obj.secondaryWorkformColor = createColor(_obj.secondaryWorkform);

	//primary and secondary colors
	_obj.primaryColor = createColor(_obj._primary);
	_obj.secondaryColor = createColor(_obj._secondary);
	_obj.tertiaryColor = createColor(_obj._tertiary);
	_obj.mountainColor = createColor(mountain);

	//Palette colors (Used for buttons, icons, navigation enz)
	_obj.primaryPaletteColor = _obj.createdPalette;
	_obj.secondaryPaletteColor = white;

	//type colors
	_obj.typeColor = _obj.tertiaryColor;
	_obj.primaryTypeColor = white;
	_obj.secondaryTypeColor = _obj.primaryPaletteColor;

	//box backgroundColors
	_obj.defaultBoxBGColor = _obj.defaultBoxColor;

	//box name colors
	_obj.defaultBoxColor = _obj.createdPalette;
	_obj.primaryBoxColor = white;
	_obj.secondaryBoxColor = _obj.primaryPaletteColor;

	//element colors
	_obj.defaultElementBGColor = _obj.defaultElementColor;
	_obj.primaryElementBGColor = _obj.primaryElementColor;
	_obj.secondaryElementBGColor = _obj.secondaryElementColor;

	//backgroundColors
	_obj.defaultBackgroundColor = _obj.primaryColor;
	_obj.defaultTitleColor = _obj.primaryPaletteColor;
	_obj.defaultContentColor = createColor('#150f10');
	_obj.secondaryBackgroundColor = grey;

	//Menu colors
	_obj.mainMenuBackground = _obj.primaryPaletteColor;
	_obj.mainMenuColor = beautySecondary;
	_obj.mainMenuSecondaryColor = white;

	//navigation colors
	_obj.navigationColor = _obj.primaryPaletteColor;
	_obj.secondaryNavigationColor = _obj.primaryPaletteColor;
	_obj.linkColor = _obj.primaryPaletteColor;
	_obj.defaultContentHover = _obj.secondaryColor;
	_obj.LessonButtonColor = _obj.primaryPaletteColor;
	_obj.defaultBorderHoverColor = _obj.createdPalette;

	//rest
	_obj.defaultBorderColor = createColor('#E6E6E6');
	_obj.softBorderColor = createColor('#EFEFEF');
	_obj.subHeaderColor = createColor('#8A8A8A');
	_obj.correctAnswer = createColor('#0362A0');
	_obj.homePageBlockPrimaryBackgroundColor = createColor(homePageBlockPrimaryBackgroundColor) || obj.primaryWorkform;
	_obj.homePageBlockSecondaryBackgroundColor = createColor(homePageBlockSecondaryBackgroundColor) || obj.secondaryWorkform;

	_obj.contentWidthXL = '1600px';
	_obj.contentWidthL = '1200px';
	_obj.contentWidthM = '1050px';
	_obj.contentWidthS = '700px';
	_obj.contentWidthXS = '340px';
	//breakpoints
	_obj.smartphoneBreakpoint = '@media only screen and (min-width : 375px)';
	_obj.tabletPortraitBreakpoint =
		'@media only screen and (min-width : 768px)';
	_obj.tabletLandscapeBreakpoint =
		'@media only screen and (min-width : 1024px)';
	_obj.laptopBreakpoint = '@media only screen and (min-width : 1280px)';
	_obj.desktopBreakpoint = '@media only screen and (min-width : 1400px)';
	_obj.desktopXLBreakpoint = '@media only screen and (min-width : 2100px)';

	//margins and paddings
	_obj.mainMenuListItemPaddingTop = '25px';
	_obj.mainMenuListItemPaddingBottom = '25px';
	_obj.defaultPadding = '25px';

	//fonts
	_obj.defaultTitleFont = "'Source Sans Pro', sans-serif";
	_obj.groldBold = "'Source Sans Pro', sans-serif";
	_obj.secondaryTitleFont = "'Source Sans Pro', sans-serif";
	_obj.defaultAnswerFont = "'Source Sans Pro', sans-serif";
	_obj.defaultContentFont = "'Source Sans Pro', sans-serif";
	_obj.recoletaBold = "'Source Sans Pro', sans-serif";

	_obj.workformTitleFont = {
		fontFamily: _obj.defaultTitleFont,
		fontSize: '4rem',
		lineHeight: '130%',
		letterSpacing: 0,
		fontWeight: 800,
	};

	//heights
	_obj.defaultMobileMenuHeight = '64px';
	_obj.defaultDesktopMenuHeight = '100px';

	_obj.defaultWrapper = {
		maxWidth: _obj.contentWidthM,
		position: 'relative',
		margin: '0 auto',
		width: '100%',
		paddingLeft: '25px',
		paddingRight: '25px',
	};
	_obj.extraSmallWrapper = {
		..._obj.defaultWrapper,
		maxWidth: _obj.contentWidthXS,
	};
	_obj.smallWrapper = {
		..._obj.defaultWrapper,
		maxWidth: _obj.contentWidthS,
	};
	_obj.largeWrapper = {
		..._obj.defaultWrapper,
		maxWidth: _obj.contentWidthL,
	};
	_obj.extraLargeWrapper = {
		..._obj.defaultWrapper,
		maxWidth: _obj.contentWidthXL,
	};

	_obj.footerLogoStyle = {
		width: '10rem',
		height: '3rem',
		backgroundImage: `url(${footerLogo})`,
		backgroundSize: 'contain',
		backgroundRepeat: 'no-repeat',
		position: 'absolute',
		right: '10rem',
		bottom: '3rem',
	};

	_obj.colorBarGradient = {
		content: '""',
		display: 'block',
		width: '100%',
		height: '30px',
		position: 'absolute',
		bottom: 0,
		right: 0,
		background:
			'linear-gradient(90deg, rgba(128,167,31,1) 0%, rgba(128,167,31,1) 25%, rgba(202,6,33,1) 25%, rgba(202,6,33,1) 50%, rgba(0,98,160,1) 50%, rgba(0,98,160,1) 75%, rgba(234,183,0,1) 75%)',
	};

	_obj.registrationHeading = {
		large: {
			textAlign: 'center',
			width: '100%',
			maxWidth: '600px',
			fontSize: '40px',
			lineHeight: '130%',
			margin: '0 auto 1em',
		},
		small: {
			fontSize: '28px',
			letterSpacing: '.2rem',
		},
	};

	_obj.breakpoints = createBreakpoints({});
	const realTheme = _theme(_obj);

	return realTheme;
};

const _theme = (props) =>
	createMuiTheme({
		// ...theme,
		typography: {
			htmlFontsize: 10,
			fontFamily: [
				props.defaultContentFont,
				'-apple-system',
				'BlinkMacSystemFont',
				'"Segoe UI"',
				'Roboto',
				'"Helvetica Neue"',
				'Arial',
				'sans-serif',
				'"Apple Color Emoji"',
				'"Segoe UI Emoji"',
				'"Segoe UI Symbol"',
			].join(','),
		},
		palette: {
			primary: props.primaryPaletteColor,
			secondary: props.secondaryPaletteColor,
			success: {
				light: green[200],
				main: green[400],
				dark: green[600],
				contrastText: '#ffffff',
			},
			info: {
				light: blue[300],
				main: blue[400],
				dark: blue[500],
				contrastText: '#ffffff',
			},
			warning: {
				light: amber[400],
				main: amber[600],
				dark: amber[700],
				contrastText: '#ffffff',
			},
		},
		manakin: createTheme({
			accountBackgroundImage: accountBackgroundImage,
			calendarVisual: calendarVisual,
			correctAnswer: props.correctAnswer,
			defaultActiveColor: props.primaryPaletteColor,
			defaultAnswerFont: props.defaultAnswerFont,
			defaultBackgroundColor: props.defaultBackgroundColor,
			defaultBorderColor: props.defaultBorderColor,
			defaultBorderHoverColor: props.defaultBorderHoverColor,
			defaultBoxBGColor: props.defaultBoxBGColor,
			defaultBoxColor: props.defaultBoxColor,
			defaultButtonBorderColor: props.buttonBorderColor,
			defaultButtonRadius: props.buttonRadius,
			defaultContentColor: props.defaultContentColor,
			defaultContentFont: props.defaultContentFont,
			defaultContentHover: props.defaultContentHover,
			defaultDesktopMenuHeight: props.defaultDesktopMenuHeight,
			defaultElementBGColor: props.defaultElementBGColor,
			defaultMobileMenuHeight: props.defaultMobileMenuHeight,
			defaultPadding: props.defaultPadding,
			defaultTitleColor: props.defaultTitleColor,
			defaultTitleFont: props.defaultTitleFont,
			defaultWorkformColor: props.defaultWorkformColor,
			defaultWrapper: props.defaultWrapper,
			desktopBreakpoint: props.desktopBreakpoint,
			desktopXLBreakpoint: props.desktopXLBreakpoint,
			extraLargeWrapper: props.extraLargeWrapper,
			extraSmallWrapper: props.extraSmallWrapper,
			homePageBlockPrimaryBackgroundColor: props.homePageBlockPrimaryBackgroundColor,
			homePageBlockSecondaryBackgroundColor: props.homePageBlockSecondaryBackgroundColor,
			indicationColor: props.indicationColor,
			laptopBreakpoint: props.laptopBreakpoint,
			largeWrapper: props.largeWrapper,
			layoutButtonStyleSelected: {
				backgroundColor: props.primaryPaletteColor[500],
				border: 0,
				color: white[500],
			},
			LessonButtonColor: props.LessonButtonColor,
			linkColor: props.linkColor,
			loginBackgroundImage: loginBackgroundImage,
			loginVisual: loginVisual,
			logo: logo,
			mainMenuBackground: props.mainMenuBackground,
			mainMenuColor: props.mainMenuColor,
			mainMenuListItemPaddingBottom: props.mainMenuListItemPaddingBottom,
			mainMenuListItemPaddingTop: props.mainMenuListItemPaddingTop,
			mainMenuSecondaryColor: props.mainMenuSecondaryColor,
			navigationColor: props.navigationColor,
			pdfIcon: pdfIcon,
			primaryBoxColor: props.primaryBoxColor,
			primaryColor: props.primaryColor,
			primaryElementBGColor: props.primaryElementBGColor,
			primaryPaletteColor: props.primaryPaletteColor,
			primaryTypeColor: props.primaryTypeColor,
			primaryWorkformColor: props.primaryWorkformColor,
			reportVisual: reportVisual,
			secondaryBackgroundColor: props.secondaryBackgroundColor,
			secondaryBoxColor: props.secondaryBoxColor,
			secondaryColor: props.secondaryColor,
			secondaryElementBGColor: props.secondaryElementBGColor,
			secondaryNavigationColor: props.secondaryNavigationColor,
			secondaryTitleFont: props.secondaryTitleFont,
			secondaryTypeColor: props.secondaryTypeColor,
			secondaryWorkformColor: props.secondaryWorkformColor,
			smallWrapper: props.smallWrapper,
			smartphoneBreakpoint: props.smartphoneBreakpoint,
			softBorderColor: props.softBorderColor,
			subHeaderColor: props.subHeaderColor,
			tabletLandscapeBreakpoint: props.tabletLandscapeBreakpoint,
			tabletPortraitBreakpoint: props.tabletPortraitBreakpoint,
			tertiaryColor: props.tertiaryColor,
			typeColor: props.typeColor,
			underline: {
				width: '50px',
				height: '2px',
				backgroundColor: props.defaultContentColor[500],
				position: 'absolute',
				bottom: 0,
				left: '50%',
				transform: 'translate(-50%, 2.5rem)',
			},
			workformTitleFont: props.workformTitleFont,
		}),
		overrides: {
			AccordionFaq: {
				panelRoot: {
					backgroundColor: props.primaryColor[500],
				},
				panelHeading: {
					color: 'white',
				},
				content: {
					color: 'white',
				},
			},
			AppAccountWrapper: {
				root: {
					background: 'none',
				},
				title: {
					letterSpacing: '0rem',
				},
			},
			AppBar: {
				menuText: {
					fontFamily: props.defaultTitleFont,
					[props.breakpoints.up('md')]: {
						fontSize: '2rem',
						fontWeight: 700,
					},
				},
				dropdownInput: {
					fontSize: '1.4rem',
					letterSpacing: '.1rem',
					fontFamily: props.secondaryTitleFont,
					fontWeight: 'bold',
					borderBottom: `2px solid ${black[500]}`,
				},
				menuButtonLabel: {
					fontSize: '2rem',
					fontWeight: 700,
					marginLeft: '22px',
					fontFamily: props.defaultTitleFont,
				},
				buttonRoot: {
					marginLeft: '1rem',
				},
				backButtonRoot: {
					fontSize: '2rem',
					borderColor: black[500],
					'& svg': {
						color: black[500],
					},
				},
				name: {
					fontFamily: props.defaultTitleFont,
					fontSize: '2rem',
					fontWeight: 700,
				},
			},
			appBestTimes: {
				user: {
					backgroundColor: props.primaryColor[500],
				},
				body: {
					color: white[500],
				},
				currentUser: {
					backgroundColor: props.primaryPaletteColor[500],
					'& $body': {
						color: white[500],
					},
					'& $time': {
						color: white[500],
					},
				},
			},
			AppBettingGameFrontPageBody: {
				root: {
					backgroundColor: white[500],
				},
			},
			AppBettingGameFrontpageHeading: {
				headingRoot: {
					backgroundColor: white[500],
				},
				firstButton: {
					backgroundColor: props.primaryColor[500],
					color: white[500],
				},
				secondButton: {
					backgroundColor: props.primaryColor[500],
					color: white[500],
					'&:hover': {
						backgroundColor: 'black',
					},
				},
			},
			AppBettingGameQuestions: {
				points: {
					backgroundColor: props.secondaryColor[500],
					borderRadius: '100%',
				},
			},
			AppBettingGameResultsPage: {
				root: {
					backgroundColor: white[500],
				},
			},
			AppBox: {
				footerLogo: {
					...props.footerLogoStyle,
					right: '12rem',
					bottom: '-39px',
				},
			},
			AppBoxCard: {
				inner: {
					borderRadius: props.defaultRadius,
					padding: '0 6rem',
					[props.breakpoints.up('md')]: {
						padding: '0 6rem',
					},
				},
				boxRoot: {
					width: '100%',
					cursor: 'poiner',
					display: 'flex',
					padding: '0',
					overflow: 'hidden',
					position: 'relative',
					flexFlow: 'column',
					flexWrap: 'wrap',
					transition: 'opacity .3s, transform .3s',
					borderRadius: '25px',
					marginBottom: '2rem',
					justifyContent: 'center',
					[props.breakpoints.up('lg')]: {
						height: '44rem',
					},
					[props.breakpoints.up('lg')]: {
						padding: 0,
					},
					'&:hover': {
						'& $boxDescription': {
							visibility: 'visible',
							transform: 'none',
							opacity: 1,
						},
						'& $boxSubtitle': {
							display: 'none',
						},
						'& $boxName': {
							opacity: 0,
							visibility: 'hidden',
							transform: 'translateY(-32px)',
						},
						'& $boxCardLabel': {
							transform: 'none',
						},
					},
					[props.breakpoints.up('md')]: {
						height: '40rem',
						padding: ' 0 6rem',
					},
				},
				listViewRoot: {
					maxHeight: '14rem',
				},
				boxName: {
					fontWeight: 600,
					letterSpacing: 0,
					color: props.offWhite[500],
					transition: 'all .2s ease',
					[props.breakpoints.up('md')]: {
						fontSize: '3.4rem',
						lineHeight: '4rem',
						maxWidth: '60%',
					},
				},
				primaryColor: {
					color: props.offWhite[500],
				},
				secondaryColor: {
					color: props.defaultContentColor[500],
				},
				boxDescription: {
					color: props.offWhite[500],
					position: 'absolute',
					marginTop: 0,
					visiblity: 'hidden',
					opacity: 0,
					transform: 'translateY(32px)',
					transition: 'all .2s ease',
				},
				boxSubtitle: {
					color: white[500],
				},
				boxCardLabel: {
					color: props.offWhite[500],
					top: '40px',
					[props.breakpoints.up('md')]: {
						top: '40px',
					},
				},
				listView: {
					'& $boxName': {
						letterSpacing: 0,
						[props.breakpoints.up('md')]: {
							letterSpacing: 0,
						},
					},
					'&:hover': {
						'& $boxName': {
							color: props.defaultContentHover[500],
							opacity: 1,
							transform: 'translateY(0)',
							visibility: 'visible',
						},
					},
				},
				percentage: {
					letterSpacing: 0
				},
				percentageDone: {
					backgroundColor: 'black',
					color: props.offWhite[500],
				},
			},
			AppBoxes: {
				wrapper: {
					maxWidth: '1250px',
				},
				smallWrapper: {
					backgroundColor: 'transparent',
					maxWidth: '1250px',
				},
			},
			AppBoxHeader: {
				root: {
					backgroundColor: 'transparent',
					backgroundImage: `url(${paperBackgroundImage})`,
					backgroundSize: 'auto',
					backgroundRepeat: 'repeat',
					[props.breakpoints.up('md')]: {
						marginBottom: '-10rem',
						padding: '18rem 0 15rem',
					},
				},
				resultsPercentage: {
					'&::after': {
						background: `linear-gradient(90deg, rgba(21,15,16,1) 0%, rgba(21,15,16,1) 25%, rgba(21,15,16,0) 25%, rgba(21,15,16,0) 75%, rgba(21,15,16,1) 75%, rgba(21,15,16,1) 100%)`,
						letterSpacing: 0,
						width: '150px',
						color: props.primaryPaletteColor[500],
					},
				},
			},
			AppBoxLayout: {
				wrapper: {
					[props.breakpoints.up('md')]: {
						paddingBottom: '4rem',
					},
				},
				root: {
					position: 'relative',
					marginTop: '-51rem',
					paddingBottom: '10rem',
					backgroundImage: `url(${paperBackgroundImage})`,
					[props.breakpoints.up('md')]: {
						paddingBottom: '14rem',
					},
				},
				footerLogo: {
					...props.footerLogoStyle,
					backgroundImage: 'none',
					right: '12rem',
				},
				listView: {
					backgroundColor: '#fafafa',
					paddingTop: '3rem',
					paddingBottom: '0',
				},
			},
			AppContentHeader: {
				title: {
					letterSpacing: '0rem',
				},
			},
			AppDashboardHeader: {
				root: {
					backgroundColor: 'transparent',
					backgroundImage: `url(${paperBackgroundImage})`,
					backgroundSize: 'auto',
					backgroundRepeat: 'repeat',
					'& $contentColor': {
						color: props.primaryPaletteColor[500],
					},
				},
				content: {
					[props.breakpoints.up('md')]: {
						marginBottom: '4.3rem',
					},
				},
				heading: {
					color: props.defaultTitleColor[500],
				},
				body: {
					color: props.defaultTitleColor[500],
					[props.breakpoints.up('md')]: {
						marginBottom: 0,
					},
				},
				introduction: {
					[props.breakpoints.up('md')]: {
						paddingLeft: '3rem',
						borderLeft: `3px solid ${props.primaryPaletteColor[500]}`,
					},
				},
			},
			AppElementCard: {
				contentColor: {},
				bridgeVideo: {
					borderRadius: props.defaultRadius,
					overflow: 'hidden',
				},
				root: {
					borderRadius: props.defaultRadius,
					'& $contentColor': {
						color: white[500],
					},
				},
				name: {
					letterSpacing: 0,
					fontWeight: 700,
					color: props.offWhite[500],
					[props.breakpoints.up('md')]: {
						fontSize: '3.2rem',
						letterSpacing: 0,
						lineHeight: '4rem',
					},
				},
				secondaryPrefixColor: {
					color: black[500],
				},
				primaryTitleColor: {
					color: props.offWhite[500],
				},
				secondaryTitleColor: {
					color: black[500],
				},
				type: {
					marginBottom: '.25rem',
					fontSize: '1.8rem',
					fontWeight: '500',
					color: props.offWhite[500],
				},
				body: {},
				bodytitle: {},
				bookCheck: {
					backgroundColor: props.tertiaryColor[300],
				},
				bookCheckContent: {
					'& $body': {
						margin: 0,
						color: props.offWhite[500],
					},
					'& $bodytitle': {
						color: props.offWhite[500],
					},
				},
				button: {
					backgroundColor: 'transparent',
					border: `2px solid ${props.offWhite[500]}`,
					color: white[500],
					borderRadius: 0,
					minHeight: '3rem',
					padding: '1.8rem!important',
					[props.breakpoints.up('md')]: {
						minWidth: '27rem',
					},
					'& span': {
						fontSize: '1.8rem',
					},
				},
				time: {
					top: '1.8rem',
					left: '1.6rem',
					[props.breakpoints.up('md')]: {
						top: '3.9rem',
						left: '3rem'
					}
				},
				done: {
					letterSpacing: 0,
					width: '4rem',
					height: '4rem',
					backgroundColor: 'transparent !important',
					color: props.offWhite[500],
					[props.breakpoints.up('md')]: {
						width: '4rem',
						height: '4rem',
					},
				},
				doneIcon: {
					color: black[500]
				},
				bridgeContent: {
					[props.breakpoints.up('md')]: {
						padding: '50px',
					},
				},
			},
			AppElementOverlay: {
				title: {
					textTransform: 'uppercase',
					fontFamily: props.secondaryTitleFont,
					fontWeight: 'bold',
				},
				closeButton: {
					backgroundColor: black[500],
					color: 'white',
					'&:hover': {
						backgroundColor: black[500],
						color: 'white',
					},
				},
				secondary: {
					'& $left': { backgroundColor: props.secondaryColor[500] },
					'& $right': { backgroundColor: props.secondaryColor[500] },
					'& $top': { backgroundColor: props.secondaryColor[500] },
				},
			},
			AppElementsRoot: {
				grid: {
					maxWidth: '1350px',
				},
			},
			AppFactCard: {
				content: {
					'& img': {
						borderRadius: props.defaultRadius,
					},
				},
			},
			AppFactcardReferral: {
				title: {
					color: white[500],
					fontSize: '2.4rem',
					marginBottom: '.4rem',
				},
				referralRoot: {
					borderRadius: props.defaultRadius,
					overflow: 'hidden',
					backgroundColor: props.secondaryColor[500],
				},
				body: { color: white[500] },
				multi: {
					margin: '10px 0px',
					'& $title': {
						marginBottom: '.4rem',
					},
					'& $body': {
						marginBottom: '3rem',
					},
					[props.breakpoints.up('md')]: {
						margin: '0 10px',
					},
				},
				button: {
					backgroundColor: 'transparent',
					border: '2px solid white',
					minHeight: '3rem',
					padding: '1.8rem!important',
					color: white[500],
					[props.breakpoints.up('md')]: {
						minWidth: '27rem',
					},
					'& span': {
						fontSize: '1.8rem',
					},
				},
			},
			AppFactcardsCard: {
				root: {
					borderRadius: props.defaultRadius,
				},
				body: {
					color: props.offWhite[500],
				},
				bodytitle: {
					color: props.offWhite[500],
				},
				bookCheck: {
					backgroundColor: props.primaryColor[500],
				},
				bookCheckContent: {
					'& $body': {
						margin: 0,
						color: props.offWhite[500],
					},
					'& $bodytitle': {
						color: props.offWhite[500],
					},
				},
				button: {
					backgroundColor: 'transparent',
					border: `2px solid ${props.offWhite[500]}`,
					borderRadius: 0,
					minHeight: '3rem',
					color: props.offWhite[500],
					padding: '1.8rem',
					[props.breakpoints.up('md')]: {
						minWidth: '27rem',
					},
					'& span': {
						fontSize: '1.8rem',
					},
				},
			},
			AppFeedbackBox: {
				root: {
					borderRadius: props.defaultRadius,
				},
			},
			AppFirstPage: {
				wrapper: {
					maxWidth: props.contentWidthM,
				},
				titleRoot: {
					[props.breakpoints.up('md')]: {
						fontSize: '6rem',
						lineHeight: '7rem',
						letterSpacing: '.4rem',
					},
					[props.breakpoints.up('lg')]: {
						fontSize: '6rem',
						lineHeight: '7rem',
						letterSpacing: '.4rem',
					},
				},
			},
			AppImageWithSubtitle: {
				image: {
					borderRadius: props.defaultRadius,
					overflow: 'hidden',
				},
			},
			AppLayoutButtons: {
				tabsRoot: {
					marginBottom: '4rem',
				},
				label: {
					fontWeight: 600,
					textTransform: 'uppercase',
					letterSpacing: '.1rem',
				},
			},
			AppLesson: {
				normalMargin: {
					margin: '3rem 0',
					[props.breakpoints.up('md')]: {
						margin: '8rem 0',
					},
				},
			},
			AppLessonBottom: {
				root: {
					backgroundColor: white[500],
					borderTop: `1px solid ${props.defaultBorderColor[500]}`,
				},
				button: {
					margin: '0 1rem',
					minWidth: '300px',
					fontWeight: 700,
				},
				backToBoxButton: {
					width: '100%',
				},
				redoQuestionButton: {
					display: 'flex',
				},
				nextQuestionButton: {
					display: 'none',
				},
			},
			AppLessonHeader: {
				root: {
					'&::after': {
						content: "''",
						position: 'absolute',
						bottom: 0,
						left: 0,
						width: '100%',
						height: '22px',
						backgroundImage: `url(${brandVisualBackground})`,
						backgroundSize: 'cover',
					},
				},
				fontStyle: {
					textTransform: 'uppercase',
					letterSpacing: '.5rem',
					fontWeight: 600,
				},
				heading: {
					letterSpacing: '.5rem',
				},
			},
			AppLessonIntroduction: {
				content: {
					color: props.defaultTitleColor[500],
					fontSize: '2rem',
					lineHeight: '3.6rem',
					fontWeight: 600,
					'&:first-letter': {
						color: props.defaultTitleColor[500],
						fontSize: '2rem',
						lineHeight: '3.6rem',
						fontWeight: '600',
						'&:first-letter': {
							fontSize: '16rem',
							lineHeight: '12rem',
							margin: '0 6rem 0 0',
						},
					},
				},
			},
			AppLogin: {
				root: {
					display: 'flex',
					flexDirection: 'column',
					[props.breakpoints.up('lg')]: {
						marginTop: '9rem',
					},
				},
				link: {
					color: black[500],
					'&:visited': {
						color: black[500],
					},
				},
				registrationLink: {
					order: 0,
					marginTop: '1.6rem',
					[props.breakpoints.up('lg')]: {
						marginTop: '1.6rem',
					},
				},
				button: {
					background: white[500],
					fontSize: '1.8rem',
					fontWeight: 600,
					'&:hover': {
						background: white[500],
						color: props.primaryColor[500],
					},
				},
			},
			appLoginHeader: {
				root: {
					backgroundColor: white[500],
					minHeight: 0,
					paddingBottom: '22px',
					[props.breakpoints.up('lg')]: {
						minHeight: '800px',
					},
					'&::after': {
						content: '""',
						position: 'fixed',
						bottom: 0,
						left: 0,
						width: '100%',
						height: '22px',
						backgroundImage: `url(${brandVisualBackground})`,
						backgroundSize: 'cover',
						zIndex: 999,
					},
				},
				visualTitle: {
					bottom: 'auto',
					top: '50%',
					left: '50%',
					transform:
						'translate(calc(-50% - 481px), calc(-50% + 230px))',
				},
				content: {
					backgroundSize: 'cover',
					backgroundPosition: 'center',
					flex: 2,
				},
				login: {
					maxWidth: '500px !important',
				},
				logo: {
					backgroundImage: `url(${logo})`,
					height: '150px',
					width: '223px',
					backgroundSize: 'contain',
					backgroundRepeat: 'no-repeat',
					right: 'unset',
					position: 'relative',
				},
				headerTitle: {
					fontFamily: props.defaultTitleFont,
					color: white[500],
					fontSize: '5rem',
					fontWeight: '500',
					textAlign: 'center',
				},
				footerLogo: {
					...props.footerLogoStyle,
					display: 'none',
				},
			},
			AppLogout: {
				root: {
					backgroundColor: 'white',
				},
			},
			AppManager: {
				stasticsBody: {
					fontFamily: props.defaultContentFont,
					fontWeight: 'normal',
					fontSize: '1.6rem',
					letterSpacing: 0,
				},
			},
			AppMenu: {
				link: {
					color: white[500],
				},
				paperRoot: {
					backgroundColor: black[500],
				},
			},
			AppOrderQuestion: {
				isCorrect: {
					backgroundColor: props.secondaryColor[500],
				},
				text: {
					fontFamily: props.defaultContentFont,
				},
			},
			appPointsPopup: {
				heading: {
					paddingRight: '30px',
				},
				button: {
					backgroundColor: props.primaryColor[500],
					color: white[500],
					'&:hover': {
						backgroundColor: 'black',
					},
				},
				nextCategory: {
					color: props.defaultContentColor[500],
				},
				points: {
					backgroundColor: props.secondaryColor[500],
					borderRadius: '100%',
				},
			},
			AppProfilePicture: {
				editPicture: {
					color: white[500],
				},
			},
			AppRegistrationAuthData: {
				heading: {
					...props.registrationHeading.large,
					[props.breakpoints.down('md')]: {
						...props.registrationHeading.small,
					},
				},
			},
			AppRegistrationPersonalData: {
				heading: {
					...props.registrationHeading.large,
					[props.breakpoints.down('md')]: {
						...props.registrationHeading.small,
					},
				},
			},
			AppRegistrationSchoolClassData: {
				heading: {
					...props.registrationHeading.large,
					[props.breakpoints.down('md')]: {
						...props.registrationHeading.small,
					},
				},
			},
			AppRegistrationSchoolData: {
				heading: {
					...props.registrationHeading.large,
					[props.breakpoints.down('md')]: {
						...props.registrationHeading.small,
					},
				},
			},
			AppRegistrationScreen: {
				root: {
					minHeight: '100vh',
					backgroundColor: white[500],
				},
				heading: {
					textAlign: 'center',
				},
			},
			AppRegistrationSuccess: {
				root: {
					backgroundColor: props.primaryColor[500],
				},
				content: {
					margin: '0 0 0px',
				},
				heading: {
					...props.registrationHeading.large,
					color: white[500],
					[props.breakpoints.down('md')]: {
						...props.registrationHeading.small,
					},
				},
				paragraph: {
					color: white[500],
				},
				smallParagraph: {
					color: white[500],
				},
			},
			AppResumeButton: {
				root: {
					borderRadius: 0,
					backgroundColor: white[500],
					overflow: 'hidden',
				},
				body1: {
					color: props.primaryPaletteColor[500],
				},
				longArrow: {
					'& .arrow': {
						background: black[500],
					},
				},
			},
			AppSearchInput: {
				root: {
					margin: 0,
				},
			},
			AppSliderInput: {
				sliderLabel: {
					fontSize: '16px',
				},
			},
			AppSortSlider: {
				questions: {
					backgroundColor: props.primaryColor[500],
				},
				questionContent: {
					fontFamily: props.defaultTitleFont,
					fontWeight: 600,
				},
				title: {
					fontSize: '2rem',
					letterSpacing: 0,
					lineHeight: 1.25,
				},
				button: {
					fontWeight: 600,
				},
			},
			AppStatementQuestion: {
				root: {
					borderRadius: props.defaultRadius,
				},
				secondaryColors: {
					'& $contentColor': {
						color: white[500],
					},
				},
				quote: {
					fontFamily: props.defaultContentFont,
					fontSize: '3.2rem',
					fontStyle: 'italic',
					lineHeight: '4rem',
					fontWeight: 'normal',
				},
				button: {
					borderColor: white[500],
					padding: '20px 15px',
				},
				wrapper: {
					[props.breakpoints.up('lg')]: {
						// padding: '5px',
						paddingBottom: '10rem',
					},
				},
			},
			AppStatisticsBox: {
				statistics: {
					[props.breakpoints.up('md')]: {
						border: `1px solid ${props.primaryPaletteColor[100]}`,
					},
				},
				specificStatisticItem: {
					border: `1px solid ${props.primaryPaletteColor[100]}`,
					[props.breakpoints.up('md')]: {
						border: 0,
					},
				},
			},
			AppTeacherHeader: {
				root: {
					backgroundColor: props.secondaryBackgroundColor[500],
					backgroundImage: `url(${paperBackgroundImage})`,
				},
				heading: {
					color: props.defaultTitleColor[500],
				},
				body: {
					color: props.defaultTitleColor[500],
					[props.breakpoints.up('md')]: {
						marginBottom: 0,
					},
				},
				introduction: {
					[props.breakpoints.up('md')]: {
						paddingLeft: '3rem',
						borderLeft: `3px solid ${props.primaryPaletteColor[500]}`,
					},
				},
			},
			AppTeacherProgram: {
				root: {
					backgroundColor: props.secondaryBackgroundColor[500],
				},
				title: {
					letterSpacing: '0rem',
				},
			},
			AppTeacherReport: {
				root: {
					backgroundColor: props.secondaryBackgroundColor[500],
					backgroundImage: `url(${paperBackgroundImage})`,
				},
				content: {
					'& $heading': {
						color: props.defaultTitleColor[500],
					},
				},
			},
			AppTestCard: {
				root: {
					borderRadius: '20px;',
				},
			},
			AppTitleAndText: {
				root: {
					borderRadius: props.defaultRadius,
					backgroundColor: props.primaryColor[500],
					[props.breakpoints.up('lg')]: {
						padding: '10rem 0',
					},
				},
				header: {
					color: white[500],
					fontWeight: 700,
					fontFamily: props.defaultTitleFont,
					paddingBottom: '2rem',
					'&.smallUnderline': {
						'&:after': {
							backgroundColor: white[500],
						},
					},
				},
				secondary: {
					'& $content': {
						color: white[500],
					},
					'& $header': {
						color: white[500],
						'&.smallUnderline': {
							'&:after': {
								backgroundColor: white[500],
							},
						},
					},
				},
				content: {
					color: white[500],
				},
			},
			AppTitleAndTextImage: {
				root: {
					overflow: 'hidden',
				},
				header: {
					paddingBottom: '2rem',
					fontFamily: props.defaultTitleFont,
					fontWeight: 700,
				},
				contentWrapper: {
					[props.breakpoints.up('md')]: {
						position: 'relative',
						padding: 0,
					},
				},
				contentBody: {
					[props.breakpoints.up('md')]: {
						padding: '4.5rem',
					},
					[props.breakpoints.up('lg')]: {
						padding: '5rem 8rem',
					},
				},
				secondary: {
					'& $contentColor': {
						color: white[500],
					},
					'& .smallUnderline': {
						'&:after': {
							backgroundColor: 'white',
						},
					},
				},
				imageWrapper: {
					height: 'auto',
					paddingTop: '0',
					position: 'relative',
				},
				image: {
					objectFit: 'contain',
					borderRadius: props.defaultRadius,
					position: 'relative',
					[props.breakpoints.up('lg')]: {
						height: 'auto',
						borderRadius: props.defaultRadius,
						maxHeight: '700px',
						width: 'auto',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
					},
				},
			},
			AppVideoPlayer: {
				playButton: {
					'& $icon': {
						backgroundColor: white[500],
						backgroundImage: `url(${playIcon})`,
						backgroundRepeat: 'no-repeat',
						backgroundSize: '50%',
						backgroundPosition: '60% center',
						'& svg': {
							display: 'none',
						},
					},
				},
			},
			AppWhatIsWhatItem: {
				isQuestion: {
					fontFamily: props.defaultTitleFont,
				},
			},
			AppWorkformLayout: {
				header: {
					fontWeight: '500',
					marginBottom: '7rem',
					fontStyle: 'italic',
					fontSize: '3.2rem',
					marginTop: '1.8rem',
					[props.breakpoints.down('xs')]: {
						fontSize: '3rem',
					},
				},
				secondaryColors: {
					'& $contentColor': {
						color: white[500],
					},
					'& $button': {
						color: white[500],
					},
				},
			},
			AuthenticationCmsAuthenticator: {
				root: {
					backgroundColor: white[500],
					'&:after': {
						...props.colorBarGradient,
					},
				},
			},
			AuthenticationConfirmReset: {
				root: {
					background: white[500],
				},
			},
			AuthenticationLoginForm: {
				submit: {
					padding: '2rem',
					backgroundColor: black[500],
					marginTop: '3.4rem',
					fontWeight: 600,
					border: '2px solid transparent',
					'&:hover': {
						backgroundColor: white[500],
						border: `2px solid ${black[900]}`,
						color: black[500],
					},
				},
				input: {
					color: 'black',
					'&:before': {
						borderColor: 'rgba(0,0,0,1)',
					},
					'&:after': {
						borderColor: 'rgba(0,0,0, 1)',
					},
				},
				adornment: {
					color: white[500],
				},
				label: {
					color: 'black!important',
					fontWeight: '600',
				},
			},
			AuthenticationResetPassword: {
				heading: {
					...props.registrationHeading.large,
					[props.breakpoints.down('md')]: {
						...props.registrationHeading.small,
					},
				},
			},
			ClaimLicense: {
				root: {
					width: '100%',
					height: '100%',
				},
			},
			FactCardsHeader: {
				wrapper: {
					maxWidth: '75rem',
				},
				root: {
					backgroundColor: 'transparent',
					backgroundSize: 'contain',
					backgroundImage: `url(${paperBackgroundImage})`,
				},
			},
			HeaderFaq: {
				heading: {
					letterSpacing: '0rem',
				},
			},
			HelpButton: {
				icon: {
					marginTop: 0,
				},
			},
			ManakinLoginForm: {
				root: {
					backgroundColor: yellow[500],
				},
			},
			MuiBadge: {
				colorSecondary: {
					backgroundColor: 'red',
				},
			},
			MuiButton: {
				root: {
					zIndex: 1,
					color: white[500],
					height: 'auto',
					padding: '3rem',
					borderRadius: props.buttonRadius,
				},
				contained: {
					boxShadow: 'none',
				},
				label: {
					fontFamily: props.defaultContentFont,
					position: 'relative',
					textTransform: 'none',
					zIndex: 1,
					fontSize: '1.8rem',
					lineHeight: '2rem',
					textDecoration: 'none',
				},
				outlinedPrimary: {
					border: `2px solid ${black[500]}`,
					padding: '18px',
					'&:hover': {
						border: `2px solid ${props.primaryPaletteColor[500]}`,
					},
				},
			},
			MuiChip: {
				label: {
					fontSize: '14px',
				},
			},
			MuiDialog: {
				paper: {
					margin: '18px',
					[props.breakpoints.up('md')]: {
						padding: '4.4rem 5rem 1rem',
					},
				},
				paperFullWidth: {
					minHeight: '53rem',
					borderRadius: 0,
				},
			},
			MuiDialogActions: {
				root: {
					justifyContent: 'center',
					flexDirection: 'column',
					[props.breakpoints.up('md')]: {
						margin: 0,
					},
				},
				action: {
					width: '100%',
					padding: '2rem',
				},
			},
			MuiDialogContent: {
				root: {
					[props.breakpoints.up('md')]: {
						padding: 0,
					},
				},
			},
			MuiDialogTitle: {
				root: {
					[props.breakpoints.up('md')]: {
						padding: 0,
					},
					'&>h2': {
						marginBottom: '4rem',
					},
				},
			},
			MuiDrawer: {
				paper: {
					padding: '3rem',
					fontFamily: props.defaultContentFont,
					fontSize: '1.8rem',
					color: props.mainMenuColor,
					background: props.mainMenuBackground[500],
					[props.breakpoints.up('md')]: {
						padding: '1.8rem 4rem',
						fontSize: '3rem',
					},
				},
			},
			MuiFab: {
				primary: {
					backgroundColor: props.secondaryColor[500],
				},
			},
			MuiFormControl: {
				root: {
					width: '100%',
				},
			},
			MuiFormControlLabel: {
				root: {
					marginRight: '20px',
					width: '40%',
					'@media (min-width: 1400px)': {
						width: 'auto',
						marginRight: '50px',
					},
				},
			},
			MuiFormGroup: {
				root: {
					width: '100%',
					flexDirection: 'row',
				},
			},
			MuiFormHelperText: {
				root: {
					fontSize: '1.25rem',
				},
			},
			MuiFormLabel: {
				root: {
					fontFamily: props.defaultContentFont,
					fontSize: '1.8rem',
					color: props.defaultContentColor[500],
				},
			},
			MuiInput: {
				input: {
					padding: '14px 0',
					fontSize: '1.8rem',
					lineHeight: '3rem',
				},
			},
			MuiInputBase: {
				inputMultiline: {
					minHeight: '14rem',
				},
			},
			MuiMenuItem: {
				root: {
					fontSize: '1.6rem',
				},
			},
			MuiStepConnector: {
				root: {
					display: 'none',
				},
			},
			MuiStepIcon: {
				root: {
					transform: 'scale(1.7)',
					margin: '0 10px',
				},
				text: {
					fontFamily: "'Zilla Slab', serif",
					fontSize: '1.2rem',
				},
			},
			MuiStepLabel: {
				iconContainer: {
					padding: '0 20px 0 0',
				},
				labelContainer: {
					display: 'none',
				},
			},
			MuiStepper: {
				root: {
					backgroundColor: 'transparent',
					padding: '0',
					margin: '30px 0',
				},
			},
			MuiSvgIcon: {
				root: {
					width: '2rem',
					height: '2rem',
				},
			},
			MuiTableCell: {
				root: {
					padding: '4px 16px 4px 16px',
				},
				head: {
					border: 0,
					fontSize: '1.2rem',
					color: props.defaultContentColor[500],
					fontFamily: props.defaultTitleFont,
					lineHeight: '2rem',
					letterSpacing: '.4rem',
				},
				body: {
					paddingTop: '1.5rem',
					paddingBottom: '1.5rem',
				},
			},
			MuiTablePagination: {
				caption: {
					fontSize: '1.6rem',
				},
				select: {
					fontSize: '1.6rem',
					lineHeight: '1.6rem',
					paddingRight: 32,
				},
			},
			MuiTypography: {
				root: {
					fontSize: '2rem',
				},
				h1: {
					fontFamily: props.defaultTitleFont,
					fontSize: '4rem',
					fontWeight: '700',
					lineHeight: '5.2rem',
					color: props.defaultTitleColor[500],
					marginBottom: '1.4rem',
					[props.breakpoints.up('md')]: {
						fontSize: '6rem',
						lineHeight: '7rem',
					},
				},
				h2: {
					fontFamily: props.defaultTitleFont,
					fontSize: '2.85rem',
					lineHeight: '3.85rem',
					fontWeight: 700,
					color: props.defaultTitleColor[500],
					marginBottom: '1.4rem',
					[props.breakpoints.up('md')]: {
						fontSize: '6rem',
						lineHeight: '7rem',
					},
				},
				h3: {
					fontFamily: props.defaultTitleFont,
					fontSize: '3rem',
					lineHeight: '4rem',
					color: props.defaultTitleColor[500],
					fontWeight: 500,
					marginBottom: '1.4rem',
					[props.breakpoints.up('md')]: {
						fontSize: '3.2rem',
						lineHeight: '4rem',
					},
				},
				h4: {
					color: props.defaultTitleColor[500],
					fontFamily: props.defaultTitleFont,
					fontWeight: 500,
					fontSize: '2.4rem',
					lineHeight: '5rem',
					marginBottom: '3.6rem',
					[props.breakpoints.up('md')]: {
						lineHeight: '5.2rem',
						marginBottom: '4.6rem',
					},
				},
				h5: {
					color: props.defaultTitleColor[500],
					fontFamily: props.defaultTitleFont,
					fontSize: '2.4rem',
					fontWeight: 700,
					lineHeight: '3.2rem',
					marginBottom: '1.3rem',
					[props.breakpoints.up('md')]: {
						marginBottom: '0.5rem',
					},
				},
				h6: {
					color: props.defaultTitleColor[500],
					fontFamily: props.defaultTitleFont,
					fontSize: '1.8rem',
					fontWeight: 'bold',
					lineHeight: '2rem',
					marginBottom: '1.3rem',
					letterSpacing: '.2rem',
					[props.breakpoints.up('md')]: {
						marginBottom: '1.6rem',
					},
				},
				subtitle1: {
					fontFamily: props.defaultTitleFont,
					fontSize: '2rem',
					lineHeight: '4rem',
					color: props.defaultContentColor[500],
					marginBottom: '3rem',
				},
				subtitle2: {
					fontFamily: props.secondaryTitleFont,
					fontSize: '2.4rem',
					lineHeight: '150%',
					color: props.defaultContentColor[500],
					marginBottom: '1.4rem',
					fontWeight: '500',
					[props.breakpoints.up('md')]: {
						marginBottom: '4rem',
					},
				},
				body1: {
					fontSize: '1.6rem',
					color: props.defaultContentColor[500],
					fontWeight: 400,
					lineHeight: '2.6rem',
					marginBottom: '2rem',
					[props.breakpoints.up('md')]: {
						fontSize: '1.8rem',
						marginBottom: '3rem',
						lineHeight: '3.6rem',
					},
				},
				body2: {
					fontSmoothing: 'auto',
					color: props.defaultContentColor[500],
					fontWeight: 400,
					fontSize: '1.8rem',
					lineHeight: '2.8rem',
					fontFamily: props.secondaryTitleFont,
					marginBottom: '2rem',
					[props.breakpoints.up('md')]: {
						fontSize: '2rem',
						lineHeight: '3.8rem',
					},
				},
				gutterBottom: {
					marginBottom: '3em',
				},
			},
			PrivateValueLabel: {
				label: {
					fontSize: '14px',
				},
			},
			// MuiRadio: {
			// 	colorPrimary: {
			// 		color: "black",
			// 		"&$checked": {
			// 			color: "black",
			// 		},
			// 	},
			// },
		},
	});

export default theme;
