'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.GQL_FETCH_ELEMENT = exports.GQL_FETCH_BOX = exports.GQL_FETCH_PROGRAM = exports.GQL_FETCH_SEARCH_RESULTS = undefined;

var _templateObject = _taggedTemplateLiteral(['\n    query($search: String) {\n        appSearchResults(search: $search) {\n            type\n            title\n            text\n            image {\n                ', '\n            }\n            program\n            box\n            element\n            factcard\n            news\n        }\n    }\n'], ['\n    query($search: String) {\n        appSearchResults(search: $search) {\n            type\n            title\n            text\n            image {\n                ', '\n            }\n            program\n            box\n            element\n            factcard\n            news\n        }\n    }\n']),
    _templateObject2 = _taggedTemplateLiteral(['\n    query($id: ID!) {\n        program(id: $id) {\n            name\n        }\n    }\n'], ['\n    query($id: ID!) {\n        program(id: $id) {\n            name\n        }\n    }\n']),
    _templateObject3 = _taggedTemplateLiteral(['\n    query($id: ID!) {\n        box(id: $id) {\n            preTitle\n            appTitle\n        }\n    }\n'], ['\n    query($id: ID!) {\n        box(id: $id) {\n            preTitle\n            appTitle\n        }\n    }\n']),
    _templateObject4 = _taggedTemplateLiteral(['\n    query($id: ID!) {\n        element(id: $id) {\n            preTitle\n        }\n    }\n'], ['\n    query($id: ID!) {\n        element(id: $id) {\n            preTitle\n        }\n    }\n']);

var _graphqlTag = require('graphql-tag');

var _graphqlTag2 = _interopRequireDefault(_graphqlTag);

var _graphQlHelpers = require('@manakin/app-core/graphQlHelpers');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var GQL_FETCH_SEARCH_RESULTS = exports.GQL_FETCH_SEARCH_RESULTS = (0, _graphqlTag2.default)(_templateObject, _graphQlHelpers.appAssetWithThumbnail);

var GQL_FETCH_PROGRAM = exports.GQL_FETCH_PROGRAM = (0, _graphqlTag2.default)(_templateObject2);

var GQL_FETCH_BOX = exports.GQL_FETCH_BOX = (0, _graphqlTag2.default)(_templateObject3);

var GQL_FETCH_ELEMENT = exports.GQL_FETCH_ELEMENT = (0, _graphqlTag2.default)(_templateObject4);