import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import renderHTML from 'react-render-html';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import { ImageWithFocusPoint } from "@manakin/app-core";
import { useReadSpeaker } from "@manakin/hooks";
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
	root: {
		display: 'flex',
		flexFlow: 'column nowrap',
		backgroundColor: theme.manakin.defaultWorkformColor[500],
		[theme.breakpoints.up('lg')]: {
			flexFlow: 'row wrap',
		},
	},
	contentColor: {
		color: theme.palette.getContrastText(theme.manakin.defaultWorkformColor[500]),
	},
	primary: {
		backgroundColor: theme.manakin.primaryWorkformColor[500],
		'& $contentColor': {
			color: theme.palette.getContrastText(theme.manakin.primaryWorkformColor[500]),
			'&::after': {
				backgroundColor: theme.palette.getContrastText(theme.manakin.primaryWorkformColor[500]),
			},
		},
		'& $header': {
			color: theme.palette.getContrastText(theme.manakin.primaryWorkformColor[500]),
			'&.smallUnderline': {
				'&:after': {
					backgroundColor: theme.palette.getContrastText(theme.manakin.primaryWorkformColor[500]),
				},
			},
		},
	},
	secondary: {
		backgroundColor: theme.manakin.secondaryWorkformColor[500],
		'& $contentColor': {
			color: theme.palette.getContrastText(theme.manakin.secondaryWorkformColor[500]),
			'&::after': {
				backgroundColor: theme.palette.getContrastText(theme.manakin.secondaryWorkformColor[500]),
			},
		},
		'& $header': {
			color: theme.palette.getContrastText(theme.manakin.secondaryWorkformColor[500]),
			'&.smallUnderline': {
				'&:after': {
					backgroundColor: theme.palette.getContrastText(theme.manakin.secondaryWorkformColor[500]),
				},
			},
		},
	},
	noBG: {
		backgroundColor: 'white',
		'& $contentColor': {
			color: theme.manakin.defaultContentColor[500],
			'&::after': {
				backgroundColor: theme.manakin.defaultContentColor[500],
			},
		},
		'& $header': {
			color: theme.manakin.defaultContentColor[500],
			'&.smallUnderline': {
				'&:after': {
					backgroundColor: theme.manakin.defaultContentColor[500],
				},
			},
		},
	},
	contentBody: {
		position: 'relative',
		padding: theme.manakin.defaultPadding,
		width: '100%',
		paddingTop: '60px',
		'& ul, & ol': {
			listStylePosition: 'inside',
		},
		[theme.breakpoints.up('md')]: {
			padding: '4.5rem',
		},
		[theme.breakpoints.up('lg')]: {
			padding: '15rem',
			width: '50%',
		},
	},
	contentWrapper: {
		position: 'relative',
		top: 0,
		left: 0,
		display: 'flex',
		flexFlow: 'column',
		justifyContent: 'flex-start',
		height: '100%',
		overflow: 'hidden',
		WebkitOverflowScrolling: 'touch',
		[theme.breakpoints.up('lg')]: {
			position: 'absolute',
			padding: '8% 15%',
		},
	},
	content: {
		margin: 'auto',
		wordBreak: "break-word",
		overflow: "hidden"
	},
	header: {
		...theme.manakin.workformTitleFont,
		marginBottom: '2.2rem',
		paddingBottom: '3.4rem',
		[theme.breakpoints.down('xs')]: {
			fontSize: '3rem',
			lineHeight: '4rem',
		},
		'&.smallUnderline': {
			'&:after': {
				backgroundColor: theme.palette.getContrastText(theme.manakin.defaultWorkformColor[500]),
			},
		},
	},
	body: {
		margin: 0,
		'@global ul': {
			paddingLeft: '2.25rem',
		},
	},
	button: {
		marginTop: '3rem',
	},
	imageWrapper: {
		position: 'relative',
		width: '100%',
		[theme.breakpoints.up('lg')]: {
			height: 0,
			paddingTop: '50%',
			width: '50%',
		},
	},
	image: {
		width: '100%',
		height: '100%',
		objectFit: 'cover',
		top: '0',
		left: '0',
		[theme.breakpoints.up('lg')]: {
			position: 'absolute',
		},
	},
	left: {
		order: 1,
	},
	right: {
		order: 2,
	},
});

const TitleAndTextImage = (props) => {
    const { classes, data, withNext = false } = props;
    const backgroundColor = data.backgroundColor || null;
    const { t } = useTranslation();
    const { readSpeaker, readSpeakerId } = useReadSpeaker("readSpeaker-titleAndTextImage-" + data.id);
    let style;

    //function
    const handleClick = () => {
        if (props.onFinished) props.onFinished();
    };

    return (
		<div>
			<div
				className={classNames(
					classes.root,
					{ [classes.primary]: backgroundColor == 'PRIMARY' },
					{ [classes.secondary]: backgroundColor == 'SECONDARY' },
					{ [classes.noBG]: backgroundColor == 'NONE' }
				)}
			>
				<div
					className={classNames(classes.contentBody, {
						[classes.right]: data.imagePosition == 'links',
					})}
					style={style}
				>
					<div className={classes.contentWrapper}>
						<div className={classes.content} id={readSpeakerId}>
							{data.workTitle &&
								<Typography
									component="h2"
									variant="h2"
									classes={{ h2: classes.header }}
									className={classNames('smallUnderline', classes.contentColor)}
								>
									{data.workTitle || ''}
								</Typography>
							}
							<Typography component="div" variant="body1" classes={{ body1: classes.body }} className={classes.contentColor}>
								{readSpeaker && renderHTML(readSpeaker)}
								{renderHTML(data.text || '')}
							</Typography>
						</div>
					</div>
				</div>
				<div className={classes.imageWrapper}>
					<ImageWithFocusPoint
						asset={data.image}
						imageProps={{
							className: classNames(classes.image, {
								[classes.left]: data.imagePosition == 'links',
							}),
						}}
					/>
				</div>
			</div>
			{withNext && (
				<Button variant="contained" color="primary" className={classes.button} onClick={handleClick}>
					{t('common.next')}
				</Button>
			)}
		</div>
	);
};

export default compose(
    withStyles(styles, { name: 'AppTitleAndTextImage' })
)(TitleAndTextImage);
