'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});
exports.requestedRolesInGivenRoles = exports.userHasAnyRole = exports.schoolManagerAndTeacherRoles = exports.teacherRoles = exports.studentRoles = exports.trainerRoles = exports.testRole = exports.demoRole = exports.myspotTeacherRole = exports.teacherRole = exports.schoolManagerRole = exports.studentRole = exports.bpvRole = exports.poRole = exports.managementRole = exports.isMobile = exports.stringIsEmpty = exports.getGradeFromItems = exports.parseLocalDateTime = exports.getHumanReadableRoleTitle = exports.getFirstOrUndefined = exports.filterRatingRequestsIgnoreBpvb = exports.filterPendingRatingRequests = exports.getExerciseRating = exports.getTestPercentage = exports.getPercentage = exports.newGetPercentage = exports.useWhiteOrBlackTextColor = exports.getMostFrequentInArray = exports.findInArrayByListOption = exports.uniqueArrayByKey = exports.isInArray = exports.savable = exports.forProgress = exports.workformElements = exports.isFlatWorkform = exports.showFeedback = undefined;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var showFeedback = exports.showFeedback = function showFeedback(obj, t) {
	var key = obj.dataKey;
	var data = obj.data.data[key];
	var variant = obj.data.variant;
	var oldFeedback = obj.feedback;
	var skipHint = obj.skipHint;

	var feedbackObj = {
		title: '',
		content: '',
		buttonText: '',
		done: false,
		hint: false,
		showFeedback: true
	};

	if (data) {
		var hasHint = false;
		if (!skipHint || skipHint == undefined) {
			hasHint = data.hintText ? data.hintText == '<p><br></p>' ? false : true : false;
		}

		if (obj.correct) {
			feedbackObj.title = t('common.good');
			feedbackObj.content = data.feedbackCorrect || '';
			feedbackObj.buttonText = variant == 'LESSON' ? '' : t('common.resume');
			feedbackObj.done = true;
		} else {
			if (variant == 'trainer') {
				feedbackObj.done = true;
			} else if (oldFeedback == undefined || !oldFeedback.hint) {
				feedbackObj.title = hasHint ? t('common.hint') : t('common.unfortunately');
				feedbackObj.content = hasHint ? data.hintText : data.feedbackInCorrect || '';
				feedbackObj.done = hasHint ? false : true;
				feedbackObj.hint = true;
				if (hasHint) {
					feedbackObj.buttonText = data.buttonText || t('common.check-answer');
				} else {
					feedbackObj.buttonText = variant == 'LESSON' ? '' : t('common.resume');
				}
			} else {
				feedbackObj.title = 'Helaas';
				feedbackObj.content = data.feedbackInCorrect || '';
				feedbackObj.done = true;
				feedbackObj.hint = true;
				feedbackObj.buttonText = variant == 'LESSON' ? '' : t('common.resume');
			}
		}
	}

	return feedbackObj;
};

var isFlatWorkform = exports.isFlatWorkform = function isFlatWorkform(type) {
	if (!type) return false;
	switch (type) {
		case 'TitleAndText':
		case 'TitleAndTextImage':
		case 'FactCardReferral':
		case 'ImageWithSubtitle':
		case 'FileDownload':
		case 'HotspotMap':
		case 'Moodboard':
			return true;
		default:
			return false;
	}
};

var workformElements = exports.workformElements = function workformElements(arr) {
	var _arr = [];

	if (arr != undefined) {
		arr.forEach(function (e) {
			switch (e.type) {
				case 'BookCheck':
				case 'Case':
				case 'Film':
				case 'Lesson':
				case 'MasterClass':
				case 'EntryTest':
				case 'Trainer':
				case 'ExerciseCard':
				case 'BettingGame':
				case 'Summary':
					_arr.push(e);
					break;
			}
		});
	}

	return _arr;
};

var forProgress = exports.forProgress = function forProgress(arr) {
	var _arr = [];

	if (arr != undefined) {
		arr.forEach(function (e) {
			switch (e.type) {
				case 'BookCheck':
				case 'Case':
				case 'Film':
				case 'Lesson':
				case 'MasterClass':
				case 'EntryTest':
				case 'Trainer':
				case 'Test':
				case 'ExerciseCard':
				case 'BettingGame':
				case 'Summary':
					_arr.push(e);
					break;
			}
		});
	}

	return _arr;
};

var savable = exports.savable = function savable(arr) {
	var _arr = [];

	if (arr != undefined) {
		arr.forEach(function (e) {
			switch (e.type) {
				case 'StatementQuestion':
				case 'ImageAndTextCheckQuestion':
				case 'ImageCheckQuestion':
				case 'TextCheckQuestion':
				case 'OrderQuestion':
				case 'TextMPC':
				case 'WhatIsWhatQuestion':
				case 'ImageMPC':
				case 'OpenQuestion':
				case 'Hotspot':
				case 'ImageAndTextMPC':
					_arr.push(e);
					break;
			}
		});
	}

	return _arr;
};

var isInArray = exports.isInArray = function isInArray(val, arr) {
	var i = false;

	if (arr.indexOf(val) >= 0) i = true;
	return i;
};

var uniqueArrayByKey = exports.uniqueArrayByKey = function uniqueArrayByKey(array, key) {
	var unique = new Set();
	return array.filter(function (item) {
		return item && (unique.has(item[key]) ? false : unique.add(item[key]));
	});
};

var findInArrayByListOption = exports.findInArrayByListOption = function findInArrayByListOption(array, option) {
	return array.find(function (item) {
		return item.id == (option.value || option.id);
	});
};

var getMostFrequentInArray = exports.getMostFrequentInArray = function getMostFrequentInArray(array) {
	var map = array.reduce(function (acc, val) {
		acc[val] = (acc[val] || 0) + 1;
		return acc;
	}, {});

	return Object.keys(map).reduce(function (a, b) {
		return map[a] > map[b] ? a : b;
	});
};

var useWhiteOrBlackTextColor = exports.useWhiteOrBlackTextColor = function useWhiteOrBlackTextColor(hex) {
	if (hex && hex.length >= 7) {
		var hexToRGB = function hexToRGB(hex) {
			return ['0x' + hex[1] + hex[2] | 0, '0x' + hex[3] + hex[4] | 0, '0x' + hex[5] + hex[6] | 0];
		};
		var rgb = hexToRGB(hex);
		return (rgb[0] * 299 + rgb[1] * 587 + rgb[1] * 114) / 1000 > 125 ? '#000000' : '#FFFFFF';
	} else {
		return '#FFFFFF';
	}
};

var isInArr = function isInArr(item, arr) {
	var isInArray = false;
	arr.forEach(function (i) {
		if (i.id === item) isInArray = true;
	});
	return isInArray;
};

var removedeprocated = function removedeprocated(arr1, arr2) {
	var newArr = [];
	arr1.forEach(function (item) {
		if (item.element && item.element.id) {
			if (isInArr(item.element.id, arr2)) newArr.push(item);
		}
	});

	return newArr;
};

var newGetPercentage = exports.newGetPercentage = function newGetPercentage(results, boxId) {
	var percentage = 0;

	if (results && results.length) {
		results.forEach(function (i) {
			if (i.box.id == boxId) {
				percentage = i.progress;
			}
		});
	}
	return percentage;
};

var getPercentage = exports.getPercentage = function getPercentage(results, elements, minGrade) {
	var _elementResults = forProgress(removedeprocated(results, elements).map(function (item) {
		return _extends({}, item, {
			type: item.element.type
		});
	}));

	var _elements = forProgress(elements);
	var finished = 0;

	_elementResults && _elementResults.forEach(function (r) {
		if (_elements.some(function (el) {
			return el.id === r.element.id;
		})) {
			if (r.type === 'Test') {
				if (r.rating && parseFloat(r.rating) > parseFloat(minGrade)) finished++;
			} else if (r.finished) {
				finished++;
			}
		}
	});

	var percentage = finished / _elements.length * 100 || 0;
	if (finished === _elements.length) percentage = 100;

	return percentage;
};

var getTestPercentage = exports.getTestPercentage = function getTestPercentage(results, elements) {
	var _elementResults = workformElements(removedeprocated(results, elements).map(function (item) {
		return _extends({}, item, {
			type: item.element.type
		});
	}));

	var _elements = workformElements(elements);
	var finished = 0;

	_elementResults && _elementResults.forEach(function (r) {
		if (r.finished) finished++;
	});

	var percentage = finished / _elements.length * 100 || 0;
	if (finished === _elements.length) percentage = 100;

	return percentage;
};

var getExerciseRating = exports.getExerciseRating = function getExerciseRating(element) {
	if (element.rating && element.rating.includes('<strong>')) {
		return element.rating.split('<strong>').pop().split('</strong>')[0];
	} else {
		return element.rating;
	}
};

var filterPendingRatingRequests = exports.filterPendingRatingRequests = function filterPendingRatingRequests(data) {
	if (data && data.ratingRequests) {
		var requests = [];

		data.ratingRequests.forEach(function (request) {
			if (request.result || request.finalRating === false && request.attempt === 1 && data.ratingRequests.find(function (findRequest) {
				return findRequest.attempt === 2 && findRequest.box.id === request.box.id;
			})) {
				// Skip this request if there is a result, or because there is an request with attempt 2
			} else {
				requests.push(request);
			}
		});

		return requests;
	}
};

var filterRatingRequestsIgnoreBpvb = exports.filterRatingRequestsIgnoreBpvb = function filterRatingRequestsIgnoreBpvb(ratingRequests) {
	return ratingRequests.filter(function (rating) {
		return rating.trainer && rating.trainer.roles && !rating.trainer.roles.some(function (role) {
			return bpvRole.includes(role.name);
		});
	});
};

var getFirstOrUndefined = exports.getFirstOrUndefined = function getFirstOrUndefined(array) {
	if (array && array.length) return array[0];else return undefined;
};

var getHumanReadableRoleTitle = exports.getHumanReadableRoleTitle = function getHumanReadableRoleTitle(t, role) {
	switch (role) {
		case managementRole:
			return t('common.roles.management');
		case poRole:
			return t('common.roles.po');
		case bpvRole:
			return t("common.roles.bpv");
		case demoRole:
			return t("common.roles.demo");
		case studentRole:
			return t('common.roles.student');
		case schoolManagerRole:
			return t('common.roles.school-manager');
		case teacherRole:
			return t('common.roles.teacher');
		case myspotTeacherRole:
			return t('common.roles.myspot-teacher');
		default:
			return role;
	}
};

var parseLocalDateTime = exports.parseLocalDateTime = function parseLocalDateTime(date, t) {
	return _moment2.default.utc(date).local().locale(t('common.locale-code'));
};

var getGradeFromItems = exports.getGradeFromItems = function getGradeFromItems(items) {
	var correct = items.filter(function (i) {
		return i.correct;
	});

	var percentage = correct.length / items.length * 100 || 0;
	return (percentage / 10).toFixed(1);
};

var stringIsEmpty = exports.stringIsEmpty = function stringIsEmpty(text) {
	return text === '' || text === null || text === undefined;
};

var isMobile = exports.isMobile = window.innerWidth < 768;
var managementRole = exports.managementRole = "MANAGEMENT";
var poRole = exports.poRole = "PO";
var bpvRole = exports.bpvRole = "BPVB";
var studentRole = exports.studentRole = "STUDENT";
var schoolManagerRole = exports.schoolManagerRole = "SCHOOL_MANAGER";
var teacherRole = exports.teacherRole = "TEACHER";
var myspotTeacherRole = exports.myspotTeacherRole = "MYSPOT_TEACHER";
var demoRole = exports.demoRole = "DEMO";
var testRole = exports.testRole = "TEST";
var trainerRoles = exports.trainerRoles = [poRole, bpvRole];
var studentRoles = exports.studentRoles = [studentRole, demoRole];
var teacherRoles = exports.teacherRoles = [teacherRole, myspotTeacherRole];
var schoolManagerAndTeacherRoles = exports.schoolManagerAndTeacherRoles = [schoolManagerRole].concat(teacherRoles);

/**
 * Check if the given user has any of the given roles. You can provide an array of roles or a single role
 */
var userHasAnyRole = exports.userHasAnyRole = function userHasAnyRole(appUser, requiredRoles) {
	return appUser && appUser.roles && appUser.roles.some(function (role) {
		return (Array.isArray(requiredRoles) ? requiredRoles : [requiredRoles]).includes(role.name);
	});
};

/**
 * Check if any of the given roles is in the required roles. For example, check if any role is one of the teacher roles
 */
var requestedRolesInGivenRoles = exports.requestedRolesInGivenRoles = function requestedRolesInGivenRoles(requiredRoles, givenRoles) {
	return requiredRoles.some(function (role) {
		return givenRoles.includes(role);
	});
};