import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
    (theme) =>
        createStyles({
            root: {
                width: '100%',
                display: 'flex',
                flexFlow: 'row wrap',
                backgroundColor: theme.manakin.defaultWorkformColor[500],
                position: 'relative',
                height: "100%",
                [theme.breakpoints.down('sm')]: {
                    flexFlow: 'column nowrap',
                },
            },
            primary: {
                backgroundColor: theme.manakin.primaryWorkformColor[500],
                '& $content': {
                    color: theme.palette.getContrastText(theme.manakin.primaryWorkformColor[500]),
                },
            },
            secondary: {
                backgroundColor: theme.manakin.secondaryWorkformColor[500],
                '& $content': {
                    color: theme.palette.getContrastText(theme.manakin.secondaryWorkformColor[500]),
                },
            },
            noBG: {
                backgroundColor: 'white',
                border: `2px solid ${theme.manakin.defaultContentColor[500]}`,
                '& $content': {
                    color: theme.manakin.defaultContentColor[500],
                },
            },
            wrapper: {
                width: '100%',
                maxWidth: '100%'
            },
            content: {
                padding: '3rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                flexFlow: 'row wrap',
                [theme.breakpoints.up('md')]: {
                    flex: '1 0 calc(100% - 250px)',
                    padding: '3rem 5rem',
                    flexFlow: 'row nowrap',
                },
            },
            textContent: {
                [theme.breakpoints.up('md')]: {
                    flex: 1,
                },
            },
            image: {
                backgroundColor: theme.manakin.defaultWorkformColor[500],
                position: 'relative',
                minHeight: '150px',
                flex: '0 1 150px',
            },
            body: {
                color: theme.palette.getContrastText(
                    theme.manakin.defaultWorkformColor[500],
                ),
                [theme.breakpoints.up('md')]: {
                    margin: 0,
                },
            },
            title: {
                fontSize: '28px',
                lineHeight: 'normal',
                marginBottom: theme.spacing(2),
                color: theme.palette.getContrastText(
                    theme.manakin.defaultWorkformColor[500],
                ),
            },
            imageSrc: {
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                fontFamily: "'object-fit: cover'",
                position: 'absolute',
                left: '0',
                right: '0',
            },
            button: {
                color: theme.palette.getContrastText(
                    theme.manakin.defaultWorkformColor[500],
                ),
            },
        }),
);
