'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styles = require('@material-ui/core/styles');

var _Typography = require('@material-ui/core/Typography');

var _Typography2 = _interopRequireDefault(_Typography);

var _reactMedia = require('react-media');

var _reactMedia2 = _interopRequireDefault(_reactMedia);

var _Menu = require('../Menu/Menu');

var _Menu2 = _interopRequireDefault(_Menu);

var _recompose = require('recompose');

var _reactRedux = require('react-redux');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = function styles(theme) {
    return {
        root: {
            padding: '180px 0',
            margin: '0 auto',
            position: 'relative',
            minHeight: '100vh',
            background: 'url(' + theme.manakin.accountBackgroundImage + ') top right / auto 60vh fixed no-repeat'
        },
        mobileRoot: {
            padding: '40px 0',
            margin: '0 auto',
            position: 'relative'
        },
        smallWrapper: _extends({}, theme.manakin.smallWrapper),
        wrapper: _extends({}, theme.manakin.defaultWrapper, {
            maxWidth: '800px'
        }),
        title: _defineProperty({
            fontSize: '6rem',
            lineHeight: '7rem',
            position: 'relative',
            margin: '0 0 7rem',
            letterSpacing: '0.5rem'
        }, theme.breakpoints.down('sm'), {
            fontSize: '4rem',
            lineHeight: '5rem'
        }),
        underline: {
            width: '50px',
            height: '2px',
            backgroundColor: theme.manakin.defaultContentColor[500],
            position: 'absolute',
            bottom: 0,
            left: '50%',
            transform: 'translate(-50%, 3rem)'
        },
        children: {
            margin: '8rem 0 0'
        },
        introduction: {
            whiteSpace: "pre-wrap"
        }
    };
};

var AccountWrapper = function (_React$PureComponent) {
    _inherits(AccountWrapper, _React$PureComponent);

    function AccountWrapper() {
        var _ref;

        var _temp, _this, _ret;

        _classCallCheck(this, AccountWrapper);

        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
        }

        return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = AccountWrapper.__proto__ || Object.getPrototypeOf(AccountWrapper)).call.apply(_ref, [this].concat(args))), _this), _this.renderGeneral = function () {
            var _this$props = _this.props,
                children = _this$props.children,
                classes = _this$props.classes,
                _this$props$title = _this$props.title,
                title = _this$props$title === undefined ? '' : _this$props$title,
                _this$props$introduct = _this$props.introduction,
                introduction = _this$props$introduct === undefined ? '' : _this$props$introduct,
                _this$props$config = _this$props.config,
                config = _this$props$config === undefined ? {} : _this$props$config;
            var _config$pages = config.pages,
                pages = _config$pages === undefined ? {} : _config$pages;

            var renderMenu = pages.appAccount && pages.appAccount.showMenu != undefined ? pages.appAccount.showMenu : true;
            return _react2.default.createElement(
                'div',
                null,
                renderMenu && _react2.default.createElement(_Menu2.default, { location: location }),
                _react2.default.createElement(
                    'div',
                    { className: classes.smallWrapper },
                    _react2.default.createElement(
                        _Typography2.default,
                        {
                            align: 'center',
                            variant: 'h2',
                            classes: { h2: classes.title }
                        },
                        title,
                        _react2.default.createElement('span', { className: classes.underline })
                    ),
                    _react2.default.createElement(
                        _Typography2.default,
                        { align: 'center', variant: 'body1', className: classes.introduction },
                        introduction
                    )
                ),
                _react2.default.createElement(
                    'div',
                    { className: classes.wrapper },
                    _react2.default.createElement(
                        'div',
                        { className: classes.children },
                        children
                    )
                )
            );
        }, _temp), _possibleConstructorReturn(_this, _ret);
    }

    _createClass(AccountWrapper, [{
        key: 'render',
        value: function render() {
            var _this2 = this;

            var classes = this.props.classes;

            return _react2.default.createElement(
                _reactMedia2.default,
                { query: '(max-width: 1140px)' },
                function (matches) {
                    return matches ? _react2.default.createElement(
                        'div',
                        { className: classes.mobileRoot },
                        _this2.renderGeneral()
                    ) : _react2.default.createElement(
                        'div',
                        { className: classes.root },
                        _this2.renderGeneral()
                    );
                }
            );
        }
    }]);

    return AccountWrapper;
}(_react2.default.PureComponent);

exports.default = (0, _recompose.compose)((0, _reactRedux.connect)(function (state) {
    return { config: state.config };
}), (0, _styles.withStyles)(styles, { name: 'AppAccountWrapper' }))(AccountWrapper);