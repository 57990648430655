import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ElementCard from './cards/ElementCard';
import ProgramCard from './cards/ProgramCard';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

// TODO also exists in cms-workforms/scheme // TODO literal
const WORK_FORM_TYPES = (t) => [
	{ id: 'AskFeedback', label: t('common.workforms.ask-feedback') },
	{ id: 'ChatBubble', label: t('common.workforms.chat-bubble') },
	{ id: 'ChoiceQuestion', label: t('common.workforms.choice-question') },
	{ id: 'Criteria', label: t('common.workforms.criteria') },
	{ id: 'FactCardReferral', label: t('common.workforms.fact-card-referral') },
	{ id: 'FileDownload', label: t('common.workforms.file-download') },
	{ id: 'Hotspot', label: t('common.workforms.hotspot') },
	{ id: 'HotspotMap', label: t('common.workforms.hotspot-map') },
	{ id: 'ImageAndTextCheckQuestion', label: t('common.workforms.image-and-text-check-question') },
	{ id: 'ImageAndTextMPC', label: t('common.workforms.image-and-text-mpc') },
	{ id: 'ImageCheckQuestion', label: t('common.workforms.image-check-question') },
	{ id: 'ImageMPC', label: t('common.workforms.image-mpc') },
	{ id: 'ImageWithSubtitle', label: t('common.workforms.image-with-subtitle') },
	{ id: 'Moodboard', label: t('common.workforms.moodboard') },
	{ id: 'OrderQuestion', label: t('common.workforms.order-question') },
	{ id: 'ProgressionFeedback', label: t('common.workforms.progression-feedback') },
	{ id: 'ReferralElement', label: t('common.workforms.referral-element') },
	{ id: 'Roadmap', label: t('common.workforms.roadmap') },
	{ id: 'Scene', label: t('common.workforms.scene') },
	{ id: 'ShortText', label: t('common.workforms.short-text') },
	{ id: 'SortQuestion', label: t('common.workforms.sort-question') },
	{ id: 'StatementQuestion', label: t('common.workforms.statement-question') },
	{ id: 'TextCheckQuestion', label: t('common.workforms.text-check-question') },
	{ id: 'TextMPC', label: t('common.workforms.text-mpc') },
	{ id: 'TitleAndText', label: t('common.workforms.title-and-text') },
	{ id: 'TitleAndTextImage', label: t('common.workforms.title-and-text-image') },
	{ id: 'VideoWithTitle', label: t('common.workforms.video-with-title') },
	{ id: 'WhatIsWhatQuestion', label: t('common.workforms.what-is-what-question') },
	{ id: 'FactCardsReferral', label: t('common.workforms.fact-cards-referral') },
	{ id: 'OpenQuestion', label: t('common.workforms.open-question') },
	{ id: 'PollQuestion', label: t('common.workforms.poll-question') },
	{ id: 'ProcessStep', label: t('common.workforms.process-step') },
];

const WORK_FORM_TYPES_NAMES = (t) => WORK_FORM_TYPES(t).map((item) => ({
    id: item.id,
    name: item.label,
}));

const styles = (theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
    },
});

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const Dropable = (props) => {
    const [items, setItems] = useState(props.items || []);
    const [checkedItems, setCheckedItems] = useState([]);
    const [checkedUnboxItems, setCheckedUnboxItems] = useState([]);
    const { t } = useTranslation();

    ///effects
    useEffect(() => {
        setItems(props.items);
    }, [props.items]);

    useEffect(() => {
        if (!props.grouping) {
            const copied = [];
            const newItems = [];
            let first = -1;

            items.forEach((item, index) => {
                if (checkedItems[index]) {
                    copied.push(item[0]);
                    if (first < 0) first = index;
                } else {
                    newItems.push(item);
                }
            });

            if (copied.length) {
                newItems.splice(first, 0, copied);
                handleItems(newItems);
            }

            setCheckedItems([]);
        }
    }, [props.grouping]);

    //functions
    const handleItems = (_items) => {
        setItems(_items);
        props.onSetItems(_items);
    };

    const onDragEnd = (result) => {
        if (!result.destination) return;

        if (result.type === 'droppable') {
            const _items = reorder(
                items,
                result.source.index,
                result.destination.index
            );
            handleItems(_items);
        }
        if (result.type === 'innerDrop') {
            let idx = 0;
            items.forEach((item, i) => {
                if (item[0].id === result.source.droppableId) idx = i;
            });

            const _items = reorder(
                items[idx],
                result.source.index,
                result.destination.index
            );

            const copy = [...items];
            copy[idx] = _items;
            handleItems(copy);
        }
    };

    const handleRemove = (index) => {
        let _items = [...items];
        _items.splice(index, 1);
        handleItems(_items);
    };

    const handleSwitch = (element, index) => {
        let _items = [...items];

        _items[index] = {
            ...items[index],
            switch: element.target.checked,
            split: element.target.checked,
            correctWorkforms: [],
            inCorrectWorkforms: [],
        };

        handleItems(_items);
    };

    const handleCheckboxChange = (index, checked) => {
        const _checkedItems = [...checkedItems];
        _checkedItems[index] = checked;
        setCheckedItems([..._checkedItems]);
    };

    const handleUnGroupingChange = (index, checked) => {
        const _checkedUnboxItems = [...checkedUnboxItems];
        _checkedUnboxItems[index] = checked;
        setCheckedUnboxItems([..._checkedUnboxItems]);
    };

    const handleUnGroupCheckbox = (index, checked) => {
        if (props.onHandleUnGrouping) props.onHandleUnGrouping(index, checked);
    };

    const handleRemoveAlt = (index, idx) => {
        if (props.onRemoveAlt) props.onRemoveAlt(index, idx);
    };

    const handleRemoveAltItem = (index, idx) => {
        const _its = [...items];
        _its.forEach((_items, i) =>
            _items.forEach((item, ind) => {
                if (item.id === idx) _its[i].splice(ind, 1);
            })
        );

        handleItems(_its);
    };

    const handleUnGrouping = (index, unGrouping) => {
        if (!unGrouping) {
            const item = items[index];
            const _items = [...items];
            const copied = [];
            let newItem = [];
            item.forEach((i, idx) => {
                if (checkedUnboxItems[idx]) copied.push([item[idx]]);
                else newItem.push(item[idx]);
            });
            if (newItem.length === 1) copied.push(newItem);
            if (copied.length) {
                if (copied.length === item.length) {
                    _items.splice(index, 1, ...copied);
                } else {
                    _items.splice(index, 1, newItem);
                    _items.splice(index, 0, ...copied);
                }
                setCheckedUnboxItems([]);
                handleItems(_items);
            }
        }
    };

    const getSubHeaderName = (id) => {
        let label = '';
        WORK_FORM_TYPES_NAMES(t).forEach((workForm) => {
            if (workForm.id == id) label = workForm.name;
        });

        return label;
    };

    const {
        style = 'element',
        readOnly = false,
        switchLabel = false,
        splitCards = false,
        classes,
        useSwitch = false,
        dropId = 'droppableItem',
        type = 'droppable',
        grouping = false,
        custom = false,
        children,
    } = props;

    if (type === 'innerDrop') {
        return (
            <Droppable droppableId={dropId} type={type}>
                {(provided, snapshot) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className={classes.root}
                    >
                        {items.map((item, index) => (
                            <React.Fragment key={index}>
                                {(style === 'program' ||
                                    style === 'programItem') && (
                                    <ProgramCard
                                        provided={provided}
                                        snapshot={snapshot}
                                        index={index}
                                        item={item}
                                        style={style}
                                        onCheckboxChange={handleUnGroupCheckbox}
                                        unGrouping={props.unGrouping}
                                        readOnly={props.readOnly}
                                        onRemove={handleRemoveAlt}
                                        handleBoxConditionDialogOpen={props.handleBoxConditionDialogOpen}
                                    />
                                )}
                            </React.Fragment>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        );
    } else {
        return (
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId={dropId} type={type}>
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className={classes.root}
                        >
                            {custom && children(provided, snapshot)}
                            {!custom &&
                                items.map((item, index) => (
                                    <React.Fragment key={index}>
                                        {style === 'element' && (
                                            <ElementCard
                                                readOnly={readOnly}
                                                item={item}
                                                provided={provided}
                                                snapshot={snapshot}
                                                subHeader={getSubHeaderName(
                                                    item.type
                                                )}
                                                index={index}
                                                onItemClicked={
                                                    props.onItemClicked
                                                }
                                                onRemove={handleRemove}
                                                useSwitch={
                                                    useSwitch &&
                                                    !(
                                                        (items[index - 1] &&
                                                            items[index - 1]
                                                                .switch) ||
                                                        (items[index - 2] &&
                                                            items[index - 2]
                                                                .switch)
                                                    )
                                                }
                                                switchLabel={switchLabel}
                                                onSwitch={handleSwitch}
                                                item={item}
                                                small={
                                                    splitCards &&
                                                    ((items[index - 1] &&
                                                        items[index - 1]
                                                            .switch) ||
                                                        (items[index - 2] &&
                                                            items[index - 2]
                                                                .switch))
                                                }
                                            />
                                        )}
                                        {(style === 'program' ||
                                            style === 'programItem') && (
                                            <ProgramCard
                                                provided={provided}
                                                snapshot={snapshot}
                                                index={index}
                                                item={item}
                                                style={style}
                                                grouping={grouping}
                                                checkedItems={checkedItems}
                                                onCheckboxChange={
                                                    handleCheckboxChange
                                                }
                                                onCheckboxReset={() =>
                                                    setCheckedItems([])
                                                }
                                                onUnGroupingChange={
                                                    handleUnGroupingChange
                                                }
                                                onUnGrouping={handleUnGrouping}
                                                readOnly={props.readOnly}
                                                onRemove={handleRemove}
                                                onRemoveAlt={
                                                    handleRemoveAltItem
                                                }
                                                handleBoxConditionDialogOpen={props.handleBoxConditionDialogOpen}
                                            />
                                        )}
                                    </React.Fragment>
                                ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        );
    }
};

export default withStyles(styles)(Dropable);
