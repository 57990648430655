import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { getAppUser } from '@manakin/authentication/selectors';
import { GQL_FETCH_SCHOOL_CLASS, GQL_FETCH_SCHOOL, GQL_FETCH_PROGRAM, GQL_FETCH_SETTINGS } from '../graphql';
import { getGroup } from '@manakin/app-core/GroupsDropdown/selectors';
import { withStyles } from '@material-ui/core/styles';
import { Student, SimpleTable, AccessControl, Loader } from '@manakin/app-core';
import { withApollo } from 'react-apollo';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import MessageDialog from '../MessageDialog';
import { openDialog } from '@manakin/core/actions';
import MailIcon from '@material-ui/icons/MailOutline';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { withForm } from '@manakin/core';
import { useLazyQuery } from '@apollo/react-hooks';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useHistory } from 'react-router-dom';
import { styles } from './styles';
import { RAPPORTAGE_ROOT_PATH } from '../../Router/Router';
const studentLength = 5;
import Cookies from 'js-cookie';
import { uniqueArrayByKey, findInArrayByListOption } from '@manakin/app-core/lib';
import Chip from '@material-ui/core/Chip';
import SelectField from './SelectField';
import { teacherRoles, schoolManagerRole, requestedRolesInGivenRoles } from '@manakin/app-core/lib';
import { useSelector } from 'react-redux';
import { getProgram } from '@manakin/app-core/ProgramsDropdown/selectors';
import { activeElementTypes } from '@manakin/core/lib/elements';
import { useQuery } from '@apollo/react-hooks';

const form = {
	box: {},
};

const filterElements = (elements, t) => {
	return elements
		.filter((element) => element.type.match(/^(Lesson|BookCheck|Trainer|MasterClass|Film|Case|ExerciseCard)$/))
		.map((element) => {
			let elementTypeText;
			let elementType = element.type;

			switch (elementType) {
				case 'Lesson':
					elementTypeText = t('common.element-types.lesson');
					break;
				case 'BookCheck':
					elementTypeText = t('common.element-types.book-check');
					break;
				case 'Trainer':
					elementTypeText = t('common.element-types.trainer');
					break;
				case 'MasterClass':
					elementTypeText = t('common.element-types.master-class');
					break;
				case 'Film':
					elementTypeText = t('common.element-types.film');
					break;
				case 'Case':
					elementTypeText = t('common.element-types.case');
					break;
				case 'ExerciseCard':
					elementTypeText = t('common.element-types.exercise-card');
					break;
				default:
					elementTypeText = elementType;
			}

			return {
				id: element.id,
				label: elementTypeText + ': ' + element.title,
			};
		});
};

const ClassList = (props) => {
	const { group, classes, form, appUser = {} } = props;
	const { roles = [] } = appUser;
	const history = useHistory();
	const { t } = useTranslation();
	const program = useSelector((state) => getProgram(state));

	const [schoolOrClass, setSchoolOrClass] = useState({});

	const [availablePrograms, setAvailablePrograms] = useState([]);
	const [availableBoxes, setAvailableBoxes] = useState([]);
	const [availableElements, setAvailableElements] = useState([]);

	const [selectedPrograms, setSelectedPrograms] = useState(null);
	const [selectedBoxes, setSelectedBoxes] = useState(null);
	const [selectedElements, setSelectedElements] = useState(null);
	const [savedSelectionLoaded, setSavedSelectionLoaded] = useState(false);

	const [student, setStudent] = useState('All');
	const [currentIdx, setCurrentIdx] = useState(1);
	const [students, setStudents] = useState(null);
	const [studentsToShow, setStudentsToShow] = useState(0);
	const [name, setName] = useState([]);
	const [hasExerciseCards, setHasExerciseCards] = useState(false);

	const [getSchoolClass, { loading, data = {}, error }] = useLazyQuery(GQL_FETCH_SCHOOL_CLASS);
	const [getSchool, { loading: schoolLoading, data: schoolData = {}, error: schoolError }] = useLazyQuery(GQL_FETCH_SCHOOL);

	const [getProgramById, { loading: programLoading, data: programData = {} }] = useLazyQuery(GQL_FETCH_PROGRAM, {
		id: program,
	});
	const { data: settingsData = {} } = useQuery(GQL_FETCH_SETTINGS);

	useEffect(() => {
		if (group) {
			setSelectedPrograms(null);
			setSelectedBoxes(null);
			setSelectedElements(null);
			handleMount(group);
		}
	}, [group]);

	useEffect(() => {
		if (
			settingsData &&
			settingsData.settings &&
			programData &&
			programData.program &&
			programData.program.boxes &&
			programData.program.boxes.length
		) {
			const activeElements = activeElementTypes(settingsData, t);
			let foundExerciseCard = false;

			programData.program.boxes.forEach((box) => {
				if (box.length && !foundExerciseCard) {
					box.forEach((i) => {
						if (i.elements && i.elements.length) {
							i.elements
								.filter(
									(element) => activeElements.find((activeElement) => activeElement.type === element.type) !== undefined
								)
								.forEach((element) => {
									if (element.type === 'ExerciseCard') {
										foundExerciseCard = true;
									}
								});
						}
					});
				}
			});

			setHasExerciseCards(foundExerciseCard);
		} else {
			if(hasExerciseCards) {
				setHasExerciseCards(false);
			}
		}
	}, [programData, settingsData]);

	useEffect(() => {
		saveSelection();
	}, [selectedPrograms, selectedBoxes, selectedElements]);

	useEffect(() => {
		if (availablePrograms && availablePrograms.length) {
			loadSavedSelection([availablePrograms[0]]);
		}
	}, [availablePrograms]);

	const saveSelection = () => {
		if (!savedSelectionLoaded) return;

		Cookies.set(
			'reportFilters',
			JSON.stringify({
				selectedPrograms,
				selectedBoxes,
				selectedElements,
			})
		);
	};

	const loadSavedSelection = (programs) => {
		const reportFilters = Cookies.get('reportFilters');
		const data = JSON.parse(reportFilters || '{}');
		const preSelectedPrograms = data.selectedPrograms && data.selectedPrograms.length ? data.selectedPrograms : programs;

		if (preSelectedPrograms) {
			// Note: The logic in this method is similar (but with slight changes on purpose) to the following three methods:
			// handleProgramsChange, handleBoxesChange, handleElementsChange
			setSelectedPrograms(preSelectedPrograms);

			const newBoxes = [];
			preSelectedPrograms.forEach((programOption) => {
				const program = findInArrayByListOption(availablePrograms, programOption);
				program &&
					program.boxes &&
					program.boxes.forEach((box) =>
						newBoxes.push({
							id: box[0].id,
							label: box[0].name,
							elements: box[0].elements,
						})
					);
			});

			const newAvailableBoxes = uniqueArrayByKey(newBoxes, 'id');
			setAvailableBoxes(newAvailableBoxes);

			if (data.selectedBoxes) {
				const selectedBoxes = data.selectedBoxes.filter((box) => findInArrayByListOption(newAvailableBoxes, box));
				setSelectedBoxes(selectedBoxes);

				const newElements = [];
				selectedBoxes.forEach((boxOption) => {
					const box = findInArrayByListOption(newAvailableBoxes, boxOption);
					box && box.elements && box.elements.forEach((element) => newElements.push(element));
				});

				const newAvailableElements = uniqueArrayByKey(newElements, 'id');
				setAvailableElements(newAvailableElements);

				if (data.selectedElements) {
					const selectedElements = data.selectedElements.filter((element) =>
						findInArrayByListOption(newAvailableElements, element)
					);
					setSelectedElements(selectedElements);
				}
			}
		}

		setSavedSelectionLoaded(true);
	};

	useEffect(() => {
		if (!loading && data.schoolClass) {
			if (!error) {
				const _students = data.schoolClass.students;
				const _splicedStudents = [..._students].splice(0, studentLength);

				setSchoolOrClass(data.schoolClass);
				setStudents(data.schoolClass.students);
				setStudentsToShow(_splicedStudents);

				if (data.schoolClass.products && data.schoolClass.products.length) {
					const programs = data.schoolClass.products.map((product) => product.program);
					setAvailablePrograms(uniqueArrayByKey(programs, 'id'));
				}
			}
		}
	}, [loading, data]);

	useEffect(() => {
		if (!schoolLoading && schoolData.school && !schoolError) {
			const _students = schoolData.school.students;
			const _splicedStudents = [..._students].splice(0, studentLength);

			setSchoolOrClass(schoolData.school);
			setStudents(_students);
			setStudentsToShow(_splicedStudents);

			if (schoolData.school.products && schoolData.school.products.length) {
				const programs = schoolData.school.products.map((product) => product.program);
				setAvailablePrograms(uniqueArrayByKey(programs, 'id'));
			}
		}
	}, [schoolLoading, schoolData]);

	const handleMount = (groupId) => {
		if (groupId && groupId.type && groupId.type === 'School') {
			if (groupId.id) {
				getSchool({
					variables: { id: groupId.id },
				});
			}
		} else if (groupId && groupId.id) {
			getSchoolClass({
				variables: { id: groupId.id },
			});
		}

		getProgramById({
			variables: { id: program },
		});
	};

	const handleMailClick = (student) => {
		setStudent([student.id]);
		setName([student.fullName]);
		props.onOpenDialog();
	};

	const handleSendMessageAll = () => {
		const _students = students.map((student) => {
			return student.id;
		});

		setStudent(_students);
		if (data && data.schoolClass) setName(data.schoolClass.name);
		props.onOpenDialog();
	};

	const handleShowMore = () => {
		setCurrentIdx(currentIdx + 1);
	};

	const handleProgramsChange = (programs) => {
		if (programs) {
			setSelectedPrograms(programs);

			const newBoxes = [];
			programs.forEach((programOption) => {
				const program = findInArrayByListOption(availablePrograms, programOption);
				program &&
					program.boxes &&
					program.boxes.forEach((box) =>
						newBoxes.push({
							id: box[0].id,
							label: box[0].name,
							elements: box[0].elements,
						})
					);
			});

			const newAvailableBoxes = uniqueArrayByKey(newBoxes, 'id');
			setAvailableBoxes(newAvailableBoxes);

			if (selectedBoxes && selectedBoxes.length) {
				const remainingSelectedBoxes = selectedBoxes.filter((box) => findInArrayByListOption(newAvailableBoxes, box));
				handleBoxChange(remainingSelectedBoxes);
			}
		} else {
			setSelectedPrograms(null);
			setSelectedBoxes(null);
			setSelectedElements(null);
		}
	};

	const handleBoxChange = (boxes) => {
		if (boxes) {
			setSelectedBoxes(boxes);

			const newElements = [];
			boxes.forEach((boxOption) => {
				const box = findInArrayByListOption(availableBoxes, boxOption);
				box && box.elements && box.elements.forEach((element) => newElements.push(element));
			});

			const newAvailableElements = uniqueArrayByKey(newElements, 'id');
			setAvailableElements(newAvailableElements);

			if (selectedElements) {
				const remainingSelectedElements = selectedElements.filter((element) =>
					findInArrayByListOption(newAvailableElements, element)
				);
				setSelectedElements(remainingSelectedElements);
			}
		} else {
			setSelectedBoxes(null);
			setSelectedElements(null);
		}
	};

	const handleElementChange = (elements) => {
		if (elements) {
			setSelectedElements(elements);
		} else {
			setSelectedElements(null);
		}
	};

	const handleReportRequest = () => {
		if (schoolOrClass && selectedPrograms && selectedPrograms.length) {
			let url = `/api/export/users/excel/${schoolOrClass.id}`;
			url += `/${selectedPrograms.map((program) => program.value).join(',')}`;

			if (selectedBoxes && selectedBoxes.length) {
				url += `/${selectedBoxes.map((box) => box.value).join(',')}`;

				if (selectedElements && selectedElements.length) {
					url += `/${selectedElements.map((element) => element.value).join(',')}`;
				}
			}

			window.open(`${url}`);
		}
	};

	useEffect(() => {
		if (currentIdx > 1) {
			const _students = [...students];
			setStudentsToShow(_students.splice(0, studentLength * currentIdx));
		}
	}, [currentIdx]);

	const handleClick = (data) => {
		if (data.id && selectedPrograms && selectedPrograms.length === 1 && hasExerciseCards) {
			// In agreement with the customer, only go to program if 1 is selected
			const program = availablePrograms.find((program) => program.id == selectedPrograms[0].value) || {};
			const product = schoolOrClass.products.find((product) => product.program.id == program.id);

			if (product) {
				const url = `/${RAPPORTAGE_ROOT_PATH}/student/${data.id}/${program.id}/${product.id}`;
				history.push(url);
			}
		}
	};

	const handleChipDelete = (type, id) => () => {
		if (type === 'program') {
			handleProgramsChange(selectedPrograms.filter((program) => program.value !== id));
		} else if (type === 'box') {
			handleBoxChange(selectedBoxes.filter((box) => box.value !== id));
		} else if (type === 'element') {
			handleElementChange(selectedElements.filter((element) => element.value !== id));
		}
	};
	if (group && !data.schoolClass && !loading && !students) {
		return (
			<div className={classes.emptyRoot}>
				<div className={classes.wrapper}>
					<Typography component="h3" variant="h3">
						{requestedRolesInGivenRoles(teacherRoles, roles) ? t('app.report.class-list.no-class-selected') : ''}
					</Typography>
				</div>
			</div>
		);
	} else if ((!group || !data.schoolClass || (group && Object.keys(group).length === 0)) && !loading && !students) {
		return (
			<div className={classes.emptyRoot}>
				<div className={classes.wrapper}>
					<Typography component="h3" variant="h3">
						{requestedRolesInGivenRoles(teacherRoles, roles) ? t('app.report.class-list.no-class-connected') : ''}
					</Typography>
				</div>
			</div>
		);
	} else {
		return (
			<React.Fragment>
				{!loading ? (
					<div className={classes.root}>
						<AccessControl role={[...teacherRoles, schoolManagerRole]}>
							<MessageDialog student={student} name={name} />
						</AccessControl>
						<div className={classes.topBar}>
							{selectedPrograms && selectedPrograms.length > 0 && (
								<div className={classes.downloadReportContainer}>
									<div className={classes.downloadReport} onClick={handleReportRequest}>
										{t('app.report.class-list.download-action')}
										<GetAppIcon className={classes.downloadReportIcon} />
									</div>
								</div>
							)}
						</div>
						<div className={classes.wrapper}>
							<div className={classes.schoolClasses}>
								<div className={classes.classList}>
									<div className={classes.selectWrapper}>
										<SelectField
											placeholder={t('app.report.class-list.fields.programs-filter.placeholder')}
											options={availablePrograms}
											valueOverride={selectedPrograms}
											onChange={handleProgramsChange}
											className={classes.selectField}
										/>
										{selectedPrograms && selectedPrograms.length > 0 && (
											<SelectField
												placeholder={t('app.report.class-list.fields.boxes-filter.placeholder')}
												options={availableBoxes}
												valueOverride={selectedBoxes}
												onChange={handleBoxChange}
												className={classes.selectField}
											/>
										)}
										{selectedBoxes && selectedBoxes.length > 0 && (
											<SelectField
												placeholder={t('app.report.class-list.fields.elements-filter.placeholder')}
												valueOverride={selectedElements}
												options={filterElements(availableElements, t)}
												onChange={handleElementChange}
												hideSelectedItems={true}
												className={classes.selectField}
											/>
										)}
									</div>
									<div>
										{selectedPrograms &&
											selectedPrograms.map((option) => (
												<Chip
													key={`program-${option.value}`}
													label={option.label || option.name}
													className={classes.chip}
													onDelete={handleChipDelete('program', option.value)}
												/>
											))}
										{selectedBoxes &&
											selectedBoxes.map((option) => (
												<Chip
													key={`box-${option.value}`}
													label={option.label || option.name}
													className={classes.chip}
													onDelete={handleChipDelete('box', option.value)}
												/>
											))}
										{selectedElements &&
											selectedElements.map((option) => (
												<Chip
													key={`element-${option.value}`}
													label={option.label || option.name}
													className={classes.chip}
													onDelete={handleChipDelete('element', option.value)}
												/>
											))}
									</div>
									{students && (
										<div>
											<div className={classes.amount}>
												<Typography component="p" variant="subtitle1" className={classes.students}>
													{t('app.report.class-list.students-in-group', { amount: students.length })}
												</Typography>
												<span className={classes.icon} onClick={handleSendMessageAll}>
													<MailIcon />
												</span>
											</div>
										</div>
									)}
									<SimpleTable
										renderHead={() => (
											<React.Fragment>
												<TableCell alitn="left">{t('common.table-headers.student')}</TableCell>
												<TableCell align="left">{t('common.table-headers.school-class')}</TableCell>
												<TableCell align="left">
													{t('common.table-headers.progress')}
													{/* <TableSortLabel
														onClick={sortTableRows}
														IconComponent={
															KeyboardArrowDown
														}
														direction={
															sortDirection
														}
														hideSortIcon={false}
														classes={{
															icon: classes.sortIcon,
														}}
													/> */}
												</TableCell>
												<TableCell align="left">
													{t('app.report.class-list.table-headers.learning-result')}
												</TableCell>
											</React.Fragment>
										)}
										renderBody={() => (
											<React.Fragment>
												{studentsToShow &&
													studentsToShow.map((student) => (
														<Student
															key={student.id}
															hover={hasExerciseCards}
															onClick={handleClick}
															userData={student}
															classData={schoolOrClass}
															onMailClick={handleMailClick}
															availablePrograms={availablePrograms}
															selectedPrograms={selectedPrograms}
															selectedBoxes={selectedBoxes}
															selectedElements={selectedElements}
														/>
													))}
											</React.Fragment>
										)}
									/>
									{studentsToShow && students && studentsToShow.length < students.length && (
										<Button
											size="small"
											color="primary"
											variant="contained"
											onClick={handleShowMore}
											className={classes.button}
										>
											{t('app.report.class-list.load-more-students')}
										</Button>
									)}
								</div>
							</div>
						</div>
					</div>
				) : (
					<div className="loadContainer">
						<Loader />
					</div>
				)}
			</React.Fragment>
		);
	}
};

export default compose(
	withForm(form),
	connect(
		(state) => ({
			group: getGroup(state),
			appUser: getAppUser(state),
		}),
		(dispatch) => ({
			onOpenDialog: (data) => dispatch(openDialog('appClassMessageDialog')),
		})
	),
	withApollo,
	withStyles(styles, { name: 'AppClassList' })
)(ClassList);
