import React, { useState, useEffect, useRef } from 'react';
import scrollToComponent from 'react-scroll-to-component';
import { Typography, Button, RadioGroup, Radio, FormControlLabel } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { ElementOverlay, Loader, BottomButtons } from '@manakin/app-core';
import { Header, Workforms } from './parts';
import renderHTML from 'react-render-html';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(
	(theme) =>
		createStyles({
			root: {
				position: 'relative',
				width: '100%',
				heigth: '100%',
			},
			container: {
				maxWidth: '1050px',
				margin: '0 auto',
				padding: theme.spacing(0, 1),
			},
			footerContainer: {
				maxWidth: '740px',
				margin: '0 auto',
				padding: theme.spacing(8, 1),
			},
			row: {
				display: 'flex',
				justifyContent: 'space-between',
				flexWrap: 'wrap',
			},
			col: {
				flex: '0 0 100%',
				[theme.breakpoints.up('md')]: {
					flex: '0 0 48%',
				},
			},
			footer: {
				backgroundColor: theme.manakin.defaultElementBGColor[500],
				width: '100%',
			},
			video: {
				margin: theme.spacing(8, 0),
			},
			center: {
				textAlign: 'center',
				marginBottom: theme.spacing(4),
				color: theme.palette.getContrastText(theme.manakin.defaultElementBGColor[500]),
			},
			radio: {
				display: 'none',
				'&.Mui-checked': {
					'& ~ $label': {
						backgroundColor: theme.palette.getContrastText(
							theme.palette.getContrastText(theme.manakin.defaultElementBGColor[500])
						),
						color: theme.palette.getContrastText(theme.manakin.defaultElementBGColor[500]),
						border: '2px solid black',
					},
				},
			},
			labelRoot: {
				width: '100%',
				display: 'block',
				margin: theme.spacing(0, 0, 2, 0),
			},
			label: {
				backgroundColor: theme.manakin.defaultElementBGColor[500],
				padding: theme.spacing(2),
				width: '100%',
				display: 'flex',
				justifyContent: 'space-between',
				margin: 0,
				textDecoration: 'none',
				textAlign: 'center',
				color: theme.palette.getContrastText(theme.manakin.defaultElementBGColor[500]),
				border: `2px solid ${theme.palette.getContrastText(theme.manakin.defaultElementBGColor[500])}`,
				transition: 'all .3s',
				'&:not($disabled)': {
					'&:hover': {
						background: theme.manakin.defaultContentHover[500],
						color: theme.palette.getContrastText(theme.manakin.defaultContentHover[500]),
					},
				},
			},
			disabled: {
				'&:hover': {
					backgroundColor: theme.manakin.defaultElementBGColor[500],
					border: `2px solid ${theme.manakin.defaultBorderColor[500]}`,
				},
			},
			button: {
				marginTop: theme.spacing(4),
				padding: theme.spacing(3),
			},
			resetButton: {
				marginTop: theme.spacing(4),
				backgroundColor: theme.manakin.secondaryNavigationColor[500],
				opacity: 1,
				transition: 'opacity .3s',
				'&:hover': {
					backgroundColor: theme.manakin.secondaryNavigationColor[500],
					opacity: 0.8,
				},
			},
			feedback: {
				margin: '40px 0 0',
				display: 'block',
				width: '100%',
			},
			feedbackHeading: {
				margin: 0,
				color: theme.palette.getContrastText(theme.manakin.primaryColor[500]),
			},
			feedbackBody: {
				color: theme.palette.getContrastText(theme.manakin.primaryColor[500]),
			},
		}),
	{
		name: 'AppAssignmentCardView',
	}
);

const AssignmentCardView = (props) => {
	const { workformsData = {}, config = {}, isDisabled, elementResult, loading, error, preview, boxId } = props;

	const { rawData = {} } = workformsData;
	const { assignmentConfig = {} } = config;
	const classes = useStyles();
	const [value, setValue] = useState('');
	const footerRef = useRef(null);
	const history = useHistory();
	const { t } = useTranslation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	//function
	const handleChange = (event) => {
		setValue(event.target.value);
	};

	const saveChoice = () => {
		if (props.onSaveChoice) props.onSaveChoice(value);
	};

	const scrollToBottom = () => {
		if (footerRef.current) {
			scrollToComponent(footerRef.current, {
				offset: -200,
				align: 'top',
				duration: 200,
			});
		}
	};

	const handleReset = () => {
		props.onReset();
	};

	const handleClose = () => {
		history.push(`/box/${boxId}`);
	};

	if (loading) return <Loader fullScreen />;
	return (
		<ElementOverlay fullWidth={true} controls={!preview}>
			<div className={classes.root}>
				<Header {...workformsData} handleDoneButton={scrollToBottom} />

				<div className={classes.container}>
					<Workforms {...workformsData} />
				</div>
				<div className={classes.footer}>
					<div ref={footerRef} className={classes.footerContainer}>
						<Typography className={classes.center} color="secondary" variant="h2">
							{t('app.assignment-card.footer.title')}
						</Typography>

						<RadioGroup
							aria-label="choice"
							name="choice"
							onChange={handleChange}
							value={(elementResult && elementResult.rating) || value}
							disabled={isDisabled}
						>
							<React.Fragment>
								<FormControlLabel
									value={rawData.feedback1 || t('app.assignment-card.footer.fields.choice.feedback1')}
									className={classes.labelRoot}
									control={<Radio className={classes.radio} />}
									label={renderHTML(rawData.feedback1 || t('app.assignment-card.footer.fields.choice.feedback1'))}
									disabled={isDisabled}
									classes={{
										label: classes.label,
										disabled: classes.disabled,
									}}
								/>
								<FormControlLabel
									value={rawData.feedback2 || t('app.assignment-card.footer.fields.choice.feedback2')}
									className={classes.labelRoot}
									control={<Radio className={classes.radio} />}
									label={renderHTML(rawData.feedback2 || t('app.assignment-card.footer.fields.choice.feedback2'))}
									disabled={isDisabled}
									classes={{
										label: classes.label,
										disabled: classes.disabled,
									}}
								/>
								<FormControlLabel
									className={classes.labelRoot}
									value={rawData.feedback3 || t('app.assignment-card.footer.fields.choice.feedback3')}
									control={<Radio className={classes.radio} />}
									label={renderHTML(rawData.feedback3 || t('app.assignment-card.footer.fields.choice.feedback3'))}
									disabled={isDisabled}
									classes={{
										label: classes.label,
										disabled: classes.disabled,
									}}
								/>
								<FormControlLabel
									className={classes.labelRoot}
									value={rawData.feedback4 || t('app.assignment-card.footer.fields.choice.feedback4')}
									control={<Radio className={classes.radio} />}
									label={renderHTML(rawData.feedback4 || t('app.assignment-card.footer.fields.choice.feedback4'))}
									disabled={isDisabled}
									classes={{
										label: classes.label,
										disabled: classes.disabled,
									}}
								/>
							</React.Fragment>
							{error && <Typography variant="body2">{t('app.assignment-card.footer.make-choice')}</Typography>}
							<Button
								className={classes.button}
								fullWidth
								variant="contained"
								color="primary"
								onClick={saveChoice}
								disabled={isDisabled}
							>
								{t('app.assignment-card.footer.save-choice')}
							</Button>

							{elementResult && elementResult.ratingTeacher && (
								<div className={classes.feedback}>
									<Typography variant="h3" className={classes.feedbackHeading}>
										{t('app.assignment-card.footer.teacher-feedback')}
									</Typography>
									<Typography className={classes.feedbackBody}>{elementResult.ratingTeacher}</Typography>
								</div>
							)}
						</RadioGroup>
					</div>
					{assignmentConfig.redoButtons && isDisabled && (
						<React.Fragment>
							<BottomButtons onClose={handleClose} onReset={handleReset} />
						</React.Fragment>
					)}
				</div>
			</div>
		</ElementOverlay>
	);
};

export default AssignmentCardView;
