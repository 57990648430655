import theme from '../../../apps/slimstefysio-cms/src/styling/theme';
import moment from 'moment';
import 'moment/min/locales';

export const toIsoString = (date, keepOffset = false) => {
    return moment(date).toISOString(keepOffset);
}

export const toLocalDateTimeString = (date) => {
    return moment(date).local().format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
}

export const normalize = (data) => {
	return {
		byId: data.reduce((byIds, entity) => {
			byIds[entity.id] = entity;
			return byIds;
		}, {}),
		allIds: data.map((entity) => entity.id),
	};
};

export const useWhiteOrBlackTextColor = (hex) => {
	if (hex && hex.length >= 7) {
		const hexToRGB = (hex) => [('0x' + hex[1] + hex[2]) | 0, ('0x' + hex[3] + hex[4]) | 0, ('0x' + hex[5] + hex[6]) | 0];
		const rgb = hexToRGB(hex);
		return (rgb[0] * 299 + rgb[1] * 587 + rgb[1] * 114) / 1000 > 125 ? 'rgba(0,0,0,0.87)' : '#FFFFFF';
	} else {
		return '#FFFFFF';
	}
};

export const localDateTimeToDateString = (localDateTimeString) => {
	if (!localDateTimeString) return undefined;
	else return moment(localDateTimeString).format('YYYY-MM-DD');
};

export const processBackgroundImage = (formData) => processImage(formData, 'backgroundImage');

export const processImage = (item, imageKey = 'image') => {
	return {
		bynderId: item[imageKey] && item[imageKey].bynderId,
		derivative: item[imageKey] && item[imageKey].derivative,
		...(item[imageKey] &&
			item[imageKey].focusPointX !== undefined && {
				focusPointX: item[imageKey].focusPointX,
			}),
		...(item[imageKey] &&
			item[imageKey].focusPointY !== undefined && {
				focusPointY: item[imageKey].focusPointY,
			}),
		...(item[imageKey] &&
			item[imageKey].fit !== undefined && {
				fit: item[imageKey].fit,
			}),
	};
};

export const processTags = (formData, tagName = 'tags') => {
	return formData[tagName] && formData[tagName].map((value) => value.value);
};

export const manakinTheme = theme;

export const FormKeys = {
	email: 'email',
	password: 'password',
	acceptTerms: 'acceptTerms',
	firstName: 'firstName',
	lastName: 'lastName',
	gender: 'gender',
	dateOfBirth: 'dateOfBirth',
	phonenumber: 'phonenumber',
};

export const ApiKeys = {
	fileUpload: '/api/assets/upload',
	cmsExportReportContent: '/api/export/content/excel',
	cmsExportReportTagContent: '/api/export/tag-content/excel',
	cmsExportUserLog: '/api/export/users',
	cmsExportGlobalProgression: '/api/export/global-progression',
	cbdExportUsersManager: '/api/cbd/export/users/manager'
};
