import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import AltContent from './AltContent';
import CbdExportUsersManager from './CbdExportUsersManagerButton';

const styles = (theme) => ({
	contentColor: {
		opacity: 1,
		transition: 'opacity .3s',
	},
	headingName: {
		[theme.breakpoints.up('md')]: {
			display: 'block',
		},
	},
	root: {
		width: '100%',
		minHeight: '500px',
		height: 'auto',
		backgroundColor: theme.manakin.primaryColor[500],
		padding: '20rem 0 50rem',
		'& $contentColor': {
			color: theme.palette.getContrastText(theme.manakin.primaryColor[500]),
		},
		[theme.breakpoints.up('md')]: {
			padding: '18rem 0 50rem',
		},
	},
	wrapper: {
		...theme.manakin.defaultWrapper,
	},
	content: {
		[theme.breakpoints.up('md')]: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			marginBottom: '2.3rem',
		},
	},
	title: {
		[theme.breakpoints.up('md')]: {
			maxWidth: '410px',
			width: '100%',
			padding: '2rem 0',
		},
	},
	heading: {},
	body: {
		marginBottom: '2.8rem',
	},
	introduction: {
		[theme.breakpoints.up('md')]: {
			maxWidth: '450px',
			width: '100%',
		},
	},
	loading: {
		opacity: 0,
	},
});

const Header = (props) => {
	const { classes, user, data, loading, config = {}, children } = props;
	const { t } = useTranslation();

	const { pages, general } = config;
	const appDashboardConfig = pages.appDashboard || {};
	const { alternativeContent } = appDashboardConfig;
	const showName = appDashboardConfig.showName != undefined ? appDashboardConfig.showName : true;

	return (
		<React.Fragment>
			<div className={classes.root}>
				<div className={classes.wrapper}>
					<div className={classes.content}>
						<div className={classes.title}>
							<Typography component="h1" variant="h2" className={classes.contentColor} classes={{ h2: classes.heading }}>
								{t('app.dashboard.title')} <span className={classes.headingName}>{(showName && user.firstName) || ''}</span>
							</Typography>
						</div>
						<div className={classes.introduction}>
							{alternativeContent ? (
								<AltContent data={data} />
							) : (
								<div>
									<Typography
										component="p"
										variant="body2"
										classes={{ body2: classes.body }}
										className={classNames(classes.contentColor, {
											[classes.loading]: loading,
										})}
									>
										{data.description || ''}
									</Typography>

									{general.cbd && (
										<CbdExportUsersManager />
									)}
								</div>
							)}
						</div>
					</div>
					{children}
				</div>
			</div>
		</React.Fragment>
	);
};

export default compose(
	withStyles(styles, { name: 'AppDashboardHeader' }),
	connect(({ config }) => ({ config }))
)(Header);
