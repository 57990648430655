import React, { useState, useEffect } from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import {
	PrivateRoute,
	AppAuthenticator,
	AppImpersonateUser,
} from '@manakin/authentication';
import { SnackbarMessenger, UiProvider } from '@manakin/core';
import App from '../App';
import PreviewRouter from '@manakin/app-views/PreviewRouter';
import { Provider } from 'react-redux';
import { ApolloProvider } from 'react-apollo';
import { Switch, Route, Router } from 'react-router-dom';
import { HelpButton, ColorService } from '@manakin/app-core';
import { loadConfig } from '../../config/config';
import loadConfiguration from '../../config';
import Cookies from 'js-cookie';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { isAuthenticated } from '@manakin/authentication/selectors';
let mounted = true;

document.oncontextmenu = function () {
	return false;
};

const mapStateToProps = (store) => {
	return {
		authenticated: isAuthenticated(store),
	};
};

const Root = (props) => {
	const locale = Cookies.get('locale') || '';
	const { store, theme, client, history, ui, loadConfig, authenticated } =
		props;
	const [colorProps, setColorProps] = useState({});

	useEffect(() => {
		loadConfiguration(locale).then((configuration) =>
			loadConfig(configuration)
		);
		// eslint-disable-next-line
	}, [authenticated]);

	//effect hooks
	useEffect(() => {
		mounted = true;
		return () => (mounted = false);
	}, []);

	//function
	const handleChange = (data) => {
		setTimeout(() => {
			if (mounted) {
				setColorProps(data);
			}
		}, 1);
	};

	window.ondragstart = () => false;

	return (
		<Provider store={store}>
			<ApolloProvider client={client}>
				<MuiThemeProvider theme={theme(colorProps)}>
					<UiProvider ui={ui}>
						<Router history={history}>
							<div>
								<SnackbarMessenger />
								<HelpButton />
								<ColorService onChange={handleChange}>
									<Switch>
										<Route
											path={'/impersonate'}
											component={AppImpersonateUser}
										/>
										<Route
											path={`/preview/:elementType/:query`}
											component={PreviewRouter}
										/>
										<Route
											path="/auth"
											component={AppAuthenticator}
										/>
										<PrivateRoute
											path="/"
											component={App}
										/>
									</Switch>
								</ColorService>
							</div>
						</Router>
					</UiProvider>
				</MuiThemeProvider>
			</ApolloProvider>
		</Provider>
	);
};

export default compose(
	connect(mapStateToProps, (dispatch) => ({
		loadConfig: (data) => dispatch(loadConfig(data)),
	}))
)(Root);
