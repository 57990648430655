'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _appCore = require('@manakin/app-core');

var _core = require('@manakin/core');

var _reactHooks = require('@apollo/react-hooks');

var _graphql = require('./graphql');

var _reactRedux = require('react-redux');

var _selectors = require('@manakin/authentication/selectors');

var _core2 = require('@material-ui/core');

var _actions = require('../GroupsDropdown/actions');

var _selectors2 = require('../GroupsDropdown/selectors');

var _reactI18next = require('react-i18next');

var _lib = require('@manakin/app-core/lib');

var _NoLicenseForProgramDialog = require('./NoLicenseForProgramDialog');

var _NoLicenseForProgramDialog2 = _interopRequireDefault(_NoLicenseForProgramDialog);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var useStyles = (0, _core2.makeStyles)(function (theme) {
	return {
		selectField: _defineProperty({
			minWidth: '100%'

		}, theme.breakpoints.up('sm'), {
			minWidth: '300px'
		})
	};
});

var ProgramsDropdown = function ProgramsDropdown(props) {
	var classes = useStyles();

	var _useTranslation = (0, _reactI18next.useTranslation)(),
	    t = _useTranslation.t;

	var appUser = (0, _reactRedux.useSelector)(function (state) {
		return (0, _selectors.getAppUser)(state);
	});
	var group = (0, _reactRedux.useSelector)(function (state) {
		return (0, _selectors2.getGroup)(state);
	});
	var client = (0, _reactHooks.useApolloClient)();

	//dispatch
	var dispatch = (0, _reactRedux.useDispatch)();
	var setGroup = (0, _react.useCallback)(function (v) {
		return dispatch((0, _actions.selectGroup)(v));
	}, [dispatch]);

	var roleType = appUser.roles && appUser.roles[0].name;
	var role = (0, _lib.userHasAnyRole)(appUser, _lib.teacherRoles) ? 'teacher' : 'manager';

	var _useQuery = (0, _reactHooks.useQuery)(_graphql.GQL_FETCH_GROUPS_FOR_SCHOOL_MANAGER),
	    _useQuery$data = _useQuery.data,
	    schoolManagerGroupsData = _useQuery$data === undefined ? {} : _useQuery$data;

	var _ref = schoolManagerGroupsData || {},
	    _ref$schoolManagerGro = _ref.schoolManagerGroups,
	    schoolManagerGroups = _ref$schoolManagerGro === undefined ? {} : _ref$schoolManagerGro;

	var _useState = (0, _react.useState)(true),
	    _useState2 = _slicedToArray(_useState, 2),
	    loading = _useState2[0],
	    setLoading = _useState2[1];

	var _useState3 = (0, _react.useState)({}),
	    _useState4 = _slicedToArray(_useState3, 2),
	    groups = _useState4[0],
	    setGroups = _useState4[1];

	var _useState5 = (0, _react.useState)([]),
	    _useState6 = _slicedToArray(_useState5, 2),
	    groupsWithLicense = _useState6[0],
	    setGroupsWithLicense = _useState6[1];

	var _useState7 = (0, _react.useState)(false),
	    _useState8 = _slicedToArray(_useState7, 2),
	    noLicenseForProgramDialogOpen = _useState8[0],
	    setNoLicenseForProgramDialogOpen = _useState8[1];

	var _useState9 = (0, _react.useState)({}),
	    _useState10 = _slicedToArray(_useState9, 2),
	    noLicenseForProgramDialogGroup = _useState10[0],
	    setNoLicenseForProgramDialogGroup = _useState10[1];

	var isMyspotTeacher = (0, _lib.userHasAnyRole)(appUser, [_lib.myspotTeacherRole]) && !(0, _lib.userHasAnyRole)(appUser, [_lib.schoolManagerRole]);

	(0, _react.useEffect)(function () {
		// Explicitly using the client instead of a query hook because query hooks do not support inline fragments
		// for some reason, which is required in this case.
		client.query({
			query: _graphql.GQL_FETCH_GROUPS,
			variables: {
				filter: _defineProperty({
					types: ['School', 'SchoolClass']
				}, role, appUser.id),
				pagesize: 99
			},
			fetchPolicy: 'no-cache'
		}).then(function (result) {
			setLoading(false);

			if (result && result.data && result.data.groups) {
				var _groups = result.data.groups;

				if (isMyspotTeacher) {
					// Check which programs the teacher has a license for, to prevent the teacher from teaching in case it has no license
					var userPrograms = [];
					if (appUser && appUser.licenses) {
						appUser.licenses.forEach(function (license) {
							if (license.product && license.product.program) {
								userPrograms.push(license.product.program.id);

								if (license.product.subProducts) {
									license.product.subProducts.forEach(function (product) {
										if (product.program && product.program.id) {
											userPrograms.push(product.program.id);
										}
									});
								}
							}
						});
					}

					var _groupsWithLicense = _groups.groups.filter(function (group) {
						var hasAllLicenses = true;
						if (group.type === 'SchoolClass' && group.programs) {
							group.programs.forEach(function (program) {
								if (!userPrograms.includes(program.id)) {
									hasAllLicenses = false;
								}
							});
						}

						return hasAllLicenses;
					});

					setGroupsWithLicense(_groupsWithLicense);
					setGroups(_groups);
				} else {
					setGroups(_groups);
				}
			}
		});
	}, []);

	var options = groups.groups && groups.groups.map(function (group) {
		return {
			id: group.id,
			label: group.name,
			type: group.type
		};
	});

	if (roleType === _lib.schoolManagerRole) {
		options = schoolManagerGroups.groups && schoolManagerGroups.groups.map(function (group) {
			return {
				id: group.id,
				label: group.name,
				type: group.type
			};
		});
	}

	//effect hooks
	(0, _react.useEffect)(function () {
		if (options && options.length && (!group || !group.id)) {
			setFirstClass(options);
		}
	}, [groups]);

	//functions
	var setFirstClass = function setFirstClass(opts) {
		if (opts && opts.length) {
			var firstClass = opts.find(function (opt) {
				return opt.type === 'SchoolClass';
			});
			var id = firstClass ? firstClass : opts[0];

			if (isMyspotTeacher) {
				// Set the group by default where the teacher has a valid product
				if (groupsWithLicense && groupsWithLicense.length > 0) {
					setGroup({
						id: groupsWithLicense[0].id,
						label: groupsWithLicense[0].name,
						type: groupsWithLicense[0].type
					});
				} else {
					// No product present for any class, show dialog for first group
					if (groups && groups.groups && groups.groups.length > 0) {
						setNoLicenseForProgramDialogGroup(groups.groups[0]);
						setNoLicenseForProgramDialogOpen(true);
					}
				}
			} else {
				setGroup(id);
			}
		}
	};

	var handleChangeWithConfirmation = function handleChangeWithConfirmation(value, confirmation) {
		if (value && value !== 'null') {
			var option = options.find(function (option) {
				return option.id === value;
			});

			if (option) {
				if (isMyspotTeacher) {
					if (groupsWithLicense.some(function (group) {
						return group.id == option.id;
					})) {
						// User has license for group, continue
						setGroup(option);
						confirmation();
					} else {
						var _group = groups && groups.groups.find(function (group) {
							return group.id == option.id;
						});
						setNoLicenseForProgramDialogGroup(_group);
						setNoLicenseForProgramDialogOpen(true);
					}
				} else {
					setGroup(option);
					confirmation();
				}
			}
		}
	};

	var handleCloseNoLicenseForProgramDialog = function handleCloseNoLicenseForProgramDialog() {
		setNoLicenseForProgramDialogOpen(false);
	};

	if (loading) return '';

	return _react2.default.createElement(
		'div',
		null,
		_react2.default.createElement(
			_appCore.AccessControl,
			{ role: [].concat(_toConsumableArray(_lib.teacherRoles), [_lib.schoolManagerRole]) },
			_react2.default.createElement(
				'div',
				{ className: classes.selectField },
				_react2.default.createElement(_core.SimpleSelect, {
					options: options,
					onChangeWithConfirmation: handleChangeWithConfirmation,
					emptyLabel: t('app-core.programs-dropdown.label'),
					initialValue: group ? group.id || null : null
				})
			),
			_react2.default.createElement(_NoLicenseForProgramDialog2.default, {
				open: noLicenseForProgramDialogOpen,
				onClose: handleCloseNoLicenseForProgramDialog,
				group: noLicenseForProgramDialogGroup,
				appUser: appUser
			})
		)
	);
};

exports.default = ProgramsDropdown;