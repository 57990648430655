import gql from 'graphql-tag';
import {currentAppUser, group, groups, elementResults } from '@manakin/app-core/graphQlHelpers';

export const GQL_FETCH_GROUP = gql`
    query group($id: ID!) {
        group(id: $id) {
            ${group}
        }
    }
`;

export const GQL_FETCH_GROUPS = gql`
    query groups($filter: GroupFilter) {
        groups(filter: $filter) {
            ${groups}
        }
    }
`;

export const GQL_FETCH_ELEMENT_RESULTS = gql`
    query elementResults($box: ID!, $program: ID!, $user: ID!) {
        elementResults(box: $box, program: $program, user: $user) {
            ${elementResults}
        }
    }
`;

export const GQL_CHANGE_FAVOURITE_PRODUCT_ON_LICENSE = gql`
	mutation changeFavouriteProductOnLicense($licenseId: ID!, $favouriteProductId: ID!) {
		changeFavouriteProductOnLicense(licenseId: $licenseId, favouriteProductId: $favouriteProductId)
	}
`;

export const GQL_CURRENT_APP_USER = gql`
    query currentAppUser {
        currentAppUser {
            ${currentAppUser}
        }
    }
`;
export const GQL_FETCH_GLOBAL_SETTING = gql`
	query setting($name: String!) {
		setting(name: $name) {
			name
			value
		}
	}
`;
