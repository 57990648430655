"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});
exports.GQL_PROGRAMS = exports.GQL_FETCH_GROUPS_FOR_SCHOOL_MANAGER = exports.GQL_FETCH_GROUPS = exports.GQL_FETCH_SERVER_INFO = undefined;

var _templateObject = _taggedTemplateLiteral(["\n\tquery serverInfo {\n\t\tserverInfo {\n\t\t\tversion\n\t\t}\n\t}\n"], ["\n\tquery serverInfo {\n\t\tserverInfo {\n\t\t\tversion\n\t\t}\n\t}\n"]),
    _templateObject2 = _taggedTemplateLiteral(["\n    query groups($filter: GroupFilter, $pagesize: Int) {\n        groups(filter: $filter, pagesize: $pagesize) {\n\t\t\tgroups {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t\taddress\n\t\t\t\tlocation\n\t\t\t\ttype\n\t\t\t\tenabled\n\t\t\t\tmanagers {\n\t\t\t\t\tid\n\t\t\t\t\tfullName\n\t\t\t\t}\n\t\t\t\tteachers {\n\t\t\t\t\tid\n\t\t\t\t\tfullName\n\t\t\t\t}\n\t\t\t\t... on SchoolClass {\n\t\t\t\t\tprograms {\n\t\t\t\t\t\tid\n\t\t\t\t\t}\n\t\t\t\t\tproducts {\n\t\t\t\t\t\tid\n\t\t\t\t\t\tname\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t\tcount\n        }\n    }\n"], ["\n    query groups($filter: GroupFilter, $pagesize: Int) {\n        groups(filter: $filter, pagesize: $pagesize) {\n\t\t\tgroups {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t\taddress\n\t\t\t\tlocation\n\t\t\t\ttype\n\t\t\t\tenabled\n\t\t\t\tmanagers {\n\t\t\t\t\tid\n\t\t\t\t\tfullName\n\t\t\t\t}\n\t\t\t\tteachers {\n\t\t\t\t\tid\n\t\t\t\t\tfullName\n\t\t\t\t}\n\t\t\t\t... on SchoolClass {\n\t\t\t\t\tprograms {\n\t\t\t\t\t\tid\n\t\t\t\t\t}\n\t\t\t\t\tproducts {\n\t\t\t\t\t\tid\n\t\t\t\t\t\tname\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t\tcount\n        }\n    }\n"]),
    _templateObject3 = _taggedTemplateLiteral(["\n\tquery schoolManagerGroups {\n\t\tschoolManagerGroups {\n            groups {\n                id\n                name\n                type\n            }\n        }\n\t}\n"], ["\n\tquery schoolManagerGroups {\n\t\tschoolManagerGroups {\n            groups {\n                id\n                name\n                type\n            }\n        }\n\t}\n"]),
    _templateObject4 = _taggedTemplateLiteral(["\n\tquery programs {\n\t\tprograms(pagesize: 20) {\n\t\t\tcount\n\t\t\tprograms {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t}\n\t\t}\n\t}\n"], ["\n\tquery programs {\n\t\tprograms(pagesize: 20) {\n\t\t\tcount\n\t\t\tprograms {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t}\n\t\t}\n\t}\n"]);

var _graphqlTag = require("graphql-tag");

var _graphqlTag2 = _interopRequireDefault(_graphqlTag);

var _graphQlHelpers = require("../graphQlHelpers");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var GQL_FETCH_SERVER_INFO = exports.GQL_FETCH_SERVER_INFO = (0, _graphqlTag2.default)(_templateObject);

var GQL_FETCH_GROUPS = exports.GQL_FETCH_GROUPS = (0, _graphqlTag2.default)(_templateObject2);

var GQL_FETCH_GROUPS_FOR_SCHOOL_MANAGER = exports.GQL_FETCH_GROUPS_FOR_SCHOOL_MANAGER = (0, _graphqlTag2.default)(_templateObject3);

var GQL_PROGRAMS = exports.GQL_PROGRAMS = (0, _graphqlTag2.default)(_templateObject4);