'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _actions = require('./AppMenu/actions');

Object.keys(_actions).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _actions[key];
    }
  });
});

var _actions2 = require('./AppBar/actions');

Object.keys(_actions2).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _actions2[key];
    }
  });
});

var _actions3 = require('./AppBarDropdown/actions');

Object.keys(_actions3).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _actions3[key];
    }
  });
});

var _actions4 = require('./Boxes/actions');

Object.keys(_actions4).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _actions4[key];
    }
  });
});

var _actions5 = require('./BoxService/actions');

Object.keys(_actions5).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _actions5[key];
    }
  });
});

var _actions6 = require('./SearchBar/actions');

Object.keys(_actions6).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _actions6[key];
    }
  });
});

var _actions7 = require('./WorkformViewer/actions');

Object.keys(_actions7).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _actions7[key];
    }
  });
});

var _actions8 = require('./ProgramsDropdown/actions');

Object.keys(_actions8).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _actions8[key];
    }
  });
});

var _actions9 = require('./ColorService/actions');

Object.keys(_actions9).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _actions9[key];
    }
  });
});