'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styles = require('@material-ui/core/styles');

var _Typography = require('@material-ui/core/Typography');

var _Typography2 = _interopRequireDefault(_Typography);

var _appCore = require('@manakin/app-core');

var _graphql = require('../graphql');

var _reactApollo = require('react-apollo');

var _recompose = require('recompose');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var styles = function styles(theme) {
    return {
        title: {},
        itemRoot: {
            cursor: 'pointer',
            '&:hover': {
                '& $listItem': {
                    borderColor: theme.manakin.defaultContentHover[500]
                },
                '& $itemTitle': {
                    color: theme.manakin.defaultContentHover[500]
                },
                '& $content': {
                    color: theme.manakin.defaultContentHover[500]
                }
            }
        },
        image: {
            position: 'relative',
            height: '18rem',
            width: '100%'
        },
        itemTitle: {
            fontWeight: 'bold',
            lineHeight: '3.2rem',
            transition: 'color .3s',
            marginBottom: 0
        },
        content: {
            transition: 'color .3s',
            marginBottom: 0
        },
        listItem: {
            transition: 'border-color .3s',
            borderBottom: '1px solid ' + theme.manakin.defaultBorderColor[500],
            paddingTop: '2.5rem',
            paddingBottom: '2.4rem'
        }
    };
};

var Item = function (_React$PureComponent) {
    _inherits(Item, _React$PureComponent);

    function Item() {
        var _ref;

        var _temp, _this, _ret;

        _classCallCheck(this, Item);

        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
        }

        return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = Item.__proto__ || Object.getPrototypeOf(Item)).call.apply(_ref, [this].concat(args))), _this), _this.handleClick = function (result) {
            if (_this.props.onClick) _this.props.onClick(result);
        }, _temp), _possibleConstructorReturn(_this, _ret);
    }

    _createClass(Item, [{
        key: 'render',
        value: function render() {
            var _this2 = this;

            var _props = this.props,
                classes = _props.classes,
                result = _props.result,
                idx = _props.idx,
                data = _props.data,
                _props$BOX = _props.BOX,
                BOX = _props$BOX === undefined ? {} : _props$BOX,
                _props$ELEMENT = _props.ELEMENT,
                ELEMENT = _props$ELEMENT === undefined ? {} : _props$ELEMENT;
            var _BOX$box = BOX.box,
                box = _BOX$box === undefined ? {} : _BOX$box;
            var _ELEMENT$element = ELEMENT.element,
                element = _ELEMENT$element === undefined ? {} : _ELEMENT$element;
            var _data$program = data.program,
                program = _data$program === undefined ? {} : _data$program;


            return _react2.default.createElement(
                'div',
                {
                    className: classes.itemRoot,
                    onClick: function onClick() {
                        return _this2.handleClick(result);
                    },
                    key: idx
                },
                result.image && result.image.url && idx == 0 && _react2.default.createElement(
                    'div',
                    { className: classes.image },
                    _react2.default.createElement(_appCore.LoadImage, {
                        src: result.image.url,
                        placeholder: result.image.thumbnailUrl || ''
                    })
                ),
                _react2.default.createElement(
                    'div',
                    { className: classes.listItem },
                    _react2.default.createElement(
                        _Typography2.default,
                        {
                            variant: 'body1',
                            classes: { body1: classes.itemTitle }
                        },
                        element && element.preTitle || result.type === "Box" && box && box.appTitle || result.title || ''
                    ),
                    program && program.name && _react2.default.createElement(
                        _Typography2.default,
                        {
                            variant: 'body1',
                            classes: { body1: classes.content }
                        },
                        box && box.preTitle || program.name || ''
                    )
                )
            );
        }
    }]);

    return Item;
}(_react2.default.PureComponent);

exports.default = (0, _recompose.compose)((0, _styles.withStyles)(styles), (0, _reactApollo.graphql)(_graphql.GQL_FETCH_PROGRAM, {
    options: function options(props) {
        return {
            variables: { id: props.result.program || 0 }
        };
    }
}), (0, _reactApollo.graphql)(_graphql.GQL_FETCH_BOX, {
    name: 'BOX',
    options: function options(props) {
        return {
            variables: { id: props.result.box || 0 }
        };
    }
}), (0, _reactApollo.graphql)(_graphql.GQL_FETCH_ELEMENT, {
    name: 'ELEMENT',
    options: function options(props) {
        return {
            variables: { id: props.result.element || 0 }
        };
    }
}))(Item);