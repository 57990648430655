import React, { useState, useCallback } from 'react';
import { useStyles } from './styles';
import { compose } from 'recompose';
import { withForm } from '@manakin/core';
import { PersonalDataFormSettings } from './forms/PersonalDataFormSettings';
import { AuthDataFormSettings } from './forms/AuthDataFormSettings';
import PersonalData from './parts/PersonalData';
import AuthData from './parts/AuthData';
import Stepper from './parts/Stepper';
import { useDispatch } from 'react-redux';
import ButtonGroup from './parts/ButtonGroup';
import { useHistory } from 'react-router';
import { Typography } from '@material-ui/core';
import { GQL_CREATE_APP_USER } from './graphql';
import { useMutation } from '@apollo/react-hooks';
import { showSnackbarMessage } from '@manakin/core/actions';
const formData = [PersonalDataFormSettings, AuthDataFormSettings];
import { useTranslation } from 'react-i18next';

const form = {
	...PersonalDataFormSettings,
	...AuthDataFormSettings,
};

const Registration = (props) => {
	const { form, onSwitch, data, token } = props;
	const classes = useStyles();
	const { t } = useTranslation();
	const history = useHistory();
	const dispatch = useDispatch();

	//dispatch
	const processError = useCallback(
		(message) =>
			dispatch(
				showSnackbarMessage({
					text: message || t('authentication.invitation.registration.failed'),
					variant: 'error',
				})
			),
		[dispatch]
	);

	//mutations
	const [mutate] = useMutation(GQL_CREATE_APP_USER);

	//state
	const [step, setStep] = useState(0);

	//functions
	const handleSubmit = () => {
		form.onSubmit().then((r) => {
			const { secondPassword, secondEmail, ...otherProps } = r;
			mutate({
				variables: {
					...otherProps,
					roles: [data.invitationByToken.trainerRole.id],
				},
			})
				.then((r) => {
					if (r.errors) {
						if (r.errors.find((error) => error.code === 409)) {
							processError(t('authentication.invitation.registration.account-already-exists'));
						} else {
							processError();
						}
					} else {
						history.push(`/auth/invitation-succes/${token}`);
					}
				})
				.catch((e) => {
					processError();
				});
		});
	};

	const handleNext = () => {
		form.onValidate().then(
			() => {
				setStep(step + 1);
			},
			(error) => {
				let hasError = false;
				for (const key of Object.keys(formData[step])) {
					if (error[key] != undefined) hasError = true;
				}
				if (!hasError) setStep(step + 1);
			}
		);
	};
	const handleBack = () => {
		if (step === 0) history.goBack();
		else setStep(step - 1);
	};

	return (
		<div className={classes.root}>
			<div className={classes.container}>
				<Stepper step={step} steps={formData} />
				<div className={classes.content}>
					{step === 0 && <PersonalData data={data} form={form} />}
					{step === 1 && <AuthData form={form} initial={data.invitationByToken || null} />}
				</div>
				<ButtonGroup onSubmit={handleSubmit} onNext={handleNext} onBack={handleBack} step={step} />
				<Typography className={classes.register}>
					{t('authentication.invitation.registration.already-account')}{' '}
					<span onClick={onSwitch} className={classes.registerLink}>
						{t('authentication.invitation.registration.login')}
					</span>
				</Typography>
			</div>
		</div>
	);
};

export default compose(withForm(form))(Registration);
