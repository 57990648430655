import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import { useGetBoxPercentage } from '@manakin/hooks';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
	(theme) =>
		createStyles({
			root: {
				padding: '8rem 0 4rem',
				opacity: 0,
				transform: 'translateX(-30px)',
				transition: 'opacity .3s .3s, transform .3s .3s',
				backgroundRepeat: 'no-repeat',
				backgroundSize: 'cover',
				backgroundPosition: 'center center',
				[theme.breakpoints.up('md')]: {
					padding: '18rem 0 8rem',
				},
				...theme.manakin.boxOverviewStyle,
			},
			wrapper: {
				...theme.manakin.smallWrapper,
				zIndex: 999,
				maxWidth: 900
			},
			body: {
				...theme.manakin.smallWrapper,
				'&$colorSecondary': {
					color: '#000',
					'&::after': {
						color: '#000',
					},
				},
				'&$colorPrimary': {
					color: '#fff',
					'&::after': {
						color: '#fff',
					},
				}
			},
			show: {
				transform: 'translateX(0)',
				opacity: 1,
			},
			content: {
				textAlign: 'center',
				position: 'relative',
			},
			heading: {
				'&::after': {
					backgroundColor: theme.manakin.indicationColor[500],
				},
				'&$colorSecondary': {
					color: '#000',
					'&::after': {
						color: '#000',
					},
				},
				'&$colorPrimary': {
					color: '#fff',
					'&::after': {
						color: '#fff',
					},
				}
			},
			resultsPercentage: {
				'&::after': {
					content: 'attr(data-results-percentage) "%"',
					fontSize: '2.5rem',
					lineHeight: 0,
					background: `linear-gradient(90deg, ${theme.manakin.indicationColor[500]} 0%, ${theme.manakin.indicationColor[500]} 25%, rgba(0,0,0,0) 25%, rgba(0,0,0,0) 75%, ${theme.manakin.indicationColor[500]} 75%, ${theme.manakin.indicationColor[500]} 100%)`,
					letterSpacing: 0,
					width: '150px',
					color: theme.manakin.primaryPaletteColor[500],
				},
			},
			backgroundImage: {
				position: 'relative',
				'&::after': {
					content: "''",
					position: 'absolute',
					left: 0,
					top: 0,
					width: '100%',
					height: '100%',
					backgroundColor: 'black',
					opacity: '0.6',
					zIndex: '99',
				},
				'& $heading': {
					color: 'white',

					'&$colorSecondary': {
						color: '#000'
					},
					'&$colorPrimary': {
						color: '#fff'
					}
				},
				'& $body': {
					color: 'white',

					'&$colorSecondary': {
						color: '#000'
					},
					'&$colorPrimary': {
						color: '#fff'
					}
				},
				'& $resultsPercentage': {
					'&::after': {
						color: 'white',
					},

					'&$colorSecondary': {
						color: '#000',
						'&::after': {
							color: '#000',
						},
					},
					'&$colorPrimary': {
						color: '#fff',
						'&::after': {
							color: '#fff',
						},
					}
				},
			},
			colorPrimary: {},
			colorSecondary: {}
		}),
	{
		name: 'AppBoxHeader',
	}
);

const Header = (props) => {
	const { description = '', title = 'Box', config = {}, backgroundImage = {}, boxResults, headerTitleColor = 'NONE' } = props;
	const classes = useStyles();
	const { pages = {} } = config;
	let _isMounted = false;

	//state hooks
	const [show, setShow] = useState(false);

	//effect hooks
	useEffect(() => {
		_isMounted = true;
		setTimeout(() => {
			if (_isMounted) {
				setShow(true);
			}
		}, 100);

		return () => (_isMounted = false);
	}, []);

	const showResultsPercentage = pages.appBox && pages.appBox.showResultsPercentage;

	const boxPercentage = useGetBoxPercentage(boxResults);

	return (
		<div
			className={classNames(
				classes.root,
				{ [classes.show]: show },
				{
					[classes.backgroundImage]: backgroundImage.url ? true : false,
				}
			)}
			style={
				backgroundImage.url && {
					backgroundImage: `url("${backgroundImage ? backgroundImage.url || '' : ''}")`,
				}
			}
		>
			<div className={classes.wrapper}>
				<div className={classes.content}>
					<Typography
						component="h1"
						variant="h2"
						className={classNames('underline', {
							[classes.resultsPercentage]: showResultsPercentage,
							[classes.colorPrimary]: headerTitleColor === 'PRIMARY',
							[classes.colorSecondary]: headerTitleColor === 'SECONDARY',
						})}
						classes={{ h2: classes.heading }}
						data-results-percentage={boxPercentage}
					>
						{title}
					</Typography>
					<Typography 
						component="p" 
						variant="body1"  
						classes={{ body1: classes.body }}
						className={classNames({
							[classes.colorPrimary]: headerTitleColor === 'PRIMARY',
							[classes.colorSecondary]: headerTitleColor === 'SECONDARY',
						})}
					>
						{description}
					</Typography>
				</div>
			</div>
		</div>
	);
};

export default Header;
