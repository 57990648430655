import React from 'react';
import Header from './parts/Header.js';
import { connect } from 'react-redux';
import { useGetConfig } from '@manakin/hooks';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import NewsCarousel from '../NewsCarousel';

const useStyles = makeStyles(
    (theme) =>
        createStyles({
            root: {
                width: '100%'
            },
            dialogPaper: {
                flexFlow: 'row wrap',
                padding: '32px',
            },
            confirmDeleteButton: {
                padding: '2rem',
                marginRight: '20px',
            },
        }),
    {
        name: 'AppLoginView',
    }
);

const Import = (props) => {
    const { config = {} } = props;
    const classes = useStyles();
    const showNews = useGetConfig('pages', 'appLogin', 'showNews', config);

    return (
        <div className={classes.root}>
            <Header search={props.location.search} />
            {showNews && <NewsCarousel category={['HOMEPAGE']} />}
        </div>
    );
};

export default compose(
    connect(({ config }) => ({ config })),
    withRouter
)(Import);
