import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { hideAppBar } from '@manakin/app-core/actions';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import Product from '@manakin/authentication/AppRegistrationCBD/elements/Product';
import { withForm } from '@manakin/core';
import { styles } from './styles';
import {
    GQL_FETCH_APP_USER_FOR_STORE,
    GQL_FETCH_SERVER_INFO,
    GQL_VALID_LICENSE_CHECK,
} from './graphql';
import { useQuery } from '@apollo/react-hooks';
import { graphql } from 'react-apollo';
import { Loader } from '@manakin/app-core';
import Button from '@material-ui/core/Button';
import { withRouter, Redirect } from 'react-router-dom';
import { logout } from '@manakin/authentication/actions';
import Cookies from 'js-cookie';
import moment from 'moment';
import { useTranslation } from "react-i18next";

const form = {};

// TODO this needs to be whitelabel I think eventually?
const NoLicenses = (props) => {
    const { classes, form, data = {}, match = {}, LICENSE_CHECK } = props;
    const { loading: licenseLoading } = LICENSE_CHECK;
    const { loading, currentAppUser = {} } = data;
    const { t } = useTranslation();

    //query
    const { loading: serverLoading, error, data: serverData = {} } = useQuery(
        GQL_FETCH_SERVER_INFO
    );
    const { serverInfo = {} } = serverData;

    //state hooks
    const [isEtos, setIsEtos] = useState(false);
    const [payUrl, setPayUrl] = useState('pay');
    const [licenses, setLicense] = useState({});
    const [loadingLicenses, setLoading] = useState(true);
    const [variant, setVariant] = useState('default');
    const [active, setActive] = useState(0);
    const [userFunction, setUserFunction] = useState('Drogist');
    const [isIosApp, setIsIosApp] = useState(false);
    const [currentYear, setCurrentYear] = useState(2022);
    const [currentYears, setCurrentYears] = useState([]);
    const [price, setPrice] = useState('34,65');
    const [hasValidLicense, setHasValidLicense] = useState(false);

    useEffect(() => {
        if (
            match.params.variant !== 'all' &&
            !LICENSE_CHECK.error &&
            !LICENSE_CHECK.loading &&
            LICENSE_CHECK.validLicenseCheck
        ) {
            if (
                LICENSE_CHECK.validLicenseCheck &&
                LICENSE_CHECK.validLicenseCheck.hasValidLicense
            ) {
                setHasValidLicense(true);
            }
        }
    }, [props.LICENSE_CHECK.loading]);

    useEffect(() => {
        if (navigator.userAgent.indexOf('wrapper') !== -1) {
            setIsIosApp(true);
        }
        const { onHideAppBar } = props;
        onHideAppBar({ path: props.location.pathname });
        if (match.params && match.params.variant) {
            setVariant(match.params.variant);
        }
    }, []);

    useEffect(() => {
        if (!serverLoading) {
            if (serverInfo.currentYear) {
                const current = serverInfo.currentYear;
                setCurrentYear(current);
                setPrice(current >= 2022 ? '34,65' : '34,00');
                setCurrentYears([current - 2, current - 1, current]);
            }
        }
    }, [serverLoading]);

    useEffect(() => {
        if (!loading && currentAppUser) {
            const { schoolClasses = [] } = currentAppUser;
            if (schoolClasses && schoolClasses.length) {
                schoolClasses.forEach((schoolClass) =>
                        schoolClass.schools && schoolClass.schools.forEach((school) =>
                                school.organisations && school.organisations.forEach((organisation) => {
                                    if (organisation.mainOrganisation &&
                                        organisation.mainOrganisation.name.toLowerCase() === 'etos eigen winkel') {
                                        setPayUrl('etos');
                                        setIsEtos(true);
                                    }
                                }),
                        ),
                );
            }

            let obj = { done: true, };

            if (currentAppUser.userDataCbd && currentAppUser.userDataCbd.function) {
                setUserFunction(currentAppUser.userDataCbd.function);
            }

            if (currentAppUser.userDataCbd) {
                const { userDataCbd = {} } = currentAppUser;
                if (userDataCbd.trainingMandatory2017) obj['trainingMandatory2017'] = true;
                if (userDataCbd.trainingMandatory2018) obj['trainingMandatory2018'] = true;
                if (userDataCbd.trainingMandatory2019) obj['trainingMandatory2019'] = true;
                if (userDataCbd.trainingMandatory2020) obj['trainingMandatory2020'] = true;
                if (userDataCbd.trainingMandatory2021 && currentYear === 2022) obj['trainingMandatory2021'] = true;
            }
            if (currentAppUser.licenses && currentAppUser.licenses.length) {
                currentAppUser.licenses.forEach((item) => {
                    if (item.licenseGroup && item.licenseGroup.name) {
                        const name = item.licenseGroup.name;
                        if (name.indexOf('2017') !== -1) obj['trainingMandatory2017'] = true;
                        if (name.indexOf('2018') !== -1) obj['trainingMandatory2018'] = true;
                        if (name.indexOf('2019') !== -1) obj['trainingMandatory2019'] = true;
                        if (name.indexOf('2020') !== -1) obj['trainingMandatory2020'] = true;
                        if (name.indexOf('2021') !== -1) obj['trainingMandatory2021'] = true;
                        if (name.indexOf('2022') !== -1) obj['trainingMandatory2022'] = true;

                        if (name.indexOf('Migration') !== -1 || name.indexOf('Autogen') !== -1 || name.indexOf('NOLIMIT') !== -1) {
                            const validUntil = item.validUntil && moment(item.validUntil).format('YYYY');
                            if (validUntil === '2018') obj['trainingMandatory2017'] = true;
                            if (validUntil === '2019') obj['trainingMandatory2018'] = true;
                            if (validUntil === '2020') obj['trainingMandatory2019'] = true;
                            if (validUntil === '2021') obj['trainingMandatory2020'] = true;
                            if (validUntil === '2022') obj['trainingMandatory2021'] = true;
                        }
                    }
                });
            }

            setLicense(obj);
        }
    }, [loading]);

    useEffect(() => {
        if (licenses.done) {
            setLoading(false);
        }
    }, [licenses]);

    //functions
    const handlePay = () => {
        const { fields } = form;
        let obj = {
            function: currentAppUser.userDataCbd.function,
        };

        for (const key of Object.keys(fields)) {
            if (!licenses[key]) obj[key] = fields[key];
        }

        Cookies.set('shop', obj);
        props.history.push(`/auth/registration/${payUrl}`);
    };

    const handleClick = (data) => {
        if (!loading && !loadingLicenses) {
            if (data) {
                setActive(active + 1);
            } else {
                setActive(active - 1);
            }
        }
    };

    const handleLogout = () => {
        props.onLogout();
    };

    const handleBack = () => {
        props.history.goBack();
    };

    if (hasValidLicense) {
        return <Redirect to={'/'} />;
    }
    if (loading || loadingLicenses || licenseLoading) {
        return (
            <div>
                <Loader fullScreen={true} />
            </div>
        );
    }
    if (isIosApp) {
        return (
            <div className={classes.root}>
                <div className={classes.wrapper}>
                    <Typography className={classes.heading} variant="h3">
                        {variant === 'all'
                            ? t("custom.app.no-licenses.ios-app.title-all")
                            : t("custom.app.no-licenses.ios-app.title")}
                    </Typography>
                    <Typography
                        classes={{ body1: classes.paragraph }}
                        variant="body1"
                        gutterBottom
                    >
                        {t("custom.app.no-licenses.ios-app.description")}
                    </Typography>
                    <Button
                        onClick={handleLogout}
                        color="primary"
                        className={classes.logoutButton}
                    >
                        {t("custom.app.no-licenses.ios-app.action")}
                    </Button>
                </div>
            </div>
        );
    }
    return (
        <div className={classes.root}>
            <div className={classes.wrapper}>
                <Typography className={classes.heading} variant="h3">
                    {variant === 'all'
                        ? t("custom.app.no-licenses.title-all")
                        : t("custom.app.no-licenses.title")}
                </Typography>
                <Typography
                    classes={{ body1: classes.paragraph }}
                    variant="body1"
                    gutterBottom
                >
                    {t("custom.app.no-licenses.description")}
                </Typography>
            </div>
            <div className={classes.content}>
                <div className={classes.largeWrapper}>
                    <div className={classes.products}>
                        {currentYears &&
                            currentYears.map((year) => (
                                <Product
                                    key={year}
                                    name={`trainingMandatory${year}`}
                                    year={year}
                                    price={isEtos ? '' : t("custom.app.no-licenses.price-ex-btw", { amount: price })}
                                    userFunction={userFunction}
                                    form={form}
                                    isUpdate={match.params.variant === 'all'}
                                    initialValue={
                                    licenses[`trainingMandatory${year}`] || false}
                                    disabled={licenses[`trainingMandatory${year}`]}
                                    onClick={handleClick}
                                />
                            ))}
                    </div>
                </div>
                <div className={classes.wrapper}>
                    <div className={classes.controls}>
                        <Button
                            onClick={handlePay}
                            color="primary"
                            variant="contained"
                            className={classes.mainButtonStyle}
                            disabled={active <= 0}
                        >
                            {t("custom.app.no-licenses.action-pay")}
                        </Button>
                        {variant === 'all' ? (
                            <Button onClick={handleBack} color="primary">
                                {t("custom.app.no-licenses.action-back")}
                            </Button>
                        ) : (
                            <Button onClick={handleLogout} color="primary">
                                {t("custom.app.no-licenses.action-logout")}
                            </Button>
                        )}
                    </div>
                    <div className={classes.footNode}>
                        <Typography
                            variant="body1"
                            className={classes.smallFont}
                        >
                            {t("custom.app.no-licenses.foot-note")}
                        </Typography>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    onHideAppBar: (data) => dispatch(hideAppBar(data)),
    onLogout: () => dispatch(logout()),
});

export default compose(
    withStyles(styles),
    withForm(form),
    connect(null, mapDispatchToProps),
    graphql(GQL_FETCH_APP_USER_FOR_STORE),
    graphql(GQL_VALID_LICENSE_CHECK, { name: 'LICENSE_CHECK' }),
    withRouter
)(NoLicenses);
