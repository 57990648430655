import { makeStyles, createStyles } from '@material-ui/core/styles';

export default (props = {}) =>
	makeStyles(
		(theme) =>
			createStyles({
				root: {
					backgroundColor: 'rgba(0,0,0,0)',
					boxShadow: 'none',
				},
				registrationLink: {
					display: 'block',
					textDecoration: 'none',
					marginTop: theme.spacing(2),
				},
				link: {
					textDecoration: 'none',
					display: 'block',
					textAlign: 'center',
					color: props.loginBackgroundColor
						? theme.palette.getContrastText(props.loginBackgroundColor)
						: theme.manakin.homeLinkColor ? theme.manakin.homeLinkColor[500] : "",
					marginTop: theme.spacing(3),
					fontSize: '18px',
					'&:visited': {
						color: props.loginBackgroundColor
							? theme.palette.getContrastText(props.loginBackgroundColor)
							: theme.manakin.homeLinkColor ? theme.manakin.homeLinkColor[500] : "",
					},
				},
				button: {},
			}),
		{
			name: 'AppLogin',
		}
	);
