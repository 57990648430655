import React, { useState, useEffect } from 'react';
import PageLayout from '../PageLayout';
import { SelectField, TextField } from '@manakin/core';
import { GQL_FETCH_REGISTRATION_GROUPS } from '../graphql';
import { withStyles } from '@material-ui/core/styles';
import { SchoolClassSelector } from '../elements';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { Loader } from '@manakin/app-core';

const styles = (theme) => ({
	wrapper: {
		...theme.manakin.smallWrapper,
		maxWidth: '550px',
	},
	hidden: {
		height: 0,
		overflow: 'hidden',
	},
});

const Organisation = (props) => {
	const { form = {}, classes, data } = props;
	const { fields = {} } = form;
	const { loading } = data;

	//state hooks
	const [selectedOrganisation, setOrganisation] = useState(null);
	const [isSpecial, setIsSpecial] = useState(false);
	const [isEtos, setIsEtos] = useState();
	const [options, setOptions] = useState([]);

	//effect hooks
	useEffect(() => {
		if (form.fields && form.fields.mainOrganisation && !isSpecial) {
			setOrganisation(form.fields.mainOrganisation);
		}

		if (form.fields.affiliate && form.fields.mainOrganisation && !isSpecial) {
			props.onReady(true);
		} else {
			props.onReady(false);
		}
	}, [form.fields]);

	useEffect(() => {
		props.onReady(!isSpecial);
		props.onSpecial(isSpecial);
	}, [isSpecial]);

	useEffect(() => {
		props.onIsEtos(isEtos);
	}, [isEtos]);

	useEffect(() => {
		if (!loading) {
			if (data.registrationGroups && data.registrationGroups) {
				console.log('data', data);
				const arr = data.registrationGroups.filter((item) => {
					if (fields.mainOrganisation && fields.mainOrganisation === item.id && item.name.toLowerCase() === 'etos eigen winkel') {
						form.onFieldChange({ key: 'globalID', value: '' });
						setIsEtos(true);
					}

					// return item.enabled;
					return true;
				});
				setOptions(arr);
			}
		}
	}, [loading]);

	//function
	const handleChange = (data) => {
		setIsSpecial(false);
		setIsEtos(false);
		if (data) {
			switch (data.label.toLowerCase()) {
				case 'dio':
				case 'kruidvat':
				case 'trekpleister':
					setIsSpecial(true);
					break;
				case 'etos eigen winkel':
					setIsEtos(true);
					form.onFieldChange({ key: 'globalID', value: '' });
					break;
			}
		}

		if (selectedOrganisation != data.value) {
			form.onFieldChange({
				key: 'affiliate',
				value: null,
			});
		}
	};

	if (loading) {
		return (
			<div>
				<Loader fullScreen={true} />
			</div>
		);
	}
	return (
		<PageLayout
			title="Organisatie en filiaal"
			intro="Welkom bij nascholingsplatform 'Zorg dat je bijblijft!'. Vul hier de gegevens in van jouw organisatie."
		>
			<div className={classes.content}>
				<div className={classes.wrapper}>
					<SelectField
						options={options}
						name="mainOrganisation"
						label="Kies organisatie"
						form={form}
						initialValue={{
							id: form.fields && form.fields.mainOrganisation,
						}}
						onChange={handleChange}
						alpha={true}
					/>
					{selectedOrganisation && !isSpecial && <SchoolClassSelector id={selectedOrganisation} form={form} />}
					{isEtos ? (
						<TextField
							form={form}
							name="globalID"
							label={'Global id'}
							shrink={false}
							initialValue={form.fields && form.fields.globalID}
						/>
					) : (
						<React.Fragment>
							<div className={classes.hidden}>
								<TextField
									form={form}
									name="globalID"
									label={'Global id'}
									shrink={false}
									inputProps={{ hidden: true }}
									initialValue="3300"
								/>
							</div>
							<TextField
								form={form}
								name="registerCode"
								label={'Personeelsnummer'}
								shrink={false}
								initialValue={(form.fields && form.fields.registerCode) || ''}
							/>
						</React.Fragment>
					)}
				</div>
			</div>
		</PageLayout>
	);
};

export default compose(
	withStyles(styles),
	graphql(GQL_FETCH_REGISTRATION_GROUPS, {
		options: (props) => ({
			variables: {
				page: 0,
				pagesize: 3000,
				filter: {
					types: ['MainOrganisation'],
				},
			},
		}),
	})
)(Organisation);
